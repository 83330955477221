import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

// nodejs library to set properties for components
// @material-ui/core components
const styles = {
  grid: {
    padding: "0 16px !important",
  },
};

const useStyles = makeStyles(styles);

function GBESSGridItem(props) {
  const classes = useStyles();
  const { children, className, noPadding, ...rest } = props;
  const gridClasses = classNames({
    [classes.grid]: !noPadding,
    [className]: true,
  });
  return (
    <Grid item {...rest} className={gridClasses}>
      {children}
    </Grid>
  );
}

GBESSGridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.any,
  noPadding: PropTypes.bool,
};
export default GBESSGridItem;
