export enum userConstants {
  LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE = "USERS_LOGIN_FAILURE",
  LOGOUT = "USERS_LOGOUT",
}
export enum menusConstants {
  MENUS_ADD_ALL = "MENUS_ADD_ALL",
  MENUS_REMOVE_ALL = "MENUS_REMOVE_ALL",
}
export enum actionsConstants {
  ACTIONS_ADD_ALL = "ACTIONS_ADD_ALL",
  ACTIONS_REMOVE_ALL = "ACTIONS_REMOVE_ALL",
}
