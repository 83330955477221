import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESSDialog from "../../../components/GBESS/GBESSDialog";
import GBESSIconButton from "../../../components/GBESS/GBESSIconButton";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const BuildingZoneSearch = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteQuestionOpen, setDeleteQuestionOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 225,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <GBESSIconButton
              aria-label="delete"
              onClick={handleDeleteQuestion(params)}
              code="building-zone-search-delete"
            >
              <DeleteIcon />
            </GBESSIconButton>
            <GBESSIconButton
              aria-label="edit"
              onClick={handleUpdate(params)}
              code="building-zone-search-edit"
            >
              <EditIcon />
            </GBESSIconButton>
          </>
        );
      },
    },
    {
      field: "ZoneName",
      headerName: t("zone-name"),
      width: 225,
      editable: true,
    },
    {
      field: "HCTemperature",
      headerName: t("indoor-temperature-for-hc"),
      width: 450,
      editable: true,
    },
    {
      field: "CCTemperature",
      headerName: t("indoor-temperature-for-cc"),
      width: 450,
      editable: true,
    },
  ];

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
      handleSearch();
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleDeleteQuestion = (record) => (e) => {
    setDeleteId(record.id);
    setDeleteQuestionOpen(true);
  };
  const handleDelete = (e) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/EnergyConsumptionZones/Delete/Id?Id=${deleteId}`)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("delete-successfully"));
        handleSearch();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
        setDeleteId(0);
        setDeleteQuestionOpen(false);
      });
  };

  const handleAdd = (e) => {
    props?.history?.push?.(`/admin/building-zone-create/${model.ProjectId}`);
  };

  const handleNextPage = (e) => {
    if (data.length <= 0) {
      AlertHelper.show("warning", "Warning", "Zone must be added!");
    } else {
      props?.history?.push?.(
        `/admin/building-construction-search/${model.ProjectId}`
      );
    }
  };

  const handlePreviousPage = (e) => {
    props?.history?.push?.(`/admin/general-information/${model.ProjectId}`);
  };

  const handleUpdate = (record) => (e) => {
    props?.history?.push?.(`/admin/building-zone-update/${record.id}`);
  };
  const handleSearch = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/EnergyConsumptionZones/Search", model)
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleCloseDeleteQuestion = () => {
    setDeleteQuestionOpen(false);
  };
  return (
    <>
      <GBESSDialog
        open={deleteQuestionOpen}
        title={t("delete-record")}
        content={t("are-you-sure-you")}
        handleAccept={handleDelete}
        handleReject={handleCloseDeleteQuestion}
      />
      <GBESSCard>
        <GBESSCardHeader
          title={t("search-building-zones")}
          subtitle={t("you-can-search-the")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="ZoneName"
                    value={model.ZoneName}
                    valueChanged={ValueChanged}
                    labelText={t("zone-name")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
       
      <GBESSDataGrid
        getRowId={(row) => row.Id}
        loading={isLoading}
        gridActionList={[
          {
            name: t("search"),
            onClick: handleSearch,
            code: "building-zone-search-search",
          },
          {
            name: t("add"),
            onClick: handleAdd,
            code: "building-zone-search-add",
          },
        ]}
        rows={data}
        columns={columns}
        checkboxSelection={false}
        disableSelectionOnClick={true}
      />
      <GBESSGridContainer>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSGridContainer>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-start" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handlePreviousPage}
                code={"building-zone-search-previous"}
              >
                {t("previous")}
              </GBESSButton>
            </GBESSGridItem>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-end" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handleNextPage}
                code={"building-zone-search-next"}
              >
                {t("next")}
              </GBESSButton>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSGridItem>
      </GBESSGridContainer>
    </>
  );
};
export default BuildingZoneSearch;
