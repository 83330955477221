import { makeStyles } from "@material-ui/core/styles";
import React, { useRef, useState } from "react";
import GBESCard from "../../../components/GBESS/GBESSCard";
import GBESCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESLabel from "../../../components/GBESS/GBESSLabel";
import GBESGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESButton from "../../../components/GBESS/GBESSButton";
import GBESDivider from "../../../components/GBESS/GBESSDivider";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useDispatch, useSelector } from "react-redux";
import {
  ElectriotyConstants,
  DemandElectrioty,
} from "core/constants/renewable.constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import EnergyConsumptionProjects from "./../../EnergyConsumption/Projects/EnergyConsumptionProjects";
import { tr } from "date-fns/locale";

const styles = {};
const useStyles = makeStyles(styles);

const ElectricityLoad = (props) => {
  // eslint-disable-next-line no-unused-vars
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const loads = useSelector((state) => state.loads.loads);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [model, setModel] = React.useState({});
  const [projectModel, setProjectModel] = React.useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const projecttype = useSelector((state) => state.projecttype.projecttype);

  React.useEffect(() => {
    projectModel.UserId = user.id;
    if (id > 0) {
      projectModel.ProjectId = id;
      setProjectModel({ ...projectModel });
      getRecord(id);
    }
  }, []);

  const getRecord = (id) => {
    if (id > 0) {
      if (projecttype === "GEST") {
        calculate();
      } else {
        Proxy.GET(`/coreapi/v1.0/RenewableEnergyDemand/GetById/Id?Id=${id}`)
          .withErrorAlert()
          .success((response) => {
            setProjectModel({ ...response.Item });
            if (
              response.Item.Demand !== null &&
              response.Item.Demand !== "" &&
              response.Item.Demand !== undefined
            ) {
              CalculateDemand(response.Item.Demand);
            }
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          });
      }
    }
  };

  const calculate = (e) => {
    setLoading(true);
    Proxy.POST("/coreapi/v1.0/EnergyConsumptionResults/Calculate", projectModel)
      .withErrorAlert()
      .success((response) => {
        if (
          response.Item?.EnergyConsumptionResults != null &&
          response.Item?.EnergyConsumptionResults.length > 0
        ) {
          let energyConsumptionResults = JSON.parse(
            response.Item?.EnergyConsumptionResults
          );
          let FinalEnergyResult = [];
          Object.keys(energyConsumptionResults).forEach((key) => {
            const value = energyConsumptionResults[key];
            if (value && value.FinalEnergy !== undefined) {
              const doubleValue = parseFloat(value.FinalEnergy) / 1000;
              FinalEnergyResult.push(
                doubleValue.toFixed(2).toString().replace(".", ",")
              );
            }
          });
          CalculateDemand(FinalEnergyResult.join("\n"));
        }
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setModel({ ...loads });
  }, [loads]);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel(model);
  };

  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      setLoading(true);
      try {
        const content = await readFileAsync(file);
        CalculateDemand(content);
      } catch (error) {
        AlertHelper.show("danger", "Error", "Error reading file.");
      } finally {
        setLoading(false);
      }
    } else {
      AlertHelper.show("danger", "Error", "Please upload a .txt file.");
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const CalculateDemand = (value) => {
    Proxy.POST("/coreapi/v1.0/Optimization/Demand", {
      Demand: value,
    })
      .withErrorAlert(() => {
        AlertHelper.show("danger", "Error", "File Uploaded ERROR!");
      })
      .success((response) => {
        if (response.Item === null || response.Item === undefined) {
          AlertHelper.show("danger", "Error", "File Uploaded ERROR!");
        } else {
          dispatch({
            type: DemandElectrioty.DEMAND_CHANGE_VALUE,
            demand: value,
          });
          projectModel.Demand = value;
          setProjectModel({ ...projectModel });
          setModel((model) => ({
            ...model,
            AverageLoad: response.Item.AverageLoad,
            MinLoad: response.Item.MinLoad,
            PeakLoad: response.Item.PeakLoad,
            AverageLoadDay: response.Item.AverageLoadDay,
            TotalLoad: response.Item.TotalLoad,
          }));
          dispatch({
            type: ElectriotyConstants.LOAD_CHANGE_VALUE,
            loads: response.Item,
          });
          AlertHelper.show("success", t("success"), "Successfully!");
        }
      });
  };

  const handleGoToNextPage = (value) => {
    let result = true;
    if (value !== 0) {
      if (Object.keys(loads).length === 0 && loads.constructor === Object) {
        result = false;
        AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
      }
    }
    if (result) {
      if (projecttype === "REST") {
        Proxy.POST("/coreapi/v1.0/RenewableEnergyDemand/Insert", projectModel)
          .withErrorAlert()
          .success((response) => {
            AlertHelper.show("success", t("success"), t("update-successfully"));
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          });
      }
      if (value == 0) {
        if (projecttype === "GEST") {
          props?.history?.push?.(
            `/admin/usage-profile/${projectModel.ProjectId}`
          );
        } else {
          props?.history?.push?.(
            `/admin/renewable-information/${projectModel.ProjectId}`
          );
        }
      } else {
        props?.history?.push?.(
          `/admin/renewable-system/${projectModel.ProjectId}`
        );
      }
    }
  };

  return (
    <GBESCard isLoading={loading}>
      <GBESCardHeader
        title={t("import_electricity_load")}
        subtitle={t("load_is_entered_in_txt_file_format")}
      />
      <GBESCardBody>
        <GBESGridContainer xs={12}>
          <GBESGridItem xs={12} sm={12} md={6}>
            <GBESGridContainer xs={12}>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept=".txt"
              />
              <GBESButton
                onClick={triggerFileInput}
                disabled={projecttype === "GEST"}
              >
                {t("upload_electricity_data")}
              </GBESButton>
              <GBESDivider />
              <GBESLabel bold text={t("load_system")} />
              <GBESTextInput
                name="AverageLoadDay"
                value={model.AverageLoadDay}
                valueChanged={ValueChanged}
                labelText={t("average_load_day")}
                disabled={true}
              />
              <GBESTextInput
                name="PeakLoad"
                value={model.PeakLoad}
                valueChanged={ValueChanged}
                labelText={t("peak_load")}
                disabled={true}
              />
              <GBESTextInput
                name="MinLoad"
                value={model.MinLoad}
                valueChanged={ValueChanged}
                labelText={t("min_load")}
                disabled={true}
              />
              <GBESTextInput
                name="AverageLoad"
                value={model.AverageLoad}
                valueChanged={ValueChanged}
                labelText={t("average_load")}
                disabled={true}
              />
              <GBESTextInput
                name="TotalLoad"
                value={model.TotalLoad}
                valueChanged={ValueChanged}
                labelText={t("annual_load")}
                disabled={true}
              />
              {/* <GBESDivider /> */}
              {/* <GBESLabel bold text="Grafik" /> */}
            </GBESGridContainer>
          </GBESGridItem>
        </GBESGridContainer>
      </GBESCardBody>
      <GBESCardFooter>
        <GBESButton onClick={(e) => handleGoToNextPage(0)}>
          {t("back")}
        </GBESButton>
        <GBESButton onClick={(e) => handleGoToNextPage(1)}>
          {t("next")}
        </GBESButton>
      </GBESCardFooter>
    </GBESCard>
  );
};
export default ElectricityLoad;
