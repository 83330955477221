import React, { useEffect } from "react";
import {
  GBESSGridContainer,
  GBESSGridItem,
  GBESSCard,
  GBESSTextInput,
  GBESSSelectInput,
  GBESSCardHeader,
  GBESSCardBody,
  GBESSCardFooter,
  GBESSButton,
  GBESSCardAvatar,
} from "./../../components/GBESS";
import { makeStyles } from "@material-ui/core/styles";
import avatar from "assets/img/faces/marc.jpg";
import { useParams } from "react-router-dom";
import AlertHelper from "./../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const UserProfile = (props) => {
  const [model, setModel] = React.useState({});
  const [modelValidate, setModelValidate] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  useEffect(() => {
    var lang = localStorage.getItem("language");
    if (lang) {
      i18n.changeLanguage(lang);
      model["LanguageId"] = lang;
      setModel({ ...model });
    }
    if (id > 0) {
      getRecord(id);
    } else {
      getRecord(user.id);
    }
  }, []);

  const user = useSelector((state) => state.user.user);
  const ValueChanged = (name, value) => {
    if (name == "LanguageId") {
      i18n.changeLanguage(value);
      localStorage.setItem("language", value);
    }
    model[name] = value;
    setModel({ ...model });
  };

  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/User/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        setModel({
          ...response.Item,
          ...{ LanguageId: localStorage.getItem("language") },
        });
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleUpdate = (e) => {
    let tempValidateModel = {
      TcNo: false,
      FirstName: false,
      LastName: false,
      Mail: false,
      RoleId: false,
    };
    let result = true;
    if (model.RoleId == null || model.RoleId == "" || model.RoleId <= 0) {
      tempValidateModel.RoleId = true;
      result = false;
    }
    if (model.TcNo == null || model.TcNo == "") {
      tempValidateModel.TcNo = true;
      result = false;
    }
    if (model.FirstName == null || model.FirstName == "") {
      tempValidateModel.FirstName = true;
      result = false;
    }
    if (model.LastName == null || model.LastName == "") {
      tempValidateModel.LastName = true;
      result = false;
    }
    if (model.Mail == null || model.Mail == "") {
      tempValidateModel.Mail = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/User/Update", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };

  return (
    <>
      <GBESSGridContainer>
        <GBESSGridItem xs={12} sm={12} md={8}>
          <GBESSCard>
            <GBESSCardHeader
              title={t("edit-user")}
              subtitle={t("edit-user-page")}
            />
            <GBESSCardBody>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="TcNo"
                    value={model.TcNo}
                    error={modelValidate.TcNo}
                    valueChanged={ValueChanged}
                    labelText={t("identity-number")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="FirstName"
                    value={model.FirstName}
                    error={modelValidate.FirstName}
                    valueChanged={ValueChanged}
                    labelText={t("first-name")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="LastName"
                    value={model.LastName}
                    error={modelValidate.LastName}
                    valueChanged={ValueChanged}
                    labelText={t("last-name")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="Mail"
                    value={model.Mail}
                    error={modelValidate.Mail}
                    valueChanged={ValueChanged}
                    labelText="E-Mail"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="Company"
                    value={model.Company}
                    valueChanged={ValueChanged}
                    labelText={t("Company")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="Job"
                    value={model.Job}
                    valueChanged={ValueChanged}
                    labelText={t("Job")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="PhoneNumber"
                    value={model.PhoneNumber}
                    valueChanged={ValueChanged}
                    labelText={t("phone-number")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="ExtensionNumber"
                    value={model.ExtensionNumber}
                    valueChanged={ValueChanged}
                    labelText={t("extension-number")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSTextInput
                    name="EducationalStatus"
                    value={model.EducationalStatus}
                    valueChanged={ValueChanged}
                    labelText={t("educational-status")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Role/Search"}
                    labelText={t("role")}
                    name="RoleId"
                    value={model.RoleId}
                    error={modelValidate.RoleId}
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="RoleName"
                    isDisabled={id > 0 ? false : true}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={4}>
                  <GBESSSelectInput
                    data={[
                      { Id: "tr", Language: t("tr") },
                      { Id: "en", Language: t("en") },
                      { Id: "ru", Language: t("ru") },
                    ]}
                    labelText={t("Languages")}
                    name="LanguageId"
                    value={model.LanguageId}
                    error={modelValidate.LanguageId}
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="Language"
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSCardBody>
            <GBESSCardFooter>
              <GBESSButton code="user-profile-update" onClick={handleUpdate}>
                {t("Update")}
              </GBESSButton>
            </GBESSCardFooter>
          </GBESSCard>
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={4}>
          <GBESSCard profile>
            <GBESSCardAvatar profile>
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img src={avatar} alt="..." />
              </a>
            </GBESSCardAvatar>
            <GBESSCardBody profile>
              <h6>{model.Job}</h6>
              <h4>
                {model.FirstName} {model.LastName}
              </h4>
              <p></p>
            </GBESSCardBody>
          </GBESSCard>
        </GBESSGridItem>
      </GBESSGridContainer>
    </>
  );
};
export default UserProfile;
