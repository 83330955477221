import * as React from "react";
import { useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../assets/css/styles.css";
import CodeSenderModal from "./components/CodeSenderModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import { useTranslation } from "react-i18next";
import Proxy from "core/Proxy";
import AlertHelper from "../../core/helpers/AlertHelper";
import { userConstants } from "../../core/constants/auth.constants.core";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/inputBorderStyle.js";
import LinearProgress from "@mui/material/LinearProgress";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const theme = createTheme();
const useStyles = makeStyles(styles);
export default function LoginPage(props) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [model, setModel] = React.useState({});

  const [codeSenderModalShow, setCodeSenderModalShow] = React.useState(false);
  const [resetPasswordModalShow, setResetPasswordModalShow] =
    React.useState(false);
  const [recoveryEmail, setRecoveryEmail] = React.useState("");

  const handleChange = (event) => {
    model[event.target.name] = event.target.value;
    setModel({ ...model });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    JSON.stringify(model);
    Proxy.POST("/coreapi/v1.0/Authentication/Auth", model)
      .withErrorAlert()
      .success((response) => {
        const responseUser = {
          mail: response.Item.Mail,
          password: response.Item.Password,
          roleCode: response.Item.RoleCode,
          roleName: response.Item.RoleName,
          roleId: response.Item.RoleId,
          token: response.Item.Token,
          id: response.Item.Id,
        };
        localStorage.setItem("user", JSON.stringify(responseUser));
        dispatch({
          type: userConstants.LOGIN_SUCCESS,
          user: responseUser,
          loggedIn: true,
        });
        props?.history?.push?.("/admin");
        AlertHelper.show("success", t("success"), t("login-successfully"));
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleShowCodeSenderModal = () => {
    setCodeSenderModalShow(!codeSenderModalShow);
  };

  const handleShowResetPassswordModal = () => {
    setResetPasswordModalShow(!resetPasswordModalShow);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <ThemeProvider theme={theme}>
      <CodeSenderModal
        show={codeSenderModalShow}
        handleShow={handleShowCodeSenderModal}
        handleShowResetPasswordModal={handleShowResetPassswordModal}
        handleRecoveryEmail={setRecoveryEmail}
      />
      <ResetPasswordModal
        email={recoveryEmail}
        show={resetPasswordModalShow}
        handleShow={handleShowResetPassswordModal}
      />

      <div className="login-wrapper">
        <Container
          component="main"
          maxWidth="xs"
          sx={{ bgcolor: "#fff", borderRadius: "0.5rem", overflow: "hidden" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "400px",
            }}
          >
            {isLoading ? (
              <LinearProgress
                className="linear-progress"
                color="success"
                style={{
                  borderRadius: "5px",
                  height: "5px",
                  overflow: "visible",
                  width: "100%",
                }}
              />
            ) : null}
            <Avatar sx={{ m: 1, marginTop: "15px", bgcolor: "#4caf50" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("welcome")}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("E-mail")}
                  name="Mail"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChange}
                  className={classes.borderStyle}
                  type="email"
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="Password"
                  label={t("Password")}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  className={classes.borderStyle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: "#4caf50",
                    height: "48px",
                    fontSize: "1rem",
                    ":hover": {
                      bgcolor: "#2C9530",
                    },
                  }}
                >
                  {t("Login")}
                </Button>
              </form>
              <Grid container sx={{ margin: "1rem" }}>
                <Grid item xs>
                  <Link
                    onClick={handleShowCodeSenderModal}
                    sx={{
                      ml: "-10px",
                      cursor: "pointer",
                      color: "black",
                      fontWeight: 400,
                    }}
                  >
                    {t("Forgot_Password")}
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="/signup"
                    sx={{ mr: 3, ml: 1, color: "black", fontWeight: 400 }}
                  >
                    {t("Sign_Up")}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
