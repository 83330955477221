export default class StringHelper {
  /**
   *
   * @param {any} str Input data
   * @param {object?} outputObj Returns the parsed object if the string is valid JSON, otherwise returns input data
   * @returns {boolean}
   */
  static tryJsonParse(str) {
    var data = str;
    if (typeof str === "string") {
      try {
        // eslint-disable-next-line no-unused-vars
        data = JSON.parse(str);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    return data;
  }

  /**
   *
   * @param {string} text
   * @param {Array<string>} parameters
   */
  static format(text, parameters) {
    if (text == null) return null;

    if (parameters == null) return text;

    return text.replace(/{(\d+)}/g, function (match, number) {
      return typeof parameters[number] != "undefined"
        ? parameters[number]
        : match;
    });
  }
}
