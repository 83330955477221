import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { successColor } from "assets/jss/material-dashboard-react";
import baseStyles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import combineStyles from "./../../core/helpers/combineStyles";
import GBESSGridContainer from "./GBESSGridContainer";
import GBESSGridItem from "./GBESSGridItem";
import GBESSLabel from "./GBESSLabel";

// @material-ui/core components
// @material-ui/icons
// core components
const styles = {
  root: {
    margin: "0px 0px 16px 0px",
  },
  label: {
    marginTop: 16,
  },
  underline: {
    color: successColor[0],
  },
};
const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSTextInput(props) {
  const [textInputProps, setTextInputProps] = React.useState();
  const classes = useStyles();
  const {
    labelText,
    labelProps,
    inputProps,
    rows,
    labelMd,
    value,
    valueChanged,
    name,
    ...rest
  } = props;

  useEffect(() => {
    const ValueChanged = (e) => {
      var value = e.target.value;
      valueChanged && valueChanged(name, value, e);
    };

    setTextInputProps({
      onChange: ValueChanged,
      className: classes.multilineColor,
      ...inputProps,
    });
  }, [valueChanged]);

  return (
    <GBESSGridItem xs={12} className={classes.root}>
      <GBESSGridContainer>
        <GBESSGridItem
          xs={labelMd > 0 ? labelMd : false}
          className={classes.label}
        >
          <GBESSLabel {...rest} noGrid text={labelText} {...labelProps} />
        </GBESSGridItem>
        <GBESSGridItem xs={12 - labelMd}>
          <TextField
            fullWidth
            value={value}
            rows={rows}
            multiline={rows > 1}
            InputProps={textInputProps}
            {...rest}
          />
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSGridItem>
  );
}
GBESSTextInput.defaultProps = {
  fullWidth: true,
  labelMd: 5,
  labelProps: {},
  inputProps: {},
};
GBESSTextInput.propTypes = {
  disabled: PropTypes.any,
  error: PropTypes.bool,
  formControlProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  labelMd: PropTypes.number,
  labelProps: PropTypes.object,
  labelText: PropTypes.node,
  maxLength: PropTypes.any,
  minLength: PropTypes.any,
  name: PropTypes.string,
  rows: PropTypes.number,
  rtlActive: PropTypes.bool,
  step: PropTypes.any,
  success: PropTypes.bool,
  value: PropTypes.string,
  valueChanged: PropTypes.func,
};
export default GBESSTextInput;
