import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESSDataGrid from "./../../../components/GBESS/GBESSDataGrid";
import GBESSDialog from "./../../../components/GBESS/GBESSDialog";
import GBESSIconButton from "./../../../components/GBESS/GBESSIconButton";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const MaterialsSearch = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteQuestionOpen, setDeleteQuestionOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 225,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <GBESSIconButton
              aria-label="delete"
              onClick={handleDeleteQuestion(params)}
              code="materials-search-delete"
            >
              <DeleteIcon />
            </GBESSIconButton>
            <GBESSIconButton
              aria-label="edit"
              onClick={handleUpdate(params)}
              code="materials-search-edit"
            >
              <EditIcon />
            </GBESSIconButton>
          </>
        );
      },
    },
    {
      field: "MaterialName",
      headerName: t("material-name"),
      width: 225,
      editable: true,
    },
    {
      field: "Thickness",
      headerName: t("thickness"),
      width: 225,
      editable: true,
    },
    {
      field: "K",
      headerName: "k",
      width: 225,
      editable: true,
    },
  ];

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleDeleteQuestion = (record) => (e) => {
    setDeleteId(record.id);
    setDeleteQuestionOpen(true);
  };
  const handleDelete = (e) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/Materials/Delete/Id?Id=${deleteId}`)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("delete-successfully"));
        handleSearch();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
        setDeleteId(0);
        setDeleteQuestionOpen(false);
      });
  };

  const handleAdd = (e) => {
    props?.history?.push?.("materials-create");
  };
  const handleUpdate = (record) => (e) => {
    props?.history?.push?.(`materials-update/${record.id}`);
  };
  const handleSearch = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/Materials/Search", model)
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleCloseDeleteQuestion = () => {
    setDeleteQuestionOpen(false);
  };
  return (
    <>
      <GBESSDialog
        open={deleteQuestionOpen}
        title={t("delete-record")}
        content={t("are-you-sure-you")}
        handleAccept={handleDelete}
        handleReject={handleCloseDeleteQuestion}
      />
      <GBESSCard>
        <GBESSCardHeader
          title={t("search-materials")}
          subtitle={t("search-material-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="MaterialName"
                    value={model.MaterialName}
                    valueChanged={ValueChanged}
                    labelText={t("material-name")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
       
      <GBESSDataGrid
        getRowId={(row) => row.Id}
        loading={isLoading}
        gridActionList={[
          {
            name: t("search"),
            onClick: handleSearch,
            code: "materials-search-search",
          },
          { name: t("add"), onClick: handleAdd, code: "materials-search-add" },
        ]}
        rows={data}
        columns={columns}
        checkboxSelection={false}
        disableSelectionOnClick={true}
      />
    </>
  );
};
export default MaterialsSearch;
