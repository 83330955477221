import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import Proxy from "core/Proxy";
import AlertHelper from "../../../core/helpers/AlertHelper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import LinearProgress from "@mui/material/LinearProgress";
// nodejs library to set properties for components
import PropTypes from "prop-types";

const theme = createTheme();

export default function CodeSenderModal({
  show,
  handleShow,
  handleShowResetPasswordModal,
  handleRecoveryEmail,
}) {
  const { t } = useTranslation();
  const [receiver, setReceiver] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    Proxy.POST(`/coreapi/v1.0/Recovery/SendCode/?mail=${receiver}`)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show(
          "success",
          t("success"),
          "Sent Code E-Mail Successfully!"
        );
        codeSentSuccess();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleReceiver = (event) => {
    setReceiver(event.target.value);
    handleRecoveryEmail(event.target.value);
  };

  const codeSentSuccess = () => {
    setIsLoading(false);
    handleShow(); //Modal kapatılır.
    handleShowResetPasswordModal(); //Kodun girileceği ekranı açıyor
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={show}
        onClose={() => {
          setIsLoading(false);
          handleShow();
        }}
        className="modal-dialog"
      >
        {isLoading ? (
          <LinearProgress
            className="linear-progress"
            color="success"
            style={{
              borderRadius: "5px",
              height: "5px",
              overflow: "visible",
              width: "100%",
            }}
          />
        ) : null}
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("Password_Recovery")}</Box>
            <Box>
              <IconButton
                onClick={() => {
                  setIsLoading(false);
                  handleShow();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ overflow: "hidden" }}>
            <DialogContentText>
              {t("Code_Sender_Modal_Message")}
            </DialogContentText>
            <TextField
              value={receiver}
              onChange={(e) => handleReceiver(e)}
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("E-mail")}
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              sx={{
                "& label.Mui-focused": {
                  color: "black",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#e5e5ea",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#e5e5ea",
                  },
                },
              }}
            />
          </DialogContent>
          <DialogActions sx={{ marginX: "22px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "gray",
                opacity: ".9",
                height: "40px",
                fontSize: ".9rem",
                ":hover": {
                  bgcolor: "gray",
                  opacity: ".7",
                },
              }}
              onClick={() => {
                setIsLoading(false);
                handleShow();
              }}
            >
              {t(t("cancel"))}
            </Button>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={{
                marginLeft: "20px !important",
                backgroundColor: "#4caf50",
                height: "40px",
                fontSize: ".9rem",
                ":hover": {
                  bgcolor: "#2C9530",
                },
              }}
            >
              {t("Send_Code")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
}

CodeSenderModal.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func,
  handleShowResetPasswordModal: PropTypes.func,
  handleRecoveryEmail: PropTypes.func,
};
