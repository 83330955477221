import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import LoginPage from "views/Login/Login.js";
import SignUpPage from "views/SignUp/SignUp";
import PrivateRoute from "./components/Route/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import {
  menusConstants,
  actionsConstants,
} from "core/constants/auth.constants.core";
import Proxy from "core/Proxy";
import "./scrollbar.css";

//Route yapısı
export default () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (user?.roleId > 0) {
      getAllMenu();
      getAllAction();
    } else {
      dispatch({
        type: menusConstants.MENUS_REMOVE_ALL,
      });
      dispatch({
        type: actionsConstants.ACTIONS_REMOVE_ALL,
      });
    }
  }, [user?.roleId]);
  const getAllMenu = () => {
    Proxy.POST("/coreapi/v1.0/MenuRole/Search", { RoleId: user.roleId })
      .withErrorAlert()
      .success((response) => {
        dispatch({
          type: menusConstants.MENUS_ADD_ALL,
          menus: response?.Item?.map?.((x) => x.Menu),
        });
      })
      .error((error) => {});
  };
  const getAllAction = () => {
    Proxy.POST("/coreapi/v1.0/ActionRole/Search", { RoleId: user.roleId })
      .withErrorAlert()
      .success((response) => {
        dispatch({
          type: actionsConstants.ACTIONS_ADD_ALL,
          actions: response?.Item?.map?.((x) => x.Action),
        });
      })
      .error((error) => {});
  };
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <PrivateRoute path="/signup" component={SignUpPage} />
      <PrivateRoute path="/admin" component={Admin} />
      <PrivateRoute path="/rtl" component={RTL} />
      <Redirect from="/" to="/login" />
    </Switch>
  );
};
