import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";
import React from "react";

import GBESSGridItem from "./GBESSGridItem";

const styles = {
  root: {
    margin: "16px",
  },
};
const useStyles = makeStyles(styles);

function GBESSDivider({ transparent }) {
  const classes = useStyles();
  return (
    <GBESSGridItem noPadding xs={12} className={classes.root}>
      {!transparent && <Divider />}
    </GBESSGridItem>
  );
}

GBESSDivider.propTypes = {
  transparent: PropTypes.any,
};
export default GBESSDivider;
