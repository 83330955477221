import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import tr from "./locales/tr.json";
import ru from "./locales/ru.json";

//Dil çeviri işlemi için

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    tr: { translation: tr },
    ru: { translation: ru },
  },
  lng: localStorage.getItem("language") ?? "en", //Seçili olan dili buradan değiştiriyoruz. "en","rus" or "tr"
  fallbackLng: localStorage.getItem("language") ?? "en",
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
    useSuspense: false,
  },
});

export default i18n;
