import "moment-timezone";

import moment from "moment";

export default class DateHelper {
  static GetDate() {
    return moment.utc().startOf("day");
  }

  static GetDateTime() {
    return moment.utc();
  }

  static GetDateLocal() {
    return moment().startOf("day");
  }

  static GetDateTimeLocal() {
    return moment();
  }

  static GetTimeZone(disableCache) {
    return moment.tz.guess(disableCache);
  }

  static GetStoredTimeZone(disableCache) {
    return localStorage.getItem("zone") || moment.tz.guess(disableCache);
  }

  static GetYear() {
    return moment.utc().format("YYYY");
  }

  /**
   * @param {moment.MomentInput} date
   */
  static ToMoment(date) {
    return moment.utc(date);
  }

  /**
   * @param {moment.MomentInput} date
   * @param {string} format
   */
  static ToMomentWithFormat(date, format) {
    return moment.utc(date, format);
  }

  static SetSelectedZoneId(zoneId) {
    localStorage.setItem("zone", zoneId);
  }

  static GetSelectedZoneId() {
    return localStorage.getItem("zone") || this.GetTimeZone();
  }

  static SetZoneList(zoneList) {
    localStorage.setItem("zoneList", JSON.stringify(zoneList));
  }
  static GetZoneList() {
    return JSON.parse(localStorage.getItem("zoneList")) || [];
  }

  static ToTimeZone(date) {
    return moment
      .tz(
        moment.tz(date, "UTC"),
        localStorage.getItem("zone") || this.GetTimeZone()
      )
      .format("DD/MMM/YY hh:mm:ss:SSS A");
  }
}
