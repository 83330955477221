import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import baseStyles from "assets/jss/material-dashboard-react/components/buttonStyle.js";
import classNames from "classnames";
import combineStyles from "core/helpers/combineStyles";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  "success-outline": {
    ...baseStyles.success,
  },
};

const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSIconButton(props) {
  const classes = useStyles();
  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    isAuthDisabled,
    code,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
    [classes["success-outline"]]: color === "success-outline",
  });
  const actions = useSelector((state) => state.actions.actions);
  const [isDisabled, setDisabled] = React.useState(true);
  React.useEffect(() => {
    const isActionValid = actions.find((x) => x.ActionCode === code);
    setDisabled(!isActionValid);
  }, [actions]);
  return (
    <IconButton
      {...rest}
      classes={muiClasses}
      className={btnClasses}
      disabled={
        disabled === true
          ? disabled
          : isAuthDisabled === false
          ? isDisabled
          : disabled
      }
    >
      {children}
    </IconButton>
  );
}

GBESSIconButton.defaultProps = {
  color: "success-outline",
  isAuthDisabled: false,
  size: "md",
  round: true,
  justIcon: true,
};
GBESSIconButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "success-outline",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  disabled: PropTypes.bool,
  justIcon: PropTypes.bool,
  link: PropTypes.bool,
  muiClasses: PropTypes.object, // use this to pass the classes props from Material-UI
  round: PropTypes.bool,
  simple: PropTypes.bool,
  isAuthDisabled: PropTypes.bool,
  code: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg"]),
};

export default GBESSIconButton;
