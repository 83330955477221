/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
// core components
import "assets/css/material-dashboard-react.css";
import "animate.css/animate.min.css";
import { createBrowserHistory } from "history";
import App from "App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import configureStore from "./store/configureStore";
import ErrorBoundaryComponent from "core/ErrorBoundary";
import { withErrorBoundary } from "react-error-boundary";
const history = createBrowserHistory();

export const store = configureStore(history);

export const ComponentWithErrorBoundary = withErrorBoundary(App);

ReactDOM.render(
  <ErrorBoundaryComponent>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <ReactNotification />
        <I18nextProvider i18n={i18n}>
          <ComponentWithErrorBoundary />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundaryComponent>,
  document.getElementById("root")
);
