import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import Proxy from "core/Proxy";
import AlertHelper from "../../../core/helpers/AlertHelper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/inputBorderStyle.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Timer } from "./Timer";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
const theme = createTheme();
const useStyles = makeStyles(styles);
export default function ResetPasswordModal({ email, show, handleShow }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [model, setModel] = React.useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    model.Mail = email;
    setModel({ ...model });
    JSON.stringify(model);
    Proxy.POST("/coreapi/v1.0/Recovery/ResetPassword", model)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("password-updated"));
        handleShow();
        setIsLoading(false);
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    model[event.target.name] = event.target.value;
    setModel({ ...model });
  };

  const validateForm = () => {
    return (
      model !== null &&
      model["RecoveryCode"] &&
      model["NewPassword"] &&
      model["ConfirmPassword"] &&
      model["RecoveryCode"].length === 6 &&
      model["NewPassword"].length > 0 &&
      model["ConfirmPassword"].length > 0 &&
      model["NewPassword"] === model["ConfirmPassword"]
    );
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const regexNumber = (value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={show}
        onClose={() => {
          setIsLoading(false);
          handleShow();
        }}
        className="modal-dialog"
      >
        {isLoading ? (
          <LinearProgress
            className="linear-progress"
            color="success"
            style={{
              borderRadius: "5px",
              height: "5px",
              overflow: "visible",
              width: "100%",
            }}
          />
        ) : null}
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t("Password_Recovery")}</Box>
            <Box>
              <IconButton
                onClick={() => {
                  setIsLoading(false);
                  handleShow();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              name="NewPassword"
              label={t("New_Password")}
              type={showPassword ? "text" : "password"}
              autoFocus
              className={classes.borderStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              onChange={handleChange}
              margin="normal"
              required
              fullWidth
              name="ConfirmPassword"
              label={t("Confirm_Password")}
              type={showPassword ? "text" : "password"}
              className={classes.borderStyle}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              inputProps={{
                maxLength: 6,
                minLength: 6,
              }}
              onChange={(e) => {
                regexNumber(e.target.value) ? handleChange(e) : null;
              }}
              margin="normal"
              required
              fullWidth
              label={t("Recovery_Code")}
              name="RecoveryCode"
              type="text"
              value={
                model["RecoveryCode"] === undefined ? "" : model["RecoveryCode"]
              }
              className={classes.borderStyle}
            />
            <Timer></Timer>
          </DialogContent>
          <DialogActions sx={{ marginX: "18px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "gray",
                opacity: ".9",
                height: "40px",
                fontSize: ".9rem",
                ":hover": {
                  bgcolor: "gray",
                  opacity: ".7",
                },
              }}
              onClick={() => {
                setIsLoading(false);
                handleShow();
              }}
            >
              {t(t("cancel"))}
            </Button>
            <Button
              variant="contained"
              disabled={!validateForm() || isLoading}
              type="submit"
              sx={{
                marginLeft: "20px !important",
                backgroundColor: "#4caf50",
                height: "40px",
                fontSize: ".9rem",
                ":hover": {
                  bgcolor: "#2C9530",
                },
              }}
            >
              {t(t("confirm"))}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </ThemeProvider>
  );
}

ResetPasswordModal.propTypes = {
  email: PropTypes.string,
  show: PropTypes.bool,
  handleShow: PropTypes.func,
};
