import { userConstants } from "../constants/auth.constants.core";
import { Action, Reducer } from "redux";
import { UserState, KnownAction } from "../actions/auth.actions";
import { IAuthUser } from "../../models/AuthUser";

var user = localStorage.getItem("user");
const userCookie: IAuthUser = JSON.parse(user as any);
//User authentication (kullanıcı kimlik doğrulama) ait reducer
export const reducer: Reducer<UserState> = (
  state: UserState | undefined,
  incomingAction: Action
): UserState => {
  if (state === undefined) {
    return { user: userCookie, loggedIn: userCookie ? true : false };
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
        loggedIn: action.loggedIn,
      };

    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
      };

    case userConstants.LOGOUT:
      return { loggedIn: false };

    default:
      return state;
  }
};
