import React, { useEffect } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSDivider from "../../../components/GBESS/GBESSDivider";
import GBESSExportDataButton from "../../../components/GBESS/GBESSExportDataButton";
import GBESSLabel from "../../../components/GBESS/GBESSLabel";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const styles = {};
const useStyles = makeStyles(styles);
const CommunityResults = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});

  // const [model, setModel] = React.useState({
  //   ElectricityConsumptionOfCommunity: 36456,
  //   ElectricityGenerationOfCommunity: 54961,
  //   ElectricitySoldToTheGrid: 39860,
  //   ElectricityFromTheGrid: 21355,
  //   ElectricityFromHRES: 15101,
  // });
  const [energyConsumptionResults, setEnergyConsumptionResults] =
    React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
      calculate();
    }
    setModel({ ...model });
  }, []);

  const handlePreviousPage = (e) => {
    props?.history?.push?.(
      `/admin/community-buildings-search/${model.ProjectId}`
    );
  };

  const calculate = (e) => {
    setIsLoading(true);

    Proxy.POST("/coreapi/v1.0/CommunityResults/Calculate", model)
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
        if (
          response.Item?.EnergyConsumptionResults != null &&
          response.Item?.EnergyConsumptionResults.length > 0
        ) {
          setEnergyConsumptionResults(
            JSON.parse(response.Item?.EnergyConsumptionResults)
          );
        }
        AlertHelper.show("success", t("success"), t("calculate-successfully"));
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const saveProject = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/CommunityProjectsController/SaveProject", {
      ProjectId: model.ProjectId,
    })
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", "Success  ", "Save Project");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("community-results")}
          subtitle={t("community-result-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    bold
                    text={t("community-energy-consumption")}
                  />
                  <GBESSDivider />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ElectricityConsumptionOfCommunity"
                    disabled={true}
                    value={model.ElectricityConsumptionOfCommunity}
                    valueChanged={ValueChanged}
                    labelText={t("community-results-electricity-consumption")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ElectricityGenerationOfCommunity"
                    disabled={true}
                    value={model.ElectricityGenerationOfCommunity}
                    valueChanged={ValueChanged}
                    labelText={t("community-results-electricity-generation")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ElectricitySoldToTheGrid"
                    disabled={true}
                    value={model.ElectricitySoldToTheGrid}
                    valueChanged={ValueChanged}
                    labelText={t("community-results-electricity-sold")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ElectricityFromTheGrid"
                    disabled={true}
                    value={model.ElectricityFromTheGrid}
                    valueChanged={ValueChanged}
                    labelText={t("community-results-electricity-from")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ElectricityFromHRES"
                    disabled={true}
                    value={model.ElectricityFromHRES}
                    valueChanged={ValueChanged}
                    labelText={t("community-results-electricity-from-hres")}
                    type="number"
                  />
                </GBESSGridItem>
              </GBESSGridContainer>

              <GBESSGridContainer>
                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  xs={12}
                  sm={4}
                  md={4}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={handlePreviousPage}
                    code={"community-result-previous"}
                  >
                    {t("previous")}
                  </GBESSButton>
                </GBESSGridItem>

                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-center" }}
                  xs={12}
                  sm={4}
                  md={4}
                >
                  <GBESSExportDataButton
                    data={energyConsumptionResults}
                    excelFileName="Consumption Summary"
                    labelText="Consumption Summary"
                    code={"community-result-download"}
                  />
                </GBESSGridItem>

                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  xs={12}
                  sm={4}
                  md={4}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={saveProject}
                    code={"community-result-submit"}
                  >
                    Save Project
                  </GBESSButton>
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default CommunityResults;
