import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import baseStyles from "assets/jss/material-dashboard-react/components/cardHeaderStyle.js";
import combineStyles from "core/helpers/combineStyles";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    display: "flex",
  },
};
const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSCardHeader(props) {
  const classes = useStyles();
  const {
    title,
    subtitle,
    className,
    children,
    color,
    plain,
    stats,
    icon,
    exportDataButtonComponent,
    ...rest
  } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + "CardHeader"]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [className]: className !== undefined,
  });
  return (
    <div className={cardHeaderClasses} {...rest}>
      <h4 className={classes.cardTitleWhite}>
        {title} {exportDataButtonComponent ? exportDataButtonComponent : null}
      </h4>
      <p className={classes.cardCategoryWhite}>{subtitle}</p>
      {children}
    </div>
  );
}

GBESSCardHeader.defaultProps = {
  color: "success",
};
GBESSCardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  icon: PropTypes.bool,
  plain: PropTypes.bool,
  stats: PropTypes.bool,
  subtitle: PropTypes.any,
  title: PropTypes.any,
  exportDataButtonComponent: PropTypes.any,
};
export default GBESSCardHeader;
