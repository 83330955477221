import React, { useEffect, useRef } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSDateTimePicker from "../../../components/GBESS/GBESSDateTimePicker";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESSLabel from "../../../components/GBESS/GBESSLabel";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESSDivider from "../../../components/GBESS/GBESSDivider";
import GBESSTabBar from "../../../components/GBESS/GBESSTabBar";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCheckBox from "../../../components/GBESS/GBESSCheckBox";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  GridConstants,
  DesignConstants,
} from "core/constants/renewable.constants";

const styles = {};
const useStyles = makeStyles(styles);
const GeneralInformation = (props) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const renewInfo = useSelector((state) => state.gridtype.gridtype);
  const designtype = useSelector((state) => state.designtype.designtype);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [model, setModel] = React.useState({
    HasEpwData: false,
    HeatingSeasonFromDate: new Date("2023-01-01T00:00:00"),
    HeatingSeasonToDate: new Date("2023-01-01T00:00:00"),
    CoolingSeasonFromDate: new Date("2023-01-01T00:00:00"),
    CoolingSeasonToDate: new Date("2023-01-01T00:00:00"),
    CoolingEer: 5,
    HeatingSysCop: 5,
    CoalHeatingValue: 6.9,
    WoodHeatingValue: 2.9,
    NaturalGasHeatingValue: 9.5,
  });
  const [seasonModel, setSeasonModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const [soilTemperatureData, setSoilTemperatureData] = React.useState([
    {
      Id: 1,
      Month: 1,
      MonthText: t("january"),
      Temperature: 12,
    },
    {
      Id: 2,
      Month: 2,
      MonthText: t("february"),
      Temperature: 12,
    },
    {
      Id: 3,
      Month: 3,
      MonthText: t("march"),
      Temperature: 12,
    },
    {
      Id: 4,
      Month: 4,
      MonthText: t("april"),
      Temperature: 12,
    },
    {
      Id: 5,
      Month: 5,
      MonthText: t("may"),
      Temperature: 12,
    },
    {
      Id: 6,
      Month: 6,
      MonthText: t("june"),
      Temperature: 12,
    },
    {
      Id: 7,
      Month: 7,
      MonthText: t("july"),
      Temperature: 12,
    },
    {
      Id: 8,
      Month: 8,
      MonthText: t("august"),
      Temperature: 12,
    },
    {
      Id: 9,
      Month: 9,
      MonthText: t("september"),
      Temperature: 12,
    },
    {
      Id: 10,
      Month: 10,
      MonthText: t("october"),
      Temperature: 12,
    },
    {
      Id: 11,
      Month: 11,
      MonthText: t("november"),
      Temperature: 12,
    },
    {
      Id: 12,
      Month: 12,
      MonthText: t("december"),
      Temperature: 12,
    },
  ]);
  const [waterUsageData, setWaterUsageData] = React.useState([
    {
      Id: 1,
      Hour: 1,
      TotalUsageValue: 100,
    },
    {
      Id: 2,
      Hour: 2,
      TotalUsageValue: 100,
    },
    {
      Id: 3,
      Hour: 3,
      TotalUsageValue: 100,
    },
    {
      Id: 4,
      Hour: 4,
      TotalUsageValue: 100,
    },
    {
      Id: 5,
      Hour: 5,
      TotalUsageValue: 100,
    },
    {
      Id: 6,
      Hour: 6,
      TotalUsageValue: 100,
    },
    {
      Id: 7,
      Hour: 7,
      TotalUsageValue: 100,
    },
    {
      Id: 8,
      Hour: 8,
      TotalUsageValue: 100,
    },
    {
      Id: 9,
      Hour: 9,
      TotalUsageValue: 100,
    },
    {
      Id: 10,
      Hour: 10,
      TotalUsageValue: 100,
    },
    {
      Id: 11,
      Hour: 11,
      TotalUsageValue: 100,
    },
    {
      Id: 12,
      Hour: 12,
      TotalUsageValue: 100,
    },
    {
      Id: 13,
      Hour: 13,
      TotalUsageValue: 100,
    },
    {
      Id: 14,
      Hour: 14,
      TotalUsageValue: 100,
    },
    {
      Id: 15,
      Hour: 15,
      TotalUsageValue: 100,
    },
    {
      Id: 16,
      Hour: 16,
      TotalUsageValue: 100,
    },
    {
      Id: 17,
      Hour: 17,
      TotalUsageValue: 100,
    },
    {
      Id: 18,
      Hour: 18,
      TotalUsageValue: 100,
    },
    {
      Id: 19,
      Hour: 19,
      TotalUsageValue: 100,
    },
    {
      Id: 20,
      Hour: 20,
      TotalUsageValue: 100,
    },
    {
      Id: 21,
      Hour: 21,
      TotalUsageValue: 100,
    },
    {
      Id: 22,
      Hour: 22,
      TotalUsageValue: 100,
    },
    {
      Id: 23,
      Hour: 23,
      TotalUsageValue: 100,
    },
    {
      Id: 24,
      Hour: 24,
      TotalUsageValue: 100,
    },
  ]);

  const waterUsageColumns = [
    {
      field: "Hour",
      headerName: t("hour"),
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return `${params?.row?.Hour ?? ""}`;
      },
    },
    {
      field: "TotalUsageValue",
      headerName: t("hourly-hw-consumption-(lt)"),
      width: 300,
      editable: true,
      type: "number",
      valueGetter: (params) => {
        return `${params?.row?.TotalUsageValue ?? ""}`;
      },
    },
  ];

  const soilTemperatureColumns = [
    {
      field: "MonthText",
      headerName: t("month"),
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return `${params?.row?.MonthText ?? ""}`;
      },
    },
    {
      field: "Temperature",
      headerName: t("temperature-(c)"),
      width: 200,
      editable: true,
      type: "number",
      valueGetter: (params) => {
        return `${params?.row?.Temperature ?? ""}`;
      },
    },
  ];

  const handleEditRowsModelChange = React.useCallback((model) => {
    const editedIds = Object.keys(model);
    // user stops editing when the edit model is empty
    if (editedIds.length != 0) {
      const tempWater = [...waterUsageData];
      const updatedValue = tempWater.find((x) => x.Id == editedIds[0]);
      if (updatedValue) {
        updatedValue["TotalUsageValue"] =
          model[editedIds[0]].TotalUsageValue.value;
      }
      setWaterUsageData(tempWater);
    }
  });

  const handleEditRowsSoilTemperatureModelChange = React.useCallback(
    (model) => {
      const editedIds = Object.keys(model);
      // user stops editing when the edit model is empty
      if (editedIds.length != 0) {
        const tempSoil = [...soilTemperatureData];
        const updatedValue = tempSoil.find((x) => x.Id == editedIds[0]);
        if (updatedValue) {
          updatedValue["Temperature"] = model[editedIds[0]].Temperature.value;
        }
        setSoilTemperatureData(tempSoil);
      }
    }
  );

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      getRecord(id);
    }
    setModel({ ...model });
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      model.EpwFile = reader.result.split(",")[1];
      model.EpwFileName = file.name;
      setModel({ ...model });
      AlertHelper.show("success", "Success", "File Upload Successfully");
    };
    reader.onerror = (error) => {
      AlertHelper.show("warning", "Warning", "File Upload Successfully", error);
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/EnergyConsumptionProjects/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        ValueChanged("CityId", response.Item.CityId);
        setModel({ ...response.Item });

        setSoilTemperatureData(JSON.parse(response.Item?.SoilTemperatureValue));
        setWaterUsageData(JSON.parse(response.Item?.WaterConsumptionValue));
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const ValueChanged = (name, value, event, isSetState = false) => {
    model[name] = value;
    setModel({ ...model });
    if (name === "CityId") {
      renewInfo["City"] = value;
      renewInfo["GridType"] = "OnGrid";
      dispatch({
        type: GridConstants.GRID_CHANGE_VALUE,
        gridtype: { ...renewInfo },
      });
      dispatch({
        type: DesignConstants.DESING_CHANGE_VALUE,
        designtype: "Optimization",
      });
    }
  };

  const SeasonValueChanged = (name, value) => {
    seasonModel[name] = value;
    setSeasonModel({ ...seasonModel });
  };

  const handleSubmit = (e) => {
    let tempValidateModel = { ProjectName: false };
    let result = true;
    if (model.ProjectName == null || model.ProjectName == "") {
      tempValidateModel.ProjectName = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });

    model.WaterConsumptionValue = JSON.stringify(waterUsageData);
    model.SoilTemperatureValue = JSON.stringify(soilTemperatureData);
    if (model.HasEpwData == true) {
      model.ContinentId = 0;
      model.CountryId = 0;
      model.CityId = 0;
    } else {
      model.EpwFile = "";
      model.EpwFileName = "";
    }
    setModel({ ...model });

    if (result) {
      setIsLoading(true);
      if (model.Id != null && model.Id > 0) {
        Proxy.POST("/coreapi/v1.0/EnergyConsumptionProjects/Update", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show("success", t("success"), t("update-successfully"));
            props?.history?.push?.(
              `/admin/building-zone-search/${model.ProjectId}`
            );
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      } else {
        Proxy.POST("/coreapi/v1.0/EnergyConsumptionProjects/Insert", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show(
              "success",
              t("success"),
              t("insert-successfully!")
            );
            props?.history?.push?.(
              `/admin/building-zone-search/${model.ProjectId}`
            );
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      }
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };

  const data = [
    {
      index: 0,
      tabText: t("project-info"),
      context: (
        <GBESSProjectInfo
          model={model}
          modelValidate={modelValidate}
          onValueChanged={ValueChanged}
          onFileChanged={handleFileChange}
          handleFileUploadClick={handleButtonClick}
          fileInputRef={fileInputRef}
          t={t}
        />
      ),
    },
    {
      index: 1,
      tabText: t("season"),
      context: (
        <GBESSSeason
          model={model}
          modelValidate={modelValidate}
          onValueChanged={ValueChanged}
          t={t}
        />
      ),
    },
    {
      index: 2,
      tabText: t("soil-temperature"),
      context: (
        <GBESSSoilTemperature
          data={soilTemperatureData}
          columns={soilTemperatureColumns}
          handleEditRowsModelChange={handleEditRowsSoilTemperatureModelChange}
        />
      ),
    },
    {
      index: 3,
      tabText: t("water-consumption"),
      context: (
        <GBESSWaterConsumption
          data={waterUsageData}
          columns={waterUsageColumns}
          handleEditRowsModelChange={handleEditRowsModelChange}
        />
      ),
    },
  ];

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create-general-information")}
          subtitle={t("create-general-information-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer xs={12}>
            <GBESSGridItem xs={12} sm={12} md={6}>
              <GBESSTabBar
                value={tabValue}
                valueChanged={(newValue) => {
                  setTabValue(newValue);
                }}
                dataSource={data}
                onFinish={() => {
                  handleSubmit();
                }}
              ></GBESSTabBar>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default GeneralInformation;

function GBESSSoilTemperature({ data, columns, handleEditRowsModelChange }) {
  return (
    <GBESSDataGrid
      name="soilTemperatureDataGrid"
      getRowId={(row) => row.Id}
      rows={data}
      columns={columns}
      checkboxSelection={false}
      disableSelectionOnClick={true}
      onEditRowsModelChange={handleEditRowsModelChange}
    />
  );
}

function GBESSWaterConsumption({ data, columns, handleEditRowsModelChange }) {
  return (
    <GBESSDataGrid
      name="waterConsumptionDataGrid"
      getRowId={(row) => row.Id}
      rows={data}
      columns={columns}
      checkboxSelection={false}
      disableSelectionOnClick={true}
      onEditRowsModelChange={handleEditRowsModelChange}
    />
  );
}

function GBESSProjectInfo({
  model,
  modelValidate,
  onValueChanged,
  t,
  onFileChanged,
  handleFileUploadClick,
  fileInputRef,
}) {
  return (
    <GBESSCardBody>
      <GBESSGridContainer xs={12} sm={12} md={12}>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel style={{ marginTop: "15px" }} bold text={t("general")} />
        </GBESSGridItem>

        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="ProjectName"
            error={modelValidate.ProjectName}
            value={model.ProjectName}
            valueChanged={onValueChanged}
            labelText={t("project-name")}
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSSelectInput
            url={"/coreapi/v1.0/Parameter/Search"}
            parameter={{ ParameterCode: "BuildingType" }}
            name="BuildingTypeId"
            renderer={(item) => {
              return `${item.ParameterDesc}`;
            }}
            keySelector={"Id"}
            value={model?.BuildingTypeId || 0}
            valueChanged={onValueChanged}
            labelText={t("GEN_INFO_COMPONENT_BUILDING_TYPE")}
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="BuildingDesc"
            value={model.BuildingDesc}
            valueChanged={onValueChanged}
            rows={3}
            labelText={t("building-description")}
          />
        </GBESSGridItem>
        <GBESSDivider />

        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel
            bold
            style={{ marginTop: "15px" }}
            text={t("number-of-floors")}
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="BelowGround"
            value={model.BelowGround}
            valueChanged={onValueChanged}
            labelText={t("below-ground")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="AboveGround"
            value={model.AboveGround}
            valueChanged={onValueChanged}
            labelText={t("above-ground")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSDivider />
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel
            bold
            style={{ marginTop: "15px" }}
            text={t("climate_data")}
          />
        </GBESSGridItem>

        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSCheckBox
            name="HasEpwData"
            labelText={t("epw_data")}
            value={model.HasEpwData}
            valueChanged={onValueChanged}
          />
        </GBESSGridItem>
        {model.HasEpwData == false ? (
          <GBESSGridContainer xs={12} sm={12} md={12}>
            <GBESSGridItem
              style={{ marginLeft: "15px" }}
              xs={12}
              sm={12}
              md={12}
            >
              <GBESSGridContainer xs={12} sm={12} md={12}>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    bold
                    style={{ marginTop: "15px" }}
                    text={t("location")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Continent/Search"}
                    name="ContinentId"
                    renderer={(item) => {
                      return `${item.ContinentName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ContinentId}
                    valueChanged={onValueChanged}
                    labelText={t("continent")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Country/Search"}
                    name="CountryId"
                    renderer={(item) => {
                      return `${item.CountryName}`;
                    }}
                    keySelector={"Id"}
                    value={model.CountryId}
                    valueChanged={onValueChanged}
                    parameter={{ ContinentId: model.ContinentId }}
                    labelText={t("country")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/City/Search"}
                    name="CityId"
                    renderer={(item) => {
                      return `${item.CityName}`;
                    }}
                    keySelector={"Id"}
                    value={model.CityId}
                    valueChanged={onValueChanged}
                    parameter={{ CountryId: model.CountryId }}
                    labelText={t("city")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        ) : (
          <GBESSGridContainer xs={12} sm={12} md={12}>
            <GBESSGridItem
              style={{ marginLeft: "15px" }}
              xs={12}
              sm={12}
              md={12}
            >
              <GBESSGridContainer xs={12} sm={12} md={12}>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    bold
                    style={{ marginTop: "15px" }}
                    text={t("climate_data_upload")}
                  />
                </GBESSGridItem>
                <GBESSGridItem
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: "15px",
                  }}
                  xs={12}
                  sm={12}
                  md={12}
                >
                  <GBESSButton onClick={handleFileUploadClick}>
                    <input
                      type="file"
                      accept=".epw"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={onFileChanged}
                    />
                    {t("upload_epw_file")}
                  </GBESSButton>
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "15px" }}
                    text={model.EpwFileName}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        )}
        <GBESSDivider />
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel
            bold
            style={{ marginTop: "15px" }}
            text={t("parameters")}
          />
        </GBESSGridItem>

        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="Ventilation"
            value={model.Ventilation}
            valueChanged={onValueChanged}
            labelText={t("ventilation-(ach)")}
            type="number"
          />
        </GBESSGridItem>

        <GBESSDivider />
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel
            bold
            style={{ marginTop: "15px" }}
            text={t("heating_system")}
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="HeatingSysCop"
            value={model.HeatingSysCop}
            valueChanged={onValueChanged}
            labelText={t("heating-system-cop")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="CoalHeatingValue"
            value={model.CoalHeatingValue}
            valueChanged={onValueChanged}
            labelText={t("coal_heating_value")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="WoodHeatingValue"
            value={model.WoodHeatingValue}
            valueChanged={onValueChanged}
            labelText={t("wood_heating_value")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="NaturalGasHeatingValue"
            value={model.NaturalGasHeatingValue}
            valueChanged={onValueChanged}
            labelText={t("natural_gas_heating_value")}
            type="number"
          />
        </GBESSGridItem>
        <GBESSDivider />
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSLabel
            bold
            style={{ marginTop: "15px" }}
            text={t("cooling_system")}
          />
        </GBESSGridItem>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSTextInput
            name="CoolingEer"
            value={model.CoolingEer}
            valueChanged={onValueChanged}
            labelText={t("cooling-system-eer")}
            type="number"
          />
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSCardBody>
  );
}

function GBESSSeason({ model, modelValidate, onValueChanged, t }) {
  return (
    <GBESSCardBody>
      <GBESSGridContainer>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSGridContainer>
            <GBESSLabel
              style={{ marginTop: "15px" }}
              bold
              text={t("heating-season-date-interval")}
            />
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDivider />
            </GBESSGridItem>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDateTimePicker
                name="HeatingSeasonFromDate"
                labelText={t("season-from")}
                onChange={(value) => {
                  onValueChanged &&
                    onValueChanged("HeatingSeasonFromDate", value, null, true);
                }}
                value={model.HeatingSeasonFromDate}
              />
            </GBESSGridItem>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDateTimePicker
                name="HeatingSeasonToDate"
                labelText={t("season-to")}
                onChange={(value) => {
                  onValueChanged &&
                    onValueChanged("HeatingSeasonToDate", value, null, true);
                }}
                value={model.HeatingSeasonToDate}
              />
            </GBESSGridItem>

            <GBESSLabel
              bold
              style={{ marginTop: "15px" }}
              text={t("cooling-season-date-interval")}
            />
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDivider />
            </GBESSGridItem>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDateTimePicker
                name="CoolingSeasonFromDate"
                labelText={t("season-from")}
                onChange={(value) => {
                  onValueChanged &&
                    onValueChanged("CoolingSeasonFromDate", value, null, true);
                }}
                value={model.CoolingSeasonFromDate}
              />
            </GBESSGridItem>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDateTimePicker
                name="CoolingSeasonToDate"
                labelText={t("season-to")}
                onChange={(value) => {
                  onValueChanged &&
                    onValueChanged("CoolingSeasonToDate", value, null, true);
                }}
                value={model.CoolingSeasonToDate}
              />
            </GBESSGridItem>
            <GBESSLabel
              bold
              style={{ marginTop: "15px" }}
              text={t("the-dates-you-choose")}
            />
            <GBESSGridItem xs={12}></GBESSGridItem>
          </GBESSGridContainer>
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSCardBody>
  );
}
