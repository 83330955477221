import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESSDialog from "../../../components/GBESS/GBESSDialog";
import GBESSIconButton from "../../../components/GBESS/GBESSIconButton";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const ElectricalEquipmentSearch = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteQuestionOpen, setDeleteQuestionOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
      handleSearch();
    }
    setModel({ ...model });
  }, []);

  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 225,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <GBESSIconButton
              aria-label="delete"
              onClick={handleDeleteQuestion(params)}
              code="electrical-equipment-search-delete"
            >
              <DeleteIcon />
            </GBESSIconButton>
            <GBESSIconButton
              aria-label="edit"
              onClick={handleUpdate(params)}
              code="electrical-equipment-search-edit"
            >
              <EditIcon />
            </GBESSIconButton>
          </>
        );
      },
    },
    {
      field: "Project",
      headerName: t("project-name"),
      width: 225,
      editable: true,
      valueGetter: (params) => {
        return `${params?.row?.Project?.ProjectName ?? ""}`;
      },
    },
    {
      field: "ElectricalEquipment",
      headerName: "Electrical Equipment Name",
      width: 225,
      editable: true,
      valueGetter: (params) => {
        return `${
          params?.row?.ElectricalEquipment?.ElectricalEquipmentName ?? ""
        }`;
      },
    },
    {
      field: "DailyUseMinutes",
      headerName: t("daily-use-minutes"),
      width: 225,
      editable: true,
    },
    {
      field: "ElectricalConsumptionFileName",
      headerName: t("file-name"),
      width: 225,
      editable: true,
    },
  ];

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleDeleteQuestion = (record) => (e) => {
    setDeleteId(record.id);
    setDeleteQuestionOpen(true);
  };
  const handleDelete = (e) => {
    setIsLoading(true);
    Proxy.GET(
      `/coreapi/v1.0/EnergyConsumptionElectricalEquipments/Delete/Id?Id=${deleteId}`
    )
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("delete-successfully"));
        handleSearch();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
        setDeleteId(0);
        setDeleteQuestionOpen(false);
      });
  };

  const handleAdd = (e) => {
    props?.history?.push?.(
      `/admin/electrical-equipment-create/${model.ProjectId}`
    );
  };
  const handleUpdate = (record) => (e) => {
    props?.history?.push?.(`/admin/electrical-equipment-update/${record.id}`);
  };

  const handleNextPage = (e) => {
    if (data.length <= 0) {
      AlertHelper.show(
        "warning",
        "Warning",
        "Electrical appliance must be added!"
      );
    } else {
      props?.history?.push?.(`/admin/usage-profile/${model.ProjectId}`);
    }
  };

  const handlePreviousPage = (e) => {
    props?.history?.push?.(
      `/admin/building-construction-search/${model.ProjectId}`
    );
  };

  const handleSearch = (e) => {
    setIsLoading(true);
    Proxy.POST(
      "/coreapi/v1.0/EnergyConsumptionElectricalEquipments/Search",
      model
    )
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleCloseDeleteQuestion = () => {
    setDeleteQuestionOpen(false);
  };
  return (
    <>
      <GBESSDialog
        open={deleteQuestionOpen}
        title={t("delete-record")}
        content={t("are-you-sure-you")}
        handleAccept={handleDelete}
        handleReject={handleCloseDeleteQuestion}
      />
      <GBESSCard>
        <GBESSCardHeader title={t("search-project-electrical-equipment")} />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/ElectricalEquipments/Search"}
                    name="ElectricalEquipmentId"
                    renderer={(item) => {
                      return `${item.ElectricalEquipmentName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ElectricalEquipmentId}
                    valueChanged={ValueChanged}
                    labelText={t("electrical-equipment")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
       
      <GBESSDataGrid
        getRowId={(row) => row.Id}
        loading={isLoading}
        gridActionList={[
          {
            name: t("search"),
            onClick: handleSearch,
            code: "electrical-equipment-search-search",
          },
          {
            name: t("add"),
            onClick: handleAdd,
            code: "electrical-equipment-search-add",
          },
        ]}
        rows={data}
        columns={columns}
        checkboxSelection={false}
        disableSelectionOnClick={true}
      />
      <GBESSGridContainer>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSGridContainer>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-start" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handlePreviousPage}
                code={"electrical-equipment-search-previous"}
              >
                {t("previous")}
              </GBESSButton>
            </GBESSGridItem>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-end" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handleNextPage}
                code={"electrical-equipment-search-next"}
              >
                {t("next")}
              </GBESSButton>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSGridItem>
      </GBESSGridContainer>
    </>
  );
};
export default ElectricalEquipmentSearch;
