import { InputLabel, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import GBESSGridItem from "./GBESSGridItem";

const styles = {
  label: {
    color: "#AAAAAA",
  },
  bold: {
    fontWeight: "bold",
  },
};

const useStyles = makeStyles(styles);

function GBESSLabel({ disabled, noGrid, bold, text, ...rest }) {
  const classes = useStyles();
  const className = classNames({
    [classes.label]: disabled,
    [classes.bold]: bold,
  });
  // eslint-disable-next-line react/prop-types
  var Container = ({ children }) => <>{children}</>;
  if (!noGrid) {
    Container = GBESSGridItem;
  }
  return (
    <Container xs={12}>
      <InputLabel className={className} {...rest}>
        {text}
      </InputLabel>
    </Container>
  );
}

GBESSLabel.propTypes = {
  bold: PropTypes.any,
  disabled: PropTypes.any,
  noGrid: PropTypes.any,
  text: PropTypes.any,
};
export default GBESSLabel;
