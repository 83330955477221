import React, { useEffect, useRef } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSLabel from "../../../components/GBESS/GBESSLabel";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESSCheckBox from "../../../components/GBESS/GBESSCheckBox";
import Proxy from "core/Proxy";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const ElectricalEquipmentUpdate = (props) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const { t } = useTranslation();

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.Id = id;
      getRecord(id);
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(
      `/coreapi/v1.0/EnergyConsumptionElectricalEquipments/GetById/Id?Id=${id}`
    )
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = {
      ProjectId: false,
      ElectricalEquipmentId: false,
      DailyUseMinutes: false,
      Id: false,
    };
    let result = true;
    if (model.Id == null || model.Id == "" || model.Id <= 0) {
      tempValidateModel.Id = true;
      result = false;
    }
    if (model.ProjectId == null || model.ProjectId <= 0) {
      tempValidateModel.ProjectId = true;
      result = false;
    }
    if (model.HasElectricalConsumptionData == false) {
      if (
        model.ElectricalEquipmentId == null ||
        model.ElectricalEquipmentId <= 0
      ) {
        tempValidateModel.ElectricalEquipmentId = true;
        result = false;
      }
      if (model.DailyUseMinutes == null || model.DailyUseMinutes <= 0) {
        tempValidateModel.DailyUseMinutes = true;
        result = false;
      }
    } else {
      if (
        model.ElectricalConsumptionFileName == null ||
        model.ElectricalConsumptionFileName == ""
      ) {
        result = false;
      }
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST(
        "/coreapi/v1.0/EnergyConsumptionElectricalEquipments/Update",
        model
      )
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.(
      `/admin/electrical-equipment-search/${model.ProjectId}`
    );
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      model.ElectricalConsumptionFile = reader.result.split(",")[1];
      model.ElectricalConsumptionFileName = file.name;
      setModel({ ...model });
      AlertHelper.show("success", "Success", "File Upload Successfully");
    };
    reader.onerror = (error) => {
      AlertHelper.show("warning", "Warning", "File Upload Successfully", error);
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("update-project-electrical-equipment")}
          subtitle={"Update Project Electrical Equipment Page"}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSCheckBox
                    name="HasElectricalConsumptionData"
                    labelText={t("is_there_electrical_consumption")}
                    value={model.HasElectricalConsumptionData}
                    valueChanged={ValueChanged}
                  />
                </GBESSGridItem>
                {model.HasElectricalConsumptionData == false ? (
                  <GBESSGridContainer xs={12} sm={12} md={12}>
                    <GBESSGridItem
                      style={{ marginLeft: "15px" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <GBESSGridContainer xs={12} sm={12} md={12}>
                        <GBESSGridItem xs={12} sm={6} md={6}>
                          <GBESSSelectInput
                            url={"/coreapi/v1.0/ElectricalEquipments/Search"}
                            name="ElectricalEquipmentId"
                            renderer={(item) => {
                              return `${item.ElectricalEquipmentName}`;
                            }}
                            keySelector={"Id"}
                            value={model.ElectricalEquipmentId}
                            valueChanged={ValueChanged}
                            labelText={t("electrical-equipment")}
                          />
                        </GBESSGridItem>
                        <GBESSGridItem xs={12} sm={6} md={6}>
                          <GBESSTextInput
                            name="DailyUseMinutes"
                            error={modelValidate.DailyUseMinutes}
                            value={model.DailyUseMinutes}
                            valueChanged={ValueChanged}
                            labelText={t("daily-use-minutes")}
                            type="number"
                          />
                        </GBESSGridItem>
                      </GBESSGridContainer>
                    </GBESSGridItem>
                  </GBESSGridContainer>
                ) : (
                  <GBESSGridContainer xs={12} sm={12} md={12}>
                    <GBESSGridItem
                      style={{ marginLeft: "15px" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <GBESSGridContainer xs={12} sm={12} md={12}>
                        <GBESSGridItem
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginLeft: "15px",
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <GBESSButton onClick={handleButtonClick}>
                            <input
                              type="file"
                              accept=".txt"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                            Upload Electrical Consumption File (.txt)
                          </GBESSButton>
                        </GBESSGridItem>
                        <GBESSGridItem xs={12} sm={12} md={12}>
                          <GBESSLabel
                            style={{ marginTop: "15px" }}
                            text={model.ElectricalConsumptionFileName}
                          />
                        </GBESSGridItem>
                        <GBESSGridItem xs={12} sm={12} md={12}>
                          <GBESSLabel
                            style={{ marginTop: "15px" }}
                            text="Upload 8761 hour consumption data with ',' character between them."
                          />
                        </GBESSGridItem>
                      </GBESSGridContainer>
                    </GBESSGridItem>
                  </GBESSGridContainer>
                )}
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="electrical-equipment-edit-update"
          >
            Update
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ElectricalEquipmentUpdate;
