import React, { useEffect } from "react";
import {
  GBESSGridContainer,
  GBESSGridItem,
  GBESSCard,
  GBESSCardHeader,
  GBESSCardBody,
  GBESSCardFooter,
  GBESSButton,
} from "../../../components/GBESS";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import { IconButton } from "@material-ui/core";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { makeStyles } from "@material-ui/core/styles";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const styles = {};
const useStyles = makeStyles(styles);
const CommunityProjects = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [model, setModel] = React.useState({});
  const [projects, setProjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    getProjects(user.id);
  }, []);

  const getProjects = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/CommunityProjects/GetProjectsByUserId?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        setProjects(response.Item);
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleNewProject = () => {
    props?.history?.push?.("/admin/community/new");
  };

  const handleOpenProject = (params) => (e) => {
    if (params?.row?.Id > 0) {
      props?.history?.push?.(`/admin/community/${params?.row?.Id}`);
    }
  };

  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 70,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="open"
              onClick={handleOpenProject(params)}
              code="community-project-open"
            >
              <FileOpenIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "CommunityName",
      headerName: t("Community_Project_Name"),
      width: 350,
      editable: false,
    },
    {
      field: "IsClickedSaveProjectAction",
      headerName: t("Projects_Warnings"),
      width: 500,
      editable: false,
      valueGetter: (params) => {
        return `${
          params?.row?.IsClickedSaveProjectAction
            ? ""
            : t("Community_Project_Delete_Warning")
        }`;
      },
    },
  ];

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("Community_Projects_Title")}
          subtitle={t("Community_Projects_Sub_Title")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDataGrid
                getRowId={(row) => row.Id}
                rows={projects || []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
              />
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleNewProject}
            code="community-new-project"
          >
            {t("Community_New_Project")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default CommunityProjects;
