import StringHelper from "core/helpers/StringHelper";
import { PoolManager } from "loadio";

import DateHelper from "./helpers/DateHelper";
import ResourceHelper from "./helpers/ResourceHelper";

const ProxyFluentEvent = {
  ErrorPassthrough: "ErrorPassthrough",
};

const GetBaseUrl = () => (<any>window).ProxyUrl;

const getProxyUrl = (url: string) => {
  var absoluteUrl: string = url;
  if (url.startsWith("http")) {
    absoluteUrl = url;
  } else {
    absoluteUrl = GetBaseUrl() + (url.startsWith("/") ? url : "/" + url);
  }
  return absoluteUrl;
};

const ToErrorString = (obj: any) => {
  if (obj == null) {
    return "";
  }

  if (typeof obj == "string")
    return (<any>window).IsProxyErrorDetailActive == 1
      ? ResourceHelper.get(String(obj))
      : "An API Error Occurred.";

  if (typeof obj == "object") {
    if (obj.ErrorCode != null)
      return (<any>window).IsProxyErrorDetailActive == 1
        ? ResourceHelper.get(String(obj.ErrorCode))
        : "An API Error Occurred.";
    if (obj instanceof TypeError) return "Unknown Type Error Occurred";
    else return "Unknown API Error Occurred";
  }
  return String(obj);
};

export interface ServiceResponse<T> {
  Item?: T;
  IsSucceeded: boolean;
  /**
   * Default Value is `"0"`
   */
  ErrorCode: string;
  ErrorDescription?: string;
  StackTrace?: string;
  ParameterList: string[];
}

export default class Proxy<T> {
  proxyPromise: Promise<T>;
  private constructor(proxyPromise: Promise<T>) {
    this.proxyPromise = proxyPromise;
  }
  /**
   * Gets running promise object
   * @returns
   */
  get = () => {
    return this.proxyPromise;
  };
  /**
   * Represents to call on CDMProxy Request success regardless of IsSucceeded property except on not using withErrorAlert before this
   * @param callback Executes the function when the proxy is succeeded
   */
  success = (callback: (response: T) => void) => {
    var forwardedSuccessCallback = (data: any) => {
      if (data !== ProxyFluentEvent.ErrorPassthrough) {
        return callback && callback(data);
      }
    };
    (this.proxyPromise as unknown) = this.proxyPromise.then(
      forwardedSuccessCallback
    );
    return this;
  };
  /**
   * Attaches callbacks for the resolution and/or rejection of the Promise.
   * @param onfulfilled The callback to execute when the Promise is resolved.
   * @param onrejected The callback to execute when the Promise is rejected.
   * @returns A Promise for the completion of which ever callback is executed.
   */
  then = <TResult1 = T, TResult2 = never>(
    onfulfilled?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null,
    onrejected?:
      | ((reason: any) => TResult2 | PromiseLike<TResult2>)
      | undefined
      | null
  ) => {
    this.proxyPromise.then(onfulfilled, onrejected);
    return this;
  };
  /**
   * Represents to generate promise chain regardless of promise statuses
   * @param callback Callback function
   */
  continueWith = <TResult1 = T>(
    callback?:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null
  ) => {
    var ret = (data: any) => {
      var obj = data;
      try {
        obj = JSON.parse(data);
      } catch (e) {}
      return callback && callback(obj);
    };
    this.proxyPromise.then(ret, ret);
    return this;
  };

  /**
   * Represents to call on CDMProxy Request fail
   * @param callback First callback parameter equals to response Object. Second callback parameter errorDescription if exist
   */
  error = (callback: Function) => {
    var promiseNew = this.proxyPromise.then(null, (responseStr) => {
      if (!responseStr.toString().startsWith("{")) {
        callback(responseStr, responseStr);
        return;
      }
      var responseData = JSON.parse(responseStr);
      if (responseData?.ErrorCode?.length > 0) {
        let errorDescByDb = ResourceHelper.get(responseData.ErrorCode);
        if (
          process.env.NODE_ENV !== "production" &&
          errorDescByDb === responseData?.ErrorCode
        ) {
          console.warn(
            `Resource code not found => ${responseData.ErrorCode}. Used response's description instead of the database.`
          );
          errorDescByDb = responseData?.ErrorDescription;
        }
        callback(responseData, errorDescByDb ?? responseData?.ErrorCode);
      }
      callback(responseData);
    });

    (this.proxyPromise as unknown) = promiseNew;
    return this;
  };
  /**
   * Represents to show alert error message on CDMProxy Error.
   *
   * So doesn't need to be handle error with .error() or check IsSucceeded property in success() function.
   * But still could be able to catch errors with error() about Proxy Internal errors or IsSucceeded:false responses
   * @param disablePopupAlert Shows only console log error instead of popup message
   * @param disableErrorPassthrough Disables the rejected promise passthrough.
   * Normally, the error promise continues to be forwarding after the alert error is showed even if the error is caught.
   * Useful to disable unhandled rejection warnings if doesn't need to handle error again after an error alert
   *
   */
  withErrorAlert = (
    disablePopupAlert: boolean = false,
    disableErrorPassthrough: boolean = false
  ) => {
    var alertCallback = async (responseStr: string) => {
      var responseData = <ServiceResponse<any>>(
        (StringHelper.tryJsonParse(responseStr) as unknown)
      );
      var desc = null;
      if (responseData.ErrorDescription?.length) {
        desc = await (<any>ResourceHelper.get(responseData.ErrorCode));

        if (responseData.ParameterList?.length > 0) {
          desc = <any>StringHelper.format(desc, responseData.ParameterList);
        }

        if (!desc?.length) {
          desc = responseData.ErrorDescription;
        } else if (desc === responseData.ErrorCode) {
          console.log("");
          console.log(
            `========= '${responseData.ErrorCode}' Resource key is missing =========`
          );
          console.log(
            "Description showing by response data instead of Core.Resource definition."
          );
          console.log("");
          desc = responseData.ErrorDescription;
        }
      } else {
        desc = responseStr;
      }

      if (responseStr === desc) {
        console.log(`withErrorAlert error => ${responseStr}`);
      } else {
        console.log(`withErrorAlert error => ${responseStr} Desc: ${desc}`);
      }

      if (!disablePopupAlert) {
        // AlertReduxHelper.ShowMessage("error", "Error ", desc);
      }
      if (disableErrorPassthrough == false) {
        return Promise.reject(responseData);
      } else {
        return Promise.resolve(ProxyFluentEvent.ErrorPassthrough);
      }
    };

    var handleNotSucceeded = (responseStr: any) => {
      var responseData = <any>StringHelper.tryJsonParse(responseStr);
      if (responseData?.IsSucceeded === false) {
        return Promise.reject(responseData);
      } else {
        return Promise.resolve(responseData);
      }
    };

    var promiseChain = this.proxyPromise
      .then(handleNotSucceeded, null)
      .then(null, alertCallback);
    (this.proxyPromise as unknown) = promiseChain;
    return this;
  };
  /**
   * Must be to use the end of the Promise chain if needed because promises don't return data after this
   *
   * Enables to alert depending by proxy.
   * Automatically opens loading screen and keeps open until promise finish.
   * If multiple promises using this function, loading screen kept until finish all proxies
   */
  withLoading = () => {
    PoolManager.append(this.proxyPromise);
    return this;
  };
  /**
   * POST Async
   * @param {string} URL
   * @param {object} Params
   * @param {*?} promiseContainer
   * @param {string?} proxyKey
   * @returns {ProxyBase}
   */

  private static REQUEST = <T = any>(
    method: "GET" | "POST",
    url: string,
    params?: object
  ) => {
    var user: any = localStorage.getItem("user");
    const userCookie = JSON.parse(user);
    var fetchBody: RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Path: (<any>window).location.pathname,
        TimeZone: DateHelper.GetSelectedZoneId(),
        Authentication: userCookie?.token ? `Bearer ${userCookie?.token}` : "",
      },
    };
    if (method === "POST") {
      fetchBody.body = JSON.stringify(params);
    }
    let proxyPromise = fetch(getProxyUrl(url), fetchBody)
      .then((response) => {
        if (!response.ok) {
          if (response.status == 401 || response.status == 403) {
            localStorage.removeItem("user");
            window.location.reload();
            console.log("Session expired. Redirecting to login.");
          } else {
            throw Error(
              `Error: ${response.statusText}  StatusCode: ${response.status} Type: ${response.type}   Url: ${response.url}`
            );
          }
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.IsSucceeded !== true) {
          if (
            (<any>window).IsProxyErrorDetailActive == 1 &&
            responseData?.StackTrace
          ) {
            console.error(responseData.StackTrace);
          } else {
            console.error("An API Error Occurred. Check logs.");
          }

          if (responseData?.ErrorCode) {
            if (responseData.ParameterList?.length > 0)
              responseData.ErrorDescription = StringHelper.format(
                ResourceHelper.get(responseData.ErrorCode),
                responseData.ParameterList
              );
            else {
              var tempDesc = ResourceHelper.get(responseData.ErrorCode);
              responseData.ErrorDescription =
                tempDesc == responseData.ErrorCode
                  ? responseData.ErrorDescription
                  : tempDesc;
            }
          }
        }
        return Promise.resolve(responseData);
      })
      .catch((error) => {
        if ((<any>window).IsProxyErrorDetailActive == 1) {
          console.error(error?.ErrorDescription || error);
        }
        if (error.message === "Failed to fetch") {
          return Promise.reject("Unable to connect to server!");
        } else {
          return Promise.reject(ToErrorString(error));
        }
      });

    var newRequest = new Proxy<T>(proxyPromise);
    return newRequest;
  };
  static POST = <T = any>(url: string, params?: object) => {
    return Proxy.REQUEST<ServiceResponse<T>>("POST", url, params);
  };
  /**
   * GET Async
   * @param {string} URL
   * @param {*?} promiseContainer
   * @param {string?} proxyKey
   * @returns {ProxyBase}
   */
  static GET = <T = any>(url: string) => {
    return Proxy.REQUEST<ServiceResponse<T>>("GET", url);
  };
}
