import React from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const ActivityCreate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { t } = useTranslation();

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = { Description: false, DIK: false, GIK: false };
    let result = true;
    if (model.Description == null || model.Description == "") {
      tempValidateModel.Description = true;
      result = false;
    }
    if (model.DIK == null || model.DIK == "") {
      tempValidateModel.DIK = true;
      result = false;
    }
    if (model.GIK == null || model.GIK == "") {
      tempValidateModel.GIK = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/Activities/Insert", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("insert-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", t("warning"), error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", t("warning"), t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.("/admin/activity-search");
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create_activities")}
          subtitle={t("create_activity_page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Description"
                    error={modelValidate.Description}
                    value={model.Description}
                    valueChanged={ValueChanged}
                    labelText={t("activity_description")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="DIK"
                    error={modelValidate.DIK}
                    value={model.DIK}
                    valueChanged={ValueChanged}
                    labelText="DIK (W)"
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="GIK"
                    error={modelValidate.GIK}
                    value={model.GIK}
                    valueChanged={ValueChanged}
                    labelText="GIK (W)"
                    type="number"
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="activity-create-submit"
          >
            {t("submit")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ActivityCreate;
