import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESSDialog from "../../../components/GBESS/GBESSDialog";
import GBESSIconButton from "../../../components/GBESS/GBESSIconButton";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const styles = {};
const useStyles = makeStyles(styles);
const CommunityBuildingSearch = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteQuestionOpen, setDeleteQuestionOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 225,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <GBESSIconButton
              aria-label="delete"
              onClick={handleDeleteQuestion(params)}
              code="community-buildings-search-delete"
            >
              <DeleteIcon />
            </GBESSIconButton>
            <GBESSIconButton
              aria-label="edit"
              onClick={handleUpdate(params)}
              code="community-buildings-search-edit"
            >
              <EditIcon />
            </GBESSIconButton>
          </>
        );
      },
    },
    {
      field: "ProjectTypeParameter",
      headerName: t("project_type"),
      width: 225,
      editable: true,
      renderCell: (params) => {
        return `${params?.row?.ProjectTypeParameter?.ParameterDesc ?? ""}`;
      },
    },
    {
      field: "BuildingProject",
      headerName: t("building_project"),
      width: 225,
      editable: true,
      renderCell: (params) => {
        return `${params?.row?.BuildingProject?.ProjectName ?? ""}`;
      },
    },
    {
      field: "RenewableSystemProject",
      headerName: t("Renewable_System"),
      width: 225,
      editable: true,
      renderCell: (params) => {
        return `${params?.row?.RenewableSystemProject?.ProjectName ?? ""}`;
      },
    },
    {
      field: "X",
      headerName: "X",
      width: 225,
      editable: true,
    },
    {
      field: "Y",
      headerName: "Y",
      width: 225,
      editable: true,
    },
  ];
  const { id } = useParams();

  useEffect(() => {
    if (id > 0) {
      model.CommunityId = id;
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleDeleteQuestion = (record) => (e) => {
    setDeleteId(record.id);
    setDeleteQuestionOpen(true);
  };
  const handleDelete = (e) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/CommunityBuilding/Delete/Id?Id=${deleteId}`)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("delete-successfully"));
        handleSearch();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
        setDeleteId(0);
        setDeleteQuestionOpen(false);
      });
  };

  const handleAdd = (e) => {
    props?.history?.push?.(
      `/admin/community-buildings-create/${model.CommunityId}`
    );
  };
  const handleUpdate = (record) => (e) => {
    props?.history?.push?.(`/admin/community-buildings-update/${record.id}`);
  };
  const handleSearch = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/CommunityBuilding/Search", model)
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleCloseDeleteQuestion = () => {
    setDeleteQuestionOpen(false);
  };

  const handlePreviousPage = (e) => {
    props?.history?.push?.(`/admin/community/${model.CommunityId}`);
  };

  const handleNextPage = (e) => {
    props?.history?.push?.(`/admin/community-results/${model.CommunityId}`);
  };

  const handleGoToPreview = () => {
    props?.history?.push?.(
      `/admin/community-buildings-preview/${model.CommunityId}`
    );
  };
  return (
    <>
      <GBESSDialog
        open={deleteQuestionOpen}
        title={t("delete-record")}
        content={t("are-you-sure-you")}
        handleAccept={handleDelete}
        handleReject={handleCloseDeleteQuestion}
      />
      <GBESSCard>
        <GBESSCardHeader
          title={t("community_building")}
          subtitle={t(
            "on_this_screen_the_buildings_and_renewable_systems_in_the_community_are_listed"
          )}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("project_type")}
                    name="ProjectTypeId"
                    value={model.ExposedDirectionId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ProjectType"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
       
      <GBESSDataGrid
        getRowId={(row) => row.Id}
        loading={isLoading}
        gridActionList={[
          {
            name: t("search"),
            onClick: handleSearch,
            code: "community-buildings-search-search",
          },
          {
            name: t("add"),
            onClick: handleAdd,
            code: "community-buildings-search-add",
          },
          {
            name: t("preview"),
            onClick: handleGoToPreview,
            code: "zone-construction-search-add",
          },
        ]}
        rows={data}
        columns={columns}
        checkboxSelection={false}
        disableSelectionOnClick={true}
      />
      <GBESSGridContainer>
        <GBESSGridItem xs={12} sm={12} md={12}>
          <GBESSGridContainer>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-start" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handlePreviousPage}
                code={"community-buildings-search-previous"}
              >
                {t("previous")}
              </GBESSButton>
            </GBESSGridItem>
            <GBESSGridItem
              style={{ display: "flex", justifyContent: "flex-end" }}
              xs={12}
              sm={6}
              md={6}
            >
              <GBESSButton
                disabled={isLoading}
                onClick={handleNextPage}
                code={"community-buildings-search-next"}
              >
                {t("next")}
              </GBESSButton>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSGridItem>
      </GBESSGridContainer>
    </>
  );
};
export default CommunityBuildingSearch;
