import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import styled from "@emotion/styled";
import compassImage from "./compass.png";
import { Box } from "@mui/material";

import Ground from "assets/img/babylon/ground.jpg";

const StyledCanvas = styled.canvas`
  width: 100%;
  height: 800px;
`;

let compassDom;
function compassHTML(canvasDom, camera, scene) {
  if (compassDom) {
    compassDom.remove();
  }

  var divOverlay = document.createElement("div");
  divOverlay.style.backgroundImage = `url('${compassImage}')`;
  divOverlay.style.backgroundSize = "200% 100%";
  divOverlay.style.width = "200px";
  divOverlay.style.height = "30px";
  divOverlay.style.top = "5px";
  divOverlay.style.left = "50%";
  divOverlay.style.position = "absolute";
  divOverlay.style.transform = "translateX(-50%)";
  divOverlay.style.zIndex = "3";
  divOverlay.id = "compassWorld";
  divOverlay.className = "compassWorld";

  var divNeedle = document.createElement("div");
  divNeedle.style.backgroundColor = "red";
  divNeedle.style.opacity = "1";
  divNeedle.style.width = "3px";
  divNeedle.style.height = "9px";
  divNeedle.style.top = "30px";
  divNeedle.style.left = "100px";
  divNeedle.style.position = "absolute";
  divNeedle.style.zIndex = "3";

  canvasDom.appendChild(divOverlay);
  divOverlay.appendChild(divNeedle);
  compassDom = divOverlay;
  scene.registerBeforeRender(() => {
    if (divOverlay) {
      //For ArcRotateCamera only
      // divOverlay.style.backgroundPosition = `${
      //   50 + (camera.alpha * 200) / Math.PI
      // }px`;

      //For FreeCamera only
      // Kameranın ileri vektörünü alın
      var forward = camera.getForwardRay().direction;

      // Y yönü (Z eksenine göre kuzey açısı)
      var angle = Math.atan2(forward.x, forward.z);

      // Açıyı yüzde pozisyonuna dönüştürün (200% genişlik üzerinden)
      var backgroundPosition = 25 + (angle * 200) / (2 * Math.PI);

      // Arka plan pozisyonunu güncelleyin
      divOverlay.style.backgroundPosition = `${backgroundPosition}% 0%`;
    }
  });
}
function getArrowDirection(direction) {
  // direction = west, east, north, south, up, down
  switch (direction) {
    case "west":
      // West yönünde, okun geniş yüzeyi küpe bakacak şekilde ve küpün sol tarafına doğru
      return {
        position: (item) => [item.x - item.width / 2 - 1, item.y, item.z],
        rotation: [0, 0, Math.PI / 2], // Z ekseni etrafında 90 derece dönüş
      };
    case "east":
      // East yönünde, okun geniş yüzeyi küpe bakacak şekilde ve küpün sağ tarafına doğru
      return {
        position: (item) => [item.x + item.width / 2 + 1, item.y, item.z],
        rotation: [0, 0, -Math.PI / 2], // Z ekseni etrafında -90 derece dönüş
      };
    case "north":
      // North yönünde, okun geniş yüzeyi küpe bakacak şekilde ve küpün ön tarafına doğru
      return {
        position: (item) => [item.x, item.y, item.z - item.thickness / 2 - 1],
        rotation: [-Math.PI / 2, 0, 0], // X ekseni etrafında 90 derece dönüş
      };
    case "south":
      // South yönünde, okun geniş yüzeyi küpe bakacak şekilde ve küpün arka tarafına doğru
      return {
        position: (item) => [item.x, item.y, item.z + item.thickness / 2 + 1],
        rotation: [Math.PI / 2, 0, 0], // X ekseni etrafında -90 derece dönüş
      };
    case "up":
      // Up yönünde, okun geniş yüzeyi küpe bakacak şekilde ve yukarıya doğru
      return {
        position: (item) => [item.x, item.y + item.height / 2 + 1, item.z],
        rotation: [0, Math.PI, 0], // Y ekseni etrafında 180 derece dönüş
      };
    case "down":
      // Down yönünde, okun geniş yüzeyi küpe bakacak şekilde ve aşağıya doğru
      return {
        position: (item) => [item.x, item.y - item.height / 2 - 1, item.z],
        rotation: [Math.PI, 0, 0], // Rotasyon yok
      };
    default:
      return {
        position: (item) => [item.x, item.y, item.z],
        rotation: [0, 0, 0],
      };
  }
}
function addTextToArrow(arrow, scene, text) {
  const planeSize = 0.5; // Metnin boyutuna bağlı olarak ayarlayın
  const dynamicTexture = new BABYLON.DynamicTexture(
    "DynamicTexture",
    256,
    scene,
    true
  );
  dynamicTexture.hasAlpha = true;

  // Metni dynamicTexture üzerine çiz
  dynamicTexture.drawText(
    text,
    5,
    170,
    "bold 95px Arial",
    "#faa520",
    "transparent",
    true
  );

  const plane = BABYLON.MeshBuilder.CreatePlane(
    "textPlane",
    { size: planeSize },
    scene
  );
  const planeMaterial = new BABYLON.StandardMaterial("planeMaterial", scene);
  planeMaterial.diffuseTexture = dynamicTexture;
  planeMaterial.specularColor = new BABYLON.Color3(0, 0, 0); // Yansımayı kapat
  planeMaterial.diffuseTexture.hasAlpha = true;
  plane.material = planeMaterial;

  // Plane'i okun sivri ucuna yerleştir
  plane.position = arrow.position.clone();
  // plane.rotation = arrow.rotation.clone();

  // Okun rotasyonuna bağlı olarak metni uygun şekilde yerleştir
  const distance = 0.25; // Okun ucundan metnin başlangıç noktasına kadar olan mesafe
  const arrowHeight = 1.5; // Okun yüksekliği
  // plane.position.addInPlace(
  //   arrow
  //     .getDirection(BABYLON.Vector3.Forward())
  //     .scale(arrowHeight / 2 + distance)
  // );
  if (text === "west") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Left()).scale(1)
    );
  } else if (text === "east") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Right()).scale(1)
    );
  } else if (text === "north") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Backward()).scale(1)
    );
  } else if (text === "south") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Forward()).scale(1)
    );
  } else if (text === "up") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Up()).scale(1)
    );
  } else if (text === "down") {
    plane.position.addInPlace(
      arrow.getDirection(BABYLON.Vector3.Down()).scale(1)
    );
  }

  plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL; // Kameraya her açıdan bak

  // Okun rotasyonunu ve pozisyonunu kullanarak plane'i yerleştirin (gerekirse)
  plane.rotationQuaternion = null; // billboardMode kullanılırken quaternion iptal edilmeli
}

function createSkybox(scene) {
  // Skybox

  var skybox = BABYLON.MeshBuilder.CreateSphere(
    "skyBox",
    { diameter: 1000, sideOrientation: 2 },
    scene
  );
  var skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
  //skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
    "https://www.babylonjs-playground.com/textures/skybox",
    scene
  );
  skyboxMaterial.reflectionTexture.coordinatesMode =
    BABYLON.Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
  skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
  skybox.material = skyboxMaterial;
  skybox.infiniteDistance = true;
}
function createGround(scene) {
  // Zemin oluştur
  var ground = BABYLON.MeshBuilder.CreateGround(
    "ground",
    { width: 1000, height: 1000, subdivisions: 256 },
    scene
  );
  var groundMaterial = new BABYLON.StandardMaterial("groundMaterial", scene);
  groundMaterial.diffuseTexture = new BABYLON.Texture(Ground, scene);
  groundMaterial.diffuseTexture.uScale = 100;
  groundMaterial.specularColor = new BABYLON.Color3(0.1, 0.1, 0.1);
  groundMaterial.diffuseTexture.vScale = 100;
  groundMaterial.backFaceCulling = false;
  ground.material = groundMaterial;

  // ground.position.y = -0.05;
}

const BabylonCanvasPreview = ({ shapes }, ref) => {
  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const [engine, setEngine] = useState(null);

  const [scene, setScene] = useState(null);
  // const [babylonComponentList, setBabylonComponentList] = useState([]);

  useImperativeHandle(ref, () => ({
    // setShapes: (shapes) => {
    //   setBabylonComponentList(shapes);
    // },
    scene,
    engine,
    clear: () => {
      if (scene) {
        scene?.meshes?.forEach((mesh) => {
          mesh.dispose();
        });
      }
    },
  }));
  useEffect(() => {
    let event1;
    let event2;

    const disposeList = [];
    if (canvasRef.current && !engine) {
      const eng = new BABYLON.Engine(canvasRef.current, true);
      const scn = new BABYLON.Scene(eng);

      var camera = new BABYLON.FreeCamera(
        "FreeCamera",
        new BABYLON.Vector3(0, 50, -50),
        scn
      );
      camera.setTarget(BABYLON.Vector3.Zero());
      var rotationQuaternion = BABYLON.Quaternion.RotationYawPitchRoll(
        0, // Yaw: Kuzeye doğru 45 derece açı
        Math.PI / 4, // Pitch: Yere doğru 45 derece eğim
        0 // Roll: Sabit
      );
      camera.rotationQuaternion = rotationQuaternion;
      camera.attachControl(canvasRef.current, true);

      camera.keysUp.push(38); // Arrow up
      camera.keysDown.push(40); // Arrow down
      camera.keysLeft.push(37); // Arrow left
      camera.keysRight.push(39); // Arrow right

      camera.keysUp.push(87); // W
      camera.keysDown.push(83); // S
      camera.keysLeft.push(65); // A
      camera.keysRight.push(68); // D

      // Space ve Ctrl için tanımlamalar
      let isSpaceDown = false;
      let isCtrlDown = false;
      const verticalSpeed = 0.2; // Yüksekliği ayarlamak için hız

      // Klavye olaylarını dinleyin
      event1 = (event) => {
        if (event.key === " ") {
          isSpaceDown = true;
          // event.stopPropagation();
          // event.preventDefault();
        }
        if (event.key === "Control") {
          isCtrlDown = true;
          // event.stopPropagation();
          // event.preventDefault();
        }
      };
      window.addEventListener("keydown", event1);

      event2 = (event) => {
        if (event.key === " ") {
          isSpaceDown = false;
          // event.stopPropagation();
          // event.preventDefault();
        }
        if (event.key === "Control") {
          isCtrlDown = false;
          // event.stopPropagation();
          // event.preventDefault();
        }
      };
      window.addEventListener("keyup", event2);
      // disposeList.push(() => {
      //   window.removeEventListener("keydown", event1);
      //   window.removeEventListener("keyup", event2);
      // });

      // Render döngüsünde Space ve Ctrl kontrolü
      scn.registerBeforeRender(() => {
        if (isSpaceDown) {
          camera.position.y += verticalSpeed; // Yüksel
        }
        if (isCtrlDown) {
          camera.position.y -= verticalSpeed; // Alçal
        }
      });

      // Işık, pusula, gökyüzü ve zemin oluşturma
      const light = new BABYLON.HemisphericLight(
        "hemiLight",
        new BABYLON.Vector3(2, 3, 0),
        scn
      );
      light.intensity = 0.92;

      compassHTML(canvasContainerRef.current, camera, scn);
      createSkybox(scn);
      createGround(scn);

      // Motoru ve sahneyi ayarlayın
      setEngine(eng);
      setScene(scn);

      // Render döngüsü
      eng.runRenderLoop(() => {
        scn.render();
      });
    }

    const onResize = () => {
      engine?.resize();
    };
    window.addEventListener("resize", onResize);

    return () => {
      if (engine) engine.dispose();
      window.removeEventListener("resize", onResize);
      disposeList.forEach((dispose) => {
        dispose();
      });
    };
  }, [engine]);

  return (
    <Box
      ref={canvasContainerRef}
      sx={{
        position: "relative",
      }}
    >
      <StyledCanvas ref={canvasRef}></StyledCanvas>
    </Box>
  );
};

export default forwardRef(BabylonCanvasPreview);
