import { Brightness5, People } from "@material-ui/icons";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import HandymanIcon from "@mui/icons-material/Handyman";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PlumbingIcon from "@mui/icons-material/Plumbing";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import ConstructionIcon from "@mui/icons-material/Construction";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import PowerIcon from "@mui/icons-material/Power";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ActionsCreate from "views/CorePages/Actions/ActionsCreate";
import ActionsSearch from "views/CorePages/Actions/ActionsSearch";
import ActionsUpdate from "views/CorePages/Actions/ActionsUpdate";
import ElectricalEquipmentDefinitionCreate from "views/CorePages/ElectricalEquipmentDefinition/ElectricalEquipmentDefinitionCreate";
import ElectricalEquipmentDefinitionSearch from "views/CorePages/ElectricalEquipmentDefinition/ElectricalEquipmentDefinitionSearch";
import ElectricalEquipmentDefinitionUpdate from "views/CorePages/ElectricalEquipmentDefinition/ElectricalEquipmentDefinitionUpdate";
import MaterialsCreate from "views/CorePages/Material/MaterialsCreate";
import MaterialsSearch from "views/CorePages/Material/MaterialsSearch";
import MaterialsUpdate from "views/CorePages/Material/MaterialsUpdate";
import ActivityCreate from "views/CorePages/Activity/ActivityCreate";
import ActivitySearch from "views/CorePages/Activity/ActivitySearch";
import ActivityUpdate from "views/CorePages/Activity/ActivityUpdate";
import ConstructionCreate from "views/CorePages/Construction/ConstructionCreate";
import ConstructionSearch from "views/CorePages/Construction/ConstructionSearch";
import ConstructionUpdate from "views/CorePages/Construction/ConstructionUpdate";
import ConstructionMaterialCreate from "views/CorePages/ConstructionMaterials/ConstructionMaterialCreate";
import ConstructionMaterialSearch from "views/CorePages/ConstructionMaterials/ConstructionMaterialSearch";
import ConstructionMaterialUpdate from "views/CorePages/ConstructionMaterials/ConstructionMaterialsUpdate";
import MenuRoleCreate from "views/CorePages/MenuRoleMap/MenuRoleCreate";
import MenusCreate from "views/CorePages/Menus/MenusCreate";
import MenusSearch from "views/CorePages/Menus/MenusSearch";
import MenusUpdate from "views/CorePages/Menus/MenusUpdate";
import RolesCreate from "views/CorePages/Roles/RolesCreate";
import RolesSearch from "views/CorePages/Roles/RolesSearch";
import RolesUpdate from "views/CorePages/Roles/RolesUpdate";
import MenuRoleSearch from "views/CorePages/MenuRoleMap/MenuRoleSearch";
import MenuRoleUpdate from "views/CorePages/MenuRoleMap/MenuRoleUpdate";
import ActionRoleSearch from "views/CorePages/ActionRoleMap/ActionRoleSearch";
import ActionRoleCreate from "../views/CorePages/ActionRoleMap/ActionRoleCreate";
import ActionRoleUpdate from "../views/CorePages/ActionRoleMap/ActionRoleUpdate";
import UserDetail from "views/UserProfile/UserProfile";
import UserSearch from "views/User/UserSearch";
import GeneralInformation from "views/EnergyConsumption/GeneralInformation/GeneralInformation";
import BuildingGeometry from "views/EnergyConsumption/BuildingGeometry/BuildingGeometry";
import ElectricalEquipmentCreate from "views/EnergyConsumption/ElectricalEquipment/ElectricalEquipmentCreate";
import ElectricalEquipmentSearch from "views/EnergyConsumption/ElectricalEquipment/ElectricalEquipmentSearch";
import ElectricalEquipmentUpdate from "views/EnergyConsumption/ElectricalEquipment/ElectricalEquipmentUpdate";
import BuildingZoneCreate from "views/EnergyConsumption/BuildingZones/BuildingZoneCreate";
import BuildingZoneSearch from "views/EnergyConsumption/BuildingZones/BuildingZoneSearch";
import BuildingZoneUpdate from "views/EnergyConsumption/BuildingZones/BuildingZoneUpdate";
import ZoneConstructionMapCreate from "views/EnergyConsumption/ZoneConstructionMap/ZoneConstructionMapCreate";
import ZoneConstructionMapSearch from "views/EnergyConsumption/ZoneConstructionMap/ZoneConstructionMapSearch";
import ZoneConstructionMapUpdate from "views/EnergyConsumption/ZoneConstructionMap/ZoneConstructionMapUpdate";
import UsageProfileCreate from "views/EnergyConsumption/UsageProfile/UsageProfileCreate";
import Results from "views/EnergyConsumption/EnergyConsumptionResults/EnergyConsumptionResults";
import EnergyConsumptionProjects from "views/EnergyConsumption/Projects/EnergyConsumptionProjects";
import RenewableEnergyProjects from "views/Renewable/Projects/RenewableEnergyProjects";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import RenewableInformation from "views/Renewable/RenewableInformation/RenewableInformation";
import ElectriotyLoad from "views/Renewable/ElectriotyLoad/ElectriotyLoad";
import RenewableSystem from "views/Renewable/RenewableSystem/RenewableSystem";
import RenewableSystemResult from "views/Renewable/RenewableResult/RenewableResult";

import GestProjects from "views/GEST/Projects/GestProjects";

import ConstructionPreview from "views/EnergyConsumption/ConstructionPreview";
import CommunityProjects from "views/Community/Projects/CommunityProjects";
import CommunityResults from "views/Community/Result/CommunityResults";
import CommunityCreate from "views/Community/CreateCommunity/CommunityCreate";
import CommunityBuildingCreate from "views/Community/CommunityBuildings/CommunityBuildingCreate";
import CommunityBuildingSearch from "views/Community/CommunityBuildings/CommunityBuildingSearch";
import CommunityBuildingUpdate from "views/Community/CommunityBuildings/CommunityBuildingUpdate";
import CommunityConstructionPreview from "views/EnergyConsumption/CommunityConstructionPreview";

import FreeCoolingSystemCalculate from "views/FCS/FCSResults";

export const GBESSRoutes = [
  {
    path: "/softwares",
    name: "Softwares",
    icon: SolarPowerIcon,
    layout: "/admin",
    IsShow: true,
    IsParent: true,
  },
  {
    path: "/renewable-energy-projects",
    name: "REST",
    icon: ElectricMeterIcon,
    component: RenewableEnergyProjects,
    layout: "/admin",
    IsShow: true,
    parent: "/softwares",
  },
  {
    path: "/gest-projects",
    name: "ZP_BEST",
    icon: ElectricMeterIcon,
    component: GestProjects,
    layout: "/admin",
    IsShow: true,
    parent: "/softwares",
  },
  {
    path: "/renewable-information/:id",
    name: "Renewable_Information",
    icon: EditNoteIcon,
    component: RenewableInformation,
    layout: "/admin",
    IsShow: true,
  },
  {
    path: "/electrioty-load/:id",
    name: "Electricity_Load",
    icon: ElectricBoltIcon,
    component: ElectriotyLoad,
    layout: "/admin",
    IsShow: true,
  },
  {
    path: "/renewable-system/:id",
    name: "Renewable_System",
    icon: SolarPowerIcon,
    component: RenewableSystem,
    layout: "/admin",
    IsShow: true,
  },
  {
    path: "/renewable-system-result/:id",
    name: "Renewable Result",
    icon: SolarPowerIcon,
    component: RenewableSystemResult,
    layout: "/admin",
    IsShow: true,
  },
  {
    path: "/community-projects",
    name: "ZP_C",
    icon: DomainAddIcon,
    component: CommunityProjects,
    layout: "/admin",
    IsShow: true,
    parent: "/softwares",
  },
  {
    path: "/community/:id",
    name: "Community_General_Information",
    icon: EditNoteIcon,
    component: CommunityCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/community-buildings-create/:id",
    name: "Community_Buildings",
    component: CommunityBuildingCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/community-buildings-update/:id",
    name: "Community_Buildings",
    component: CommunityBuildingUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/community-buildings-search/:id",
    name: "Community_Buildings",
    component: CommunityBuildingSearch,
    icon: PowerIcon,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/community-results/:id",
    name: "Community_Results",
    component: CommunityResults,
    icon: PowerIcon,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/energy-consumption-projects",
    name: "BEST",
    icon: ElectricMeterIcon,
    component: EnergyConsumptionProjects,
    layout: "/admin",
    IsShow: true,
    parent: "/softwares",
  },
  {
    path: "/general-information/:id",
    name: "General_Information",
    icon: EditNoteIcon,
    component: GeneralInformation,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-zone-create/:id",
    name: "Building_Zones",
    component: BuildingZoneCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-zone-update/:id",
    name: "Building_Zones",
    component: BuildingZoneUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-zone-search/:id",
    name: "Building_Zones",
    component: BuildingZoneSearch,
    icon: PowerIcon,
    layout: "/admin",
    IsShow: false,
  },

  {
    path: "/building-construction-create/:id",
    name: "Construction_Zones",
    component: ZoneConstructionMapCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-construction-update/:id",
    name: "Construction_Zones",
    component: ZoneConstructionMapUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-construction-search/:id",
    name: "Building_Construction",
    icon: ConstructionIcon,
    component: ZoneConstructionMapSearch,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-construction-preview/:id",
    name: "Building_Construction_Preview",
    icon: ConstructionIcon,
    component: ConstructionPreview,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/community-buildings-preview/:id",
    name: "Community_Buildings_Preview",
    icon: ConstructionIcon,
    component: CommunityConstructionPreview,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/building-geometry/:id",
    name: "Building_Geometry",
    icon: ArchitectureIcon,
    component: BuildingGeometry,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/electrical-equipment-create/:id",
    name: "electrical-equipment",
    component: ElectricalEquipmentCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/electrical-equipment-update/:id",
    name: "electrical-equipment",
    component: ElectricalEquipmentUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/electrical-equipment-search/:id",
    name: "electrical-equipment",
    component: ElectricalEquipmentSearch,
    icon: PowerIcon,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/usage-profile/:id",
    name: "Usage_Profile",
    icon: SettingsAccessibilityIcon,
    component: UsageProfileCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/results/:id",
    name: "Results",
    icon: SummarizeIcon,
    component: Results,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/free-cooling-system-calculate",
    name: "FCS",
    icon: AcUnitIcon,
    component: FreeCoolingSystemCalculate,
    layout: "/admin",
    IsShow: true,
    parent: "/softwares",
  },
  {
    path: "/definitions",
    name: "Definitions",
    icon: RoomPreferencesIcon,
    layout: "/admin",
    IsShow: true,
    IsParent: true,
  },
  {
    path: "/materials-create",
    name: "materials",
    component: MaterialsCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/materials-update/:id",
    name: "materials",
    component: MaterialsUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/materials-search",
    name: "materials",
    component: MaterialsSearch,
    icon: PlumbingIcon,
    layout: "/admin",
    IsShow: true,
    parent: "/definitions",
  },
  {
    path: "/activity-create",
    name: "Activity",
    component: ActivityCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/activity-update/:id",
    name: "Activity",
    component: ActivityUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/activity-search",
    name: "Activity",
    component: ActivitySearch,
    icon: SportsKabaddiIcon,
    layout: "/admin",
    IsShow: true,
    parent: "/definitions",
  },
  {
    path: "/constructions-create",
    name: "Constructions",
    component: ConstructionCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/constructions-update/:id",
    name: "Constructions",
    component: ConstructionUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/constructions-search",
    name: "Constructions",
    component: ConstructionSearch,
    icon: DesignServicesIcon,
    layout: "/admin",
    IsShow: true,
    parent: "/definitions",
  },
  {
    path: "/construction-materials-create",
    name: "Construction_Materials",
    component: ConstructionMaterialCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/construction-materials-update/:id",
    name: "Construction_Materials",
    component: ConstructionMaterialUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/construction-materials-search",
    name: "Construction_Materials",
    component: ConstructionMaterialSearch,
    icon: HandymanIcon,
    layout: "/admin",
    IsShow: true,
    parent: "/definitions",
  },
  {
    path: "/electrical-equipment-definition-create",
    name: "Electrical_Equipments",
    component: ElectricalEquipmentDefinitionCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/electrical-equipment-definition-update/:id",
    name: "Electrical_Equipments",
    component: ElectricalEquipmentDefinitionUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/electrical-equipment-definition-search",
    name: "Electrical_Equipments",
    component: ElectricalEquipmentDefinitionSearch,
    icon: DeveloperBoardIcon,
    layout: "/admin",
    IsShow: true,
    parent: "/definitions",
  },
  {
    path: "/management-menus",
    name: "Management",
    icon: AdminPanelSettingsIcon,
    layout: "/admin",
    IsShow: true,
    IsParent: true,
  },
  {
    path: "/actions-create",
    name: "actions",
    component: ActionsCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/actions-update/:id",
    name: "actions",
    component: ActionsUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/actions-search",
    name: "actions",
    component: ActionsSearch,
    icon: Brightness5,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/menus-create",
    name: "menus",
    component: MenusCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/menus-update/:id",
    name: "menus",
    component: MenusUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/menus-search",
    name: "menus",
    component: MenusSearch,
    icon: Brightness5,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/roles-create",
    name: "roles",
    component: RolesCreate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/roles-update/:id",
    name: "roles",
    component: RolesUpdate,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/roles-search",
    name: "roles",
    component: RolesSearch,
    icon: Brightness5,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/menu-role-update/:id",
    name: "Menu_Role_Mapping",
    component: MenuRoleUpdate,
    icon: Brightness5,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/menu-role-create",
    name: "Menu_Role_Mapping",
    component: MenuRoleCreate,
    icon: Brightness5,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/menu-role-search",
    name: "Menu_Role_Mapping",
    component: MenuRoleSearch,
    icon: Brightness5,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/action-role-update/:id",
    name: "Action_Role_Mapping",
    component: ActionRoleUpdate,
    icon: Brightness5,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/action-role-create",
    name: "Action_Role_Mapping",
    component: ActionRoleCreate,
    icon: Brightness5,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/action-role-search",
    name: "Action_Role_Mapping",
    component: ActionRoleSearch,
    icon: Brightness5,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/user-search",
    name: "Users",
    component: UserSearch,
    icon: People,
    layout: "/admin",
    IsShow: true,
    parent: "/management-menus",
  },
  {
    path: "/user-detail/:id",
    name: "Users",
    component: UserDetail,
    layout: "/admin",
    IsShow: false,
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserDetail,
    layout: "/admin",
    IsShow: false,
  },
];

export default GBESSRoutes;
