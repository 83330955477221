import React, { useEffect } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSDivider from "../../../components/GBESS/GBESSDivider";
import ExportDataButton from "../../../components/Dashboard/ExportDataButton";
import GBESSExportDataButton from "../../../components/GBESS/GBESSExportDataButton";
import GBESSLabel from "../../../components/GBESS/GBESSLabel";
import AlertHelper from "../../../core/helpers/AlertHelper";
import EvaluationTable from "./EvaluationTable";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PassiveHouseTable from "./PassiveHouseTable";

const styles = {};
const useStyles = makeStyles(styles);
const EnergyConsumptionResults = (props) => {
  const classes = useStyles();
  const [passiveHouseModel, setpassiveHouseModel] = React.useState({});
  const projecttype = useSelector((state) => state.projecttype.projecttype);
  const [model, setModel] = React.useState({});
  const [energyConsumptionResults, setEnergyConsumptionResults] =
    React.useState([]);
  const [evaluationCriteriaList, setEvaluationCriteriaList] = React.useState(
    []
  );
  const [energyConsumptionConductionList, setEnergyConsumptionConductionList] =
    React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
      calculate();
    }
    setModel({ ...model });
  }, []);

  useEffect(() => {
    if (projecttype === "GEST") {
      setTimeout(() => {
        let CoolingDemandResult = [];
        let HeatingDemandResult = [];
        Object.keys(energyConsumptionResults).forEach((key) => {
          const value = energyConsumptionResults[key];
          if (value && value.CoolingDemand !== undefined) {
            CoolingDemandResult.push(value.CoolingDemand);
            if (value.HeatingDemand !== undefined) {
              HeatingDemandResult.push(value.HeatingDemand);
            }
          }
        });
        if (HeatingDemandResult.length > 0) {
          model.MAXHeatingDemand = Math.max(...HeatingDemandResult);
          model.MAXCoolingDemand = Math.max(...CoolingDemandResult);
          Proxy.POST("/coreapi/v1.0/GestEnergyResult/PassiveHouseCalculate", {
            MAXCoolingDemand: model.MAXCoolingDemand,
            CoolingDemand: model.CoolingDemand,
            MAXHeatingDemand: model.MAXHeatingDemand,
            HeatingDemand: model.HeatingDemand,
            ProjectId: model.ProjectId,
          })
            .withErrorAlert()
            .success((response) => {
              setpassiveHouseModel({ ...response.Item });
            })
            .error((error) => {
              AlertHelper.show("warning", "Warning", error?.ErrorDescription);
            })
            .continueWith(() => {
              setIsLoading(false);
            });
        }
      }, 100);
    } else {
      setIsLoading(false);
    }
  }, [energyConsumptionResults]);

  const handlePreviousPage = (e) => {
    if (projecttype === "GEST") {
      props?.history?.push?.(`/admin/renewable-system/${model.ProjectId}`);
    } else {
      props?.history?.push?.(`/admin/usage-profile/${model.ProjectId}`);
    }
  };

  const calculate = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/GestEnergyResult/Calculate", model)
      .withErrorAlert()
      .success((response) => {
        ValueChanged("OptimumUValue", response.Item?.OptimumUValue);
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setTimeout(() => {
          Proxy.POST("/coreapi/v1.0/EnergyConsumptionResults/Calculate", model)
            .withErrorAlert()
            .success((response) => {
              setModel({ ...response.Item });
              AlertHelper.show(
                "success",
                t("success"),
                t("calculate-successfully")
              );
              if (
                response.Item?.EnergyConsumptionResults != null &&
                response.Item?.EnergyConsumptionResults.length > 0
              ) {
                setEnergyConsumptionResults(
                  JSON.parse(response.Item?.EnergyConsumptionResults)
                );
              }
              if (
                response.Item?.EvaluationCriteria != null &&
                response.Item?.EvaluationCriteria.length > 0
              ) {
                setEvaluationCriteriaList(
                  JSON.parse(response.Item?.EvaluationCriteria)
                );
              }
              if (
                response.Item?.EnergyConsumptionResults != null &&
                response.Item?.EnergyConsumptionResults
                  .EnergyConsumptionConductionList != null &&
                response.Item?.EnergyConsumptionResults
                  .EnergyConsumptionConductionList.length > 0
              ) {
                energyConsumptionResults.map((item, index) =>
                  setEnergyConsumptionConductionList((prevList1) => [
                    ...prevList1,
                    energyConsumptionResults[index]
                      .EnergyConsumptionConductionList,
                  ])
                );
                /*setEnergyConsumptionConductionList(
            JSON.parse(
              response.Item?.EnergyConsumptionResults
                .EnergyConsumptionConductionList
            )
          );*/
              }
            })
            .error((error) => {
              AlertHelper.show("warning", "Warning", error?.ErrorDescription);
            })
            .continueWith(() => {
              setIsLoading(false);
            });
        }, 500);
        setIsLoading(false);
      });
  };

  const saveProject = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/EnergyConsumptionResults/SaveProject", {
      ProjectId: model.ProjectId,
    })
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", "Success", "Save Project");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleGoToSearch = () => {
    props?.history?.push?.("/admin/actions-search");
  };

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader title={t("results")} subtitle={t("result-page")} />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    bold
                    text={t("energy-consumption")}
                  />
                  <GBESSDivider />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="HeatingDemand"
                    disabled={true}
                    value={model.HeatingDemand}
                    valueChanged={ValueChanged}
                    labelText={t("heating-demand")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="CoolingDemand"
                    disabled={true}
                    value={model.CoolingDemand}
                    valueChanged={ValueChanged}
                    labelText={t("cooling_demand")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="FinalyEnergyDemand"
                    disabled={true}
                    value={model.FinalyEnergyDemand}
                    valueChanged={ValueChanged}
                    labelText={t("total-energy-consumption")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="OptimumUValue"
                    disabled={true}
                    value={model.OptimumUValue}
                    valueChanged={ValueChanged}
                    labelText={t("optimum-u-value")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    bold
                    text={t("satisfaction-result")}
                  />
                  <GBESSDivider />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  {evaluationCriteriaList.map((criteria) => (
                    <EvaluationTable
                      key={criteria.ZoneName}
                      criteria={criteria}
                    />
                  ))}
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    text={t("criteria_scored_over")}
                  />
                </GBESSGridItem>
                {projecttype === "GEST" &&
                  passiveHouseModel.PassiveHouseResult !== null &&
                  passiveHouseModel.PassiveHouseResult !== undefined && (
                    <GBESSGridItem xs={12} sm={12} md={12}>
                      <PassiveHouseTable criteria={passiveHouseModel} />
                    </GBESSGridItem>
                  )}
              </GBESSGridContainer>

              <GBESSGridContainer>
                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={handlePreviousPage}
                    code={"result-previous"}
                  >
                    {t("previous")}
                  </GBESSButton>
                </GBESSGridItem>

                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-center" }}
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <GBESSExportDataButton
                    data={energyConsumptionResults}
                    excelFileName="Consumption Summary"
                    labelText="Consumption Summary"
                  />
                </GBESSGridItem>

                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-center" }}
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <GBESSExportDataButton
                    data={energyConsumptionConductionList}
                    excelFileName="Consumption Conduction Summary"
                    labelText="Consumption Conduction Summary"
                  />
                </GBESSGridItem>

                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  xs={12}
                  sm={3}
                  md={3}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={saveProject}
                    code={"result-submit"}
                  >
                    Save Project
                  </GBESSButton>
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default EnergyConsumptionResults;
