import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const BuildingZoneUpdate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.Id = id;
      getRecord(id);
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/EnergyConsumptionZones/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        setModel(response.Item);
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = {
      ZoneName: false,
      HCTemperature: false,
      CCTemperature: false,
      Id: false,
    };
    let result = true;
    if (model.Id == null || model.Id == "" || model.Id <= 0) {
      tempValidateModel.Id = true;
      result = false;
    }
    if (model.ZoneName == null || model.ZoneName == "") {
      tempValidateModel.ZoneName = true;
      result = false;
    }
    if (model.HCTemperature == null || model.HCTemperature == "") {
      tempValidateModel.HCTemperature = true;
      result = false;
    }
    if (model.CCTemperature == null || model.CCTemperature == "") {
      tempValidateModel.CCTemperature = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/EnergyConsumptionZones/Update", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.(`/admin/building-zone-search/${model.ProjectId}`);
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("update-building-zone")}
          subtitle={t("update-building-zone-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ZoneName"
                    error={modelValidate.ZoneName}
                    value={model.ZoneName}
                    valueChanged={ValueChanged}
                    labelText={t("zone-name")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="HCTemperature"
                    error={modelValidate.HCTemperature}
                    value={model.HCTemperature}
                    valueChanged={ValueChanged}
                    labelText={t("indoor-temperature-for-hc")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="CCTemperature"
                    error={modelValidate.CCTemperature}
                    value={model.CCTemperature}
                    valueChanged={ValueChanged}
                    labelText={t("indoor-temperature-for-cc")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="ZoneWallHeight"
                    error={modelValidate.ZoneWallHeight}
                    value={model.ZoneWallHeight}
                    valueChanged={ValueChanged}
                    labelText={t("zone-wall-height")}
                    type="number"
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="building-zone-edit-update"
          >
            Update
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default BuildingZoneUpdate;
