import React from "react";
import dayjs from "dayjs";
import { TextField, makeStyles } from "@material-ui/core";
import combineStyles from "./../../core/helpers/combineStyles";
import { successColor } from "assets/jss/material-dashboard-react";
import baseStyles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import GBESSGridContainer from "./GBESSGridContainer";
import GBESSGridItem from "./GBESSGridItem";
import GBESSLabel from "./GBESSLabel";
import { useTranslation } from "react-i18next";

const styles = {
  root: {
    margin: "0px 0px 16px 0px",
  },
  label: {
    marginTop: 16,
  },
  underline: {
    color: successColor[0],
  },
};
const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSDateTimePicker(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <GBESSGridItem xs={12} className={classes.root}>
      <GBESSGridContainer>
        <GBESSGridItem xs={props.labelMd}>
          <GBESSLabel {...props.rest} noGrid text={props.labelText} />
        </GBESSGridItem>
        <GBESSGridItem xs={12 - props.labelMd}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              format="DD-MM"
              inputFormat="DD/MM"
              disableFuture
              label={`"${t("day")}" and "${t("month")}"`}
              views={["day", "month"]}
              renderInput={(props) => <TextField fullWidth {...props} />}
              onChange={props.onChange}
              minDate={new Date("2023-01-01T00:00:00")} // Minimum seçilebilir tarih: 2023-01-01
              maxDate={new Date("2023-12-31T23:59:59")} // Maksimum seçilebilir tarih: 2023-12-31
              {...props}
            />
          </LocalizationProvider>
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSGridItem>
  );
}
GBESSDateTimePicker.defaultProps = {
  labelMd: 5,
};
export default GBESSDateTimePicker;
