import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Poppers from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import Dashboard from "@material-ui/icons/Dashboard";
import Notifications from "@material-ui/icons/Notifications";
import Person from "@material-ui/icons/Person";
import Search from "@material-ui/icons/Search";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { userConstants } from "core/constants/auth.constants.core";
import AuthHelper from "core/helpers/AuthHelper";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import Proxy from "core/Proxy";
import { GBESSDialog } from "components/GBESS";
import { useTranslation } from "react-i18next";

// @material-ui/core components
// @material-ui/icons
// core components
const useStyles = makeStyles(styles);

export default function AdminNavbarLinks({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state?.user?.user);
  const [alarmNotifications, setAlarmNotifications] = React.useState([]);
  const [alarmDetail, setAlarmDetail] = React.useState({});
  const [alarmDetailOpen, setAlarmDetailOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (user?.id) {
    }
  }, [user]);

  const handleAlarmMute = () => {
    Proxy.GET(
      `/coreapi/v1.0/AlarmNotification/AlarmNotificationMute/Id?Id=${alarmDetail?.Id}`
    )
      .withErrorAlert()
      .success((response) => {})
      .error((error) => {})
      .continueWith(() => {
        setAlarmDetailOpen(false);
      });
  };
  const handleCloseAlarmDetail = () => setAlarmDetailOpen(false);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = (alarm) => () => {
    if (alarm?.Id > 0) {
      setAlarmDetail(alarm);
      setAlarmDetailOpen(true);
    }
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleClickLogout = () => {
    AuthHelper.logout();
    dispatch({
      type: userConstants.LOGOUT,
      loggedIn: false,
    });
    history?.push?.("/login");
  };

  const handleGoToProfile = () => {
    history?.push?.("/admin/profile");
  };

  return (
    <div>
      <GBESSDialog
        open={alarmDetailOpen}
        title={"Alarm Notification Detail"}
        content={alarmDetail?.Message}
        acceptButtonName={"Alarm Notification Mute"}
        rejectButtonName={"Close"}
        handleClose={handleCloseAlarmDetail}
        handleAccept={handleAlarmMute}
        handleReject={handleCloseAlarmDetail}
      />
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search,
          }}
          inputProps={{
            placeholder: t("search"),
            inputProps: {
              "aria-label": t("search"),
            },
          }}
        />
        <Button color="white" aria-label="edit" justIcon round>
          <Search />
        </Button>
      </div>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-label="Dashboard"
        className={classes.buttonLink}
      >
        <Dashboard className={classes.icons} />
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Dashboard</p>
        </Hidden>
      </Button>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={classes.buttonLink}
        >
          <Notifications className={classes.icons} />
          <span className={classes.notifications}>
            {alarmNotifications?.length}
          </span>
          <Hidden mdUp implementation="css">
            <p
              onClick={handleCloseNotification({})}
              className={classes.linkText}
            >
              Notification
            </p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification({})}>
                  <MenuList role="menu">
                    {alarmNotifications?.map?.((alarm) => {
                      return (
                        <MenuItem
                          key={alarm.Id + "_alarm_notification"}
                          onClick={handleCloseNotification(alarm)}
                          className={classes.dropdownItem}
                        >
                          {alarm?.Message?.length > 80
                            ? `${alarm?.Message?.substring?.(0, 80)}...`
                            : alarm?.Message}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>{t("Profile")}</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleGoToProfile}
                      className={classes.dropdownItem}
                    >
                      {t("Profile")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      {t("Settings")}
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleClickLogout}
                      className={classes.dropdownItem}
                    >
                      {t("Logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
