import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@material-ui/core/styles";
import logo from "assets/img/GBESS-logo.png";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import Footer from "components/Footer/Footer.js";
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PerfectScrollbar from "perfect-scrollbar";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import PrivateRoute from "./../components/Route/PrivateRoute";
import { useSelector } from "react-redux";

// creates a beautiful scrollbar
// @material-ui/core components
// core components
let ps;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const menus = useSelector((state) => state.menus.menus);
  // states and functions
  const [color] = React.useState("green");
  const [menuRoutes, setMenuRoutes] = React.useState([]);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    var tempRoutes = routes?.filter?.(
      (x) => x.IsShow && menus.find((k) => "/" + k.MenuCode == x.path) != null
    );
    setMenuRoutes(tempRoutes);
  }, [menus]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={menuRoutes}
        logoText={
          <span
            style={{
              fontSize: 15,
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <span style={{ height: 16 }}> GREEN BUILDING </span>
            <span style={{ height: 16 }}> AND </span>
            <span style={{ height: 16 }}> BUILDING COMMUNITY </span>
            <span> SIMULATION TOOL</span>
            <span> (GBBCS) </span>
          </span>
        }
        logo={logo}
        image={null}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"green"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
      </div>
    </div>
  );
}
