import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const styles = {};
const useStyles = makeStyles(styles);
const CommunityBuildingCreate = (props) => {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    if (id > 0) {
      model.CommunityId = id;
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = { X: false, Y: false };
    let result = true;
    if (model.X == null || model.X == "") {
      tempValidateModel.X = true;
      result = false;
    }
    if (model.Y == null || model.Y == "") {
      tempValidateModel.Y = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/CommunityBuilding/Insert", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.(
      `/admin/community-buildings-search/${model.CommunityId}`
    );
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title="Create Community Building"
          subtitle="On this screen, you can add buildings or renewable systems to your community."
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("project_type")}
                    name="ProjectTypeId"
                    value={model.ProjectTypeId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ProjectType"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/EnergyConsumptionProjects/Search"}
                    name="BuildingProjectId"
                    renderer={(item) => {
                      return `${item.ProjectName}`;
                    }}
                    keySelector={"Id"}
                    value={model.BuildingProjectId}
                    valueChanged={ValueChanged}
                    labelText={t("Community_Reference_Building")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/RenewableEnergyProjects/Search"}
                    name="RenewableSystemProjectId"
                    renderer={(item) => {
                      return `${item.ProjectName}`;
                    }}
                    keySelector={"Id"}
                    value={model.RenewableSystemProjectId}
                    valueChanged={ValueChanged}
                    labelText={t("Renewable_System")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="X"
                    error={modelValidate.X}
                    value={model.X}
                    valueChanged={ValueChanged}
                    labelText={t("renewable_system.X")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Y"
                    error={modelValidate.Y}
                    value={model.Y}
                    valueChanged={ValueChanged}
                    labelText={t("renewable_system.Y")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="community-buildings-create-submit"
          >
            {t("add")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default CommunityBuildingCreate;
