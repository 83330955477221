import React, { useEffect } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useParams } from "react-router-dom";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { renderDirection } from "./ZoneConstructionMapCreate";

const styles = {};
const useStyles = makeStyles(styles);
const ZoneConstructionMapUpdate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const [modelValidate, setModelValidate] = React.useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.Id = id;
      getRecord(id);
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(
      `/coreapi/v1.0/EnergyConsumptionConstructions/GetById/Id?Id=${id}`
    )
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = {
      ZoneId: false,
      DirectionId: false,
      Id: false,
    };
    let result = true;
    if (model.Id == null || model.Id == "" || model.Id <= 0) {
      tempValidateModel.Id = true;
      result = false;
    }
    if (model.ZoneId == null || model.ZoneId <= 0) {
      tempValidateModel.ZoneId = true;
      result = false;
    }
    if (model.DirectionId == null || model.DirectionId <= 0) {
      tempValidateModel.DirectionId = true;
      result = false;
    }
    if (model.Width == null || model.Width == "") {
      tempValidateModel.Width = true;
      result = false;
    }
    if (model.Height == null || model.Height == "") {
      tempValidateModel.Height = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      if (model.ExposedToId != 10066) {
        model.ExposedToZoneId = 0;
      }
      if (model.ConstructionTypeId != 10067) {
        model.riId = 0;
        model.gtId = 0;
      }
      Proxy.POST("/coreapi/v1.0/EnergyConsumptionConstructions/Update", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.(
      `/admin/building-construction-search/${model.ProjectId}`
    );
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("update-zone-constructions-mapping")}
          subtitle={t("update-zone-constructions-mapping-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/EnergyConsumptionZones/Search"}
                    name="ZoneId"
                    renderer={(item) => {
                      return `${item.ZoneName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ZoneId}
                    valueChanged={ValueChanged}
                    labelText={t("zone")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("direction")}
                    name="DirectionId"
                    value={model.DirectionId}
                    error={modelValidate.DirectionId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="Direction"
                    valueChanged={ValueChanged}
                    renderer={renderDirection}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText="ConstructionType"
                    name="ConstructionTypeId"
                    value={model.ConstructionTypeId}
                    error={modelValidate.ConstructionTypeId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ConstructionType"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Constructions/Search"}
                    name="ConstructionId"
                    value={model.ConstructionId}
                    valueChanged={ValueChanged}
                    labelText={t("construction")}
                    keySelector="Id"
                    textSelector="ConstructionName"
                  />
                </GBESSGridItem>

                {1 == 2 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      url={"/coreapi/v1.0/ConstructionMaterials/Search"}
                      name="ConstructionMaterialId"
                      renderer={(item) => {
                        return `${item.Construction.ConstructionName}`;
                      }}
                      keySelector={"Id"}
                      value={model.ConstructionMaterialId}
                      valueChanged={ValueChanged}
                      labelText={t("construction")}
                    />
                  </GBESSGridItem>
                ) : null}

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("exposed-to")}
                    name="ExposedToId"
                    value={model.ExposedToId}
                    error={modelValidate.ExposedToId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ExposedTo"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>
                {model.ExposedToId == 10066 && (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("exposed-direction")}
                      name="ExposedDirectionId"
                      value={model.ExposedDirectionId}
                      error={modelValidate.ExposedDirectionId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="Direction"
                      valueChanged={ValueChanged}
                      renderer={renderDirection}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                )}
                {model.ExposedToId == 10066 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      url={"/coreapi/v1.0/EnergyConsumptionZones/Search"}
                      name="ExposedToZoneId"
                      renderer={(item) => {
                        return `${item.ZoneName}`;
                      }}
                      keySelector={"Id"}
                      value={model.ExposedToZoneId}
                      valueChanged={ValueChanged}
                      labelText={t("zone")}
                    />
                  </GBESSGridItem>
                ) : null}

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Width"
                    error={modelValidate.Width}
                    value={model.Width}
                    valueChanged={ValueChanged}
                    labelText={t("width")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Height"
                    error={modelValidate.Height}
                    value={model.Height}
                    valueChanged={ValueChanged}
                    labelText={t("height")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="OffsetX"
                    error={modelValidate.OffsetX}
                    value={model.OffsetX || 0}
                    valueChanged={ValueChanged}
                    labelText={"Distance from Origin to the Right (mm)"}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="OffsetY"
                    error={modelValidate.OffsetY}
                    value={model.OffsetY || 0}
                    valueChanged={ValueChanged}
                    labelText={"Distance from Origin to the Ground (mm)"}
                    type="number"
                  />
                </GBESSGridItem>
                {model.ConstructionTypeId == 10067 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("the-ghosting-factor")}
                      name="riId"
                      value={model.riId}
                      error={modelValidate.riId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="riType"
                      valueChanged={ValueChanged}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                ) : null}

                {model.ConstructionTypeId == 10067 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("the-solar-transmission-factor")}
                      name="gtId"
                      value={model.gtId}
                      error={modelValidate.gtId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="gtType"
                      valueChanged={ValueChanged}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                ) : null}
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="zone-construction-edit-update"
          >
            Update
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ZoneConstructionMapUpdate;
