import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import GBESSButton from "./GBESSButton";

const useStyles = makeStyles(styles);
//For detailed usage see https://mui.com/api/data-grid/data-grid/
export default function GBESSDataGrid(props) {
  const { rows, columns, gridActionList, containerProps, ...others } = props;
  return (
    <>
      {gridActionList &&
        gridActionList.map(({ name, ...rest }, index) => {
          return (
            <GBESSButton style={{ height: 30 }} key={index} {...rest}>
              {name}
            </GBESSButton>
          );
        })}
      <div
        style={{
          height: "50vh",
          width: "100%",
        }}
        {...containerProps}
      >
        <DataGrid rows={rows} columns={columns} {...others} />
      </div>
    </>
  );
}

GBESSDataGrid.defaultProps = {
  ...DataGrid.defaultProps,
  gridActionList: [],
};

GBESSDataGrid.propTypes = {
  ...DataGrid.propTypes,
  gridActionList: PropTypes.any,
};
