import { ElectriotyConstants } from "../constants/renewable.constants";
import { Action, Reducer } from "redux";
import { LoadState, KnownAction } from "../actions/electricityload.actions";

export const reducer: Reducer<LoadState> = (
  state: LoadState | undefined,
  incomingAction: Action
): LoadState => {
  if (state === undefined) {
    return { loads: {} };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ElectriotyConstants.LOAD_CHANGE_VALUE:
      return {
        loads: action.loads,
      };
    default:
      return state;
  }
};
