const inputBorderStyle = {
  borderStyle: {
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#e5e5ea",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#e5e5ea",
      },
    },
  },
};

export default inputBorderStyle;
