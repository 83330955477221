import * as AuthReducer from "../core/reducers/auth.reducer";
import * as AuthAction from "../core/actions/auth.actions";
import * as MenusReducer from "../core/reducers/menus.reducer";
import * as MenusAction from "../core/actions/menus.actions";
import * as ActionsReducer from "../core/reducers/actions.reducer";
import * as ActionsAction from "../core/actions/action.actions";
import * as GridReducer from "../core/reducers/grid.reducer";
import * as GridAction from "../core/actions/grid.actions";
import * as DesingReducer from "../core/reducers/design.reducer";
import * as DesingAction from "../core/actions/design.actions";
import * as LoadReducer from "../core/reducers/electricityload.reducer";
import * as LoadAction from "../core/actions/electricityload.actions";
import * as ClimateReducer from "../core/reducers/climate.reducer";
import * as ClimateAction from "../core/actions/climate.actions";
import * as DemandAction from "../core/actions/demandelectricity.actions";
import * as DemandReducer from "../core/reducers/demandelectricity.reducer";
import * as ClimateDataAction from "../core/actions/climatedata.actions";
import * as ClimateDataReducer from "../core/reducers/climatedata.reducer";
import * as ProjectTypeAction from "../core/actions/projecttype.actions";
import * as ProjectTypeReducer from "../core/reducers/projecttype.reducer";

// The top-level state object
// Uygulamaya ait reducer'lar ApplicationState interface dahil edildi. Bütün sayfalarda ApplicationState import ederek reducer'lara erişim sağlanır.
export interface ApplicationState {
  user: AuthAction.UserState | undefined;
  menus: MenusAction.MenuState | undefined;
  actions: ActionsAction.ActionState | undefined;
  gridtype: GridAction.GridState | undefined;
  designtype: DesingAction.DesignState | undefined;
  loads: LoadAction.LoadState | undefined;
  Climates: ClimateAction.ClimateState | undefined;
  demand: DemandAction.DemandState | undefined;
  ClimatesData: ClimateDataAction.ClimateDataState | undefined;
  projecttype: ProjectTypeAction.ProjectTypeState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  user: AuthReducer.reducer,
  menus: MenusReducer.reducer,
  actions: ActionsReducer.reducer,
  gridtype: GridReducer.reducer,
  designtype: DesingReducer.reducer,
  loads: LoadReducer.reducer,
  Climates: ClimateReducer.reducer,
  demand: DemandReducer.reducer,
  ClimatesData: ClimateDataReducer.reducer,
  projecttype: ProjectTypeReducer.reducer,
};
// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
