import React, { useEffect } from "react";
import {
  GBESSGridContainer,
  GBESSGridItem,
  GBESSCard,
  GBESSCardHeader,
  GBESSCardBody,
  GBESSCardFooter,
  GBESSButton,
} from "../../../components/GBESS";
import GBESSDataGrid from "./../../../components/GBESS/GBESSDataGrid";
import { IconButton } from "@material-ui/core";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { makeStyles } from "@material-ui/core/styles";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { ProjectTypeConstants } from "core/constants/renewable.constants";

const styles = {};
const useStyles = makeStyles(styles);
const EnergyConsumptionProjects = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [model, setModel] = React.useState({});
  const [projects, setProjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector((state) => state.user.user);
  const projecttype = useSelector((state) => state.projecttype.projecttype);
  const dispatch = useDispatch();

  useEffect(() => {
    getProjects(user.id);
  }, []);

  const getProjects = (id) => {
    setIsLoading(true);
    Proxy.GET(
      `/coreapi/v1.0/EnergyConsumptionProjects/GetProjectsByUserId?Id=${id}`
    )
      .withErrorAlert()
      .success((response) => {
        setProjects(response.Item);
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleNewProject = () => {
    dispatch({
      type: ProjectTypeConstants.PROJECTTYPE_CHANGE_VALUE,
      projecttype: "BEST",
    });
    props?.history?.push?.("/admin/general-information/new");
  };

  const handleOpenProject = (params) => (e) => {
    if (params?.row?.Id > 0) {
      dispatch({
        type: ProjectTypeConstants.PROJECTTYPE_CHANGE_VALUE,
        projecttype: "BEST",
      });
      props?.history?.push?.(`/admin/general-information/${params?.row?.Id}`);
    }
  };

  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 70,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="open"
              onClick={handleOpenProject(params)}
              code="GBESS-project-open"
            >
              <FileOpenIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "ProjectName",
      headerName: t("General_Information_Project_Name"),
      width: 150,
      editable: false,
    },
    {
      field: "BuildingDesc",
      headerName: t("GEN_INFO_COMPONENT_PROJECT_DESCRIPTION"),
      width: 250,
      editable: false,
    },
    {
      field: "IsClickedSaveProjectAction",
      headerName: t("Projects_Warnings"),
      width: 500,
      editable: false,
      valueGetter: (params) => {
        return `${
          params?.row?.IsClickedSaveProjectAction
            ? ""
            : t("Projects_Delete_Warning")
        }`;
      },
    },
  ];

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("Projects_Title")}
          subtitle={t("Projects_Sub_Title")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSDataGrid
                getRowId={(row) => row.Id}
                rows={projects || []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick={true}
              />
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleNewProject}
            code="energy-consumption-new-project"
          >
            {t("Projects_New_Project")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default EnergyConsumptionProjects;
