import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/icons/Menu";
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import { useRouteName } from "hooks";
import { useStatus } from "loadio";
import PropTypes from "prop-types";
import React from "react";

import AdminNavbarLinks from "./AdminNavbarLinks.js";
import RTLNavbarLinks from "./RTLNavbarLinks.js";
import { useTranslation } from "react-i18next";

// @material-ui/core components
// @material-ui/icons
// core components
//hooks
const useStyles = makeStyles(styles);

const LoadingPlaceholder = () => {
  const classes = useStyles();
  return <div className={classes.loadingPlaceholder} />;
};
function Header(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  var loadingStatus = useStatus();
  return (
    <>
      {loadingStatus.isLoading ? <LinearProgress /> : <LoadingPlaceholder />}

      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button color="transparent" href="#" className={classes.title}>
              {t(routeName)}
            </Button>
          </div>
          <Hidden smDown implementation="css">
            {props.rtlActive ? (
              <RTLNavbarLinks />
            ) : (
              <AdminNavbarLinks history={props.history} />
            )}
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    </>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
export default Header;
