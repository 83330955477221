import { Action, Reducer } from "redux";
import { ProjectTypeState, KnownAction } from "../actions/projecttype.actions";
import { ProjectTypeConstants } from "core/constants/renewable.constants";

export const reducer: Reducer<ProjectTypeState> = (
  state: ProjectTypeState | undefined,
  incomingAction: Action
): ProjectTypeState => {
  if (state === undefined) {
    return { projecttype: "BEST" };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ProjectTypeConstants.PROJECTTYPE_CHANGE_VALUE:
      return {
        projecttype: action.projecttype,
      };
    default:
      return state;
  }
};
