/*eslint-disable*/
import { Collapse } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import classNames from "classnames";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

// @material-ui/core components
// core components
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  let location = useLocation();
  const [openParentList, setOpenParentList] = React.useState([]);
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName, layout, subRouteName) {
    return (
      location.pathname === layout + routeName ||
      subRouteName?.find?.((x) => layout + x.path === location.pathname)
    );
  }
  const { color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
      {routes
        .filter((x) => x.IsParent)
        .map((prop, key) => {
          var activePro = " ";
          var listItemClasses;

          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(
              prop.path,
              prop.layout,
              routes.filter((x) => x.parent == prop.path)
            ),
          });

          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(
              prop.path,
              prop.layout,
              routes.filter((x) => x.parent == prop.path)
            ),
          });
          return (
            <React.Fragment>
              <ListItem
                style={{ display: "flex" }}
                button
                className={classes.itemLink + listItemClasses}
                onClick={() => {
                  var openList = [...openParentList];
                  if (openList.find((x) => x == prop.name)) {
                    const index = openList.indexOf(prop.name);
                    if (index > -1) {
                      openList.splice(index, 1);
                    }
                  } else {
                    openList.push(prop.name);
                  }
                  setOpenParentList(openList);
                }}
              >
                {typeof prop.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  >
                    {prop.icon}
                  </Icon>
                ) : (
                  <prop.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                )}

                <ListItemText
                  primary={t(prop.name)}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography={true}
                />

                {openParentList.find((x) => x == prop.name) ? (
                  <ExpandLess
                    className={classNames(
                      classes.itemCollapse,
                      whiteFontClasses
                    )}
                  />
                ) : (
                  <ExpandMore
                    className={classNames(
                      classes.itemCollapse,
                      whiteFontClasses
                    )}
                  />
                )}
              </ListItem>
              <Collapse
                in={openParentList.find((x) => x == prop.name) ? true : false}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" className={classes.list}>
                  {routes
                    .filter((x) => x.parent == prop.path)
                    .map((subProp, subKey) => {
                      return (
                        <NavLink
                          to={subProp.layout + subProp.path}
                          className={activePro + classes.item}
                          activeClassName="active"
                          key={key + "_" + subKey}
                        >
                          <ListItem
                            button
                            className={classNames(classes.itemLink, {
                              [listItemClasses]: activeRoute(
                                subProp.path,
                                subProp.layout,
                                routes.filter((x) => x.parent == subProp.path)
                              ),
                            })}
                          >
                            {typeof subProp.icon === "string" ? (
                              <Icon
                                className={classNames(
                                  classes.itemIcon,
                                  whiteFontClasses
                                )}
                              >
                                {subProp.icon}
                              </Icon>
                            ) : (
                              <subProp.icon
                                className={classNames(
                                  classes.itemIcon,
                                  whiteFontClasses
                                )}
                              />
                            )}
                            <ListItemText
                              primary={t(subProp.name)}
                              className={classNames(
                                classes.itemText,
                                whiteFontClasses
                              )}
                              disableTypography={true}
                            />
                          </ListItem>
                        </NavLink>
                      );
                    })}
                </List>
              </Collapse>
            </React.Fragment>
          );
        })}
    </List>
  );
  var brand = (
    // <div>
    <div className={classes.logo}>
      <a className={classNames(classes.logoLink)} target="_blank">
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks history={props.history} />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
