export enum GridConstants {
  GRID_CHANGE_VALUE = "GRID_CHANGE_VALUE",
}

export enum DesignConstants {
  DESING_CHANGE_VALUE = "DESING_CHANGE_VALUE",
}

export enum ElectriotyConstants {
  LOAD_CHANGE_VALUE = "LOAD_CHANGE_VALUE",
}

export enum DemandElectrioty {
  DEMAND_CHANGE_VALUE = "DEMAND_CHANGE_VALUE",
}

export enum ClimateConstants {
  CLIMATE_CHANGE_VALUE = "CLIMATE_CHANGE_VALUE",
}

export enum ClimateData {
  CLIMATEDATA_CHANGE_VALUE = "CLIMATEDATA_CHANGE_VALUE",
}

export enum ProjectTypeConstants {
  PROJECTTYPE_CHANGE_VALUE = "PROJECTTYPE_CHANGE_VALUE",
}
