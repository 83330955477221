import React from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import GBESSSelectInput from "./../../../components/GBESS/GBESSSelectInput";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const ActionRoleCreate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { t } = useTranslation();

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = { ActionId: false, RoleId: false };
    let result = true;
    if (model.ActionId == null || model.ActionId <= 0) {
      tempValidateModel.ActionId = true;
      result = false;
    }
    if (model.RoleId == null || model.RoleId <= 0) {
      tempValidateModel.RoleId = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/ActionRole/Insert", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("create-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.("/admin/action-role-search");
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create-action-role-mapping")}
          subtitle={"Create Action Role Mapping Page"}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Role/Search"}
                    name="RoleId"
                    renderer={(item) => {
                      return `${item.RoleName} - (${item.RoleCode})`;
                    }}
                    keySelector={"Id"}
                    value={model.RoleId}
                    valueChanged={ValueChanged}
                    labelText={t("role")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Action/Search"}
                    name="ActionId"
                    renderer={(item) => {
                      return `${item.ActionName} - (${item.ActionCode})`;
                    }}
                    keySelector={"Id"}
                    value={model.ActionId}
                    valueChanged={ValueChanged}
                    labelText={t("action")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code={"action-role-create-submit"}
          >
            {t("submit")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ActionRoleCreate;
