import React, { useEffect } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSDivider from "../../../components/GBESS/GBESSDivider";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import GBESSDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESSTabBar from "../../../components/GBESS/GBESSTabBar";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const styles = {};
const useStyles = makeStyles(styles);
const UsageProfileCreate = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activityData, setActivityData] = React.useState([
    {
      Id: 1,
      Hour: 1,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 2,
      Hour: 2,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 3,
      Hour: 3,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 4,
      Hour: 4,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 5,
      Hour: 5,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 6,
      Hour: 6,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 7,
      Hour: 7,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 8,
      Hour: 8,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 9,
      Hour: 9,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 10,
      Hour: 10,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 11,
      Hour: 11,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 12,
      Hour: 12,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 13,
      Hour: 13,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 14,
      Hour: 14,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 15,
      Hour: 15,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 16,
      Hour: 16,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 17,
      Hour: 17,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 18,
      Hour: 18,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 19,
      Hour: 19,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 20,
      Hour: 20,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 21,
      Hour: 21,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 22,
      Hour: 22,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 23,
      Hour: 23,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 24,
      Hour: 24,
      DayType: 1,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 25,
      Hour: 1,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 26,
      Hour: 2,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 27,
      Hour: 3,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 28,
      Hour: 4,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 29,
      Hour: 5,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 30,
      Hour: 6,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 31,
      Hour: 7,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 32,
      Hour: 8,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 33,
      Hour: 9,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 34,
      Hour: 10,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 35,
      Hour: 11,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 36,
      Hour: 12,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 37,
      Hour: 13,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 38,
      Hour: 14,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 39,
      Hour: 15,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 40,
      Hour: 16,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 41,
      Hour: 17,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 42,
      Hour: 18,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 43,
      Hour: 19,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 44,
      Hour: 20,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 45,
      Hour: 21,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 46,
      Hour: 22,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 47,
      Hour: 23,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
    {
      Id: 48,
      Hour: 24,
      DayType: 2,
      NumberOfPeople: 1,
      ActivityId: 1,
    },
  ]);
  const [activityList, setActivityList] = React.useState([]);
  const [model, setModel] = React.useState({});
  const [value, setTabValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasUsageProfile, setHasUsageProfile] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const projecttype = useSelector((state) => state.projecttype.projecttype);

  const objectList = [
    { id: 1, name: "Object 1", bit: true },
    { id: 2, name: "Object 2", bit: false },
    { id: 3, name: "Object 3", bit: true },
  ];

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
      getRecord(id);
    }
    setModel({ ...model });
    getActivityList();
  }, []);

  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(
      `/coreapi/v1.0/EnergyConsumptionUsageProfiles/GetById/Id?Id=${id}`
    )
      .withErrorAlert()
      .success((response) => {
        if (response.Item != null) {
          setHasUsageProfile(true);
          setModel({ ...response.Item });
          setActivityData(JSON.parse(response.Item?.ActivityContent));
        }
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const getActivityList = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/Activities/Search", {})
      .withErrorAlert()
      .success((response) => {
        if (response.Item != null) {
          setActivityList(response.Item);
        }
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handlePreviousPage = (e) => {
    props?.history?.push?.(
      `/admin/electrical-equipment-search/${model.ProjectId}`
    );
  };

  const columns = [
    {
      field: t("hour"),
      headerName: "Hour",
      width: 150,
      editable: false,
      valueGetter: (params) => {
        return `${params?.row?.Hour ?? ""}`;
      },
    },
    {
      field: "ActivityId",
      headerName: t("activity"),
      width: 300,
      editable: true,
      type: "singleSelect",
      valueOptions: activityList?.map((post) => post.Description),
      valueGetter: (params) => {
        return `${
          activityList
            .filter((post) => post.Id === params?.row?.ActivityId)
            ?.pop()?.Description ?? ""
        }`;
      },
    },
    {
      field: "NumberOfPeople",
      headerName: t("number-of-people"),
      width: 150,
      editable: true,
      type: "number",
      valueGetter: (params) => {
        return `${params?.row?.NumberOfPeople ?? ""}`;
      },
    },
  ];

  const handleEditRowsModelChange = React.useCallback((model) => {
    const editedIds = Object.keys(model);
    if (editedIds.length != 0) {
      const tempActivityData = [...activityData];
      const updatedValue = tempActivityData.find((x) => x.Id == editedIds[0]);
      if (updatedValue) {
        if (model[editedIds[0]].ActivityId != undefined) {
          updatedValue["ActivityId"] = activityList
            .filter(
              (post) =>
                post.Description === model[editedIds[0]].ActivityId.value
            )
            ?.pop()?.Id;
        }

        if (model[editedIds[0]].NumberOfPeople != undefined) {
          updatedValue["NumberOfPeople"] =
            model[editedIds[0]].NumberOfPeople.value;
        }
      }
      setActivityData(tempActivityData);
    }
  });

  const tabColumns = [
    {
      index: 0,
      tabText: t("weekday"),
      context: (
        <GBESSDataGrid
          name="waterConsumptionDataGrid"
          getRowId={(row) => row.Id}
          rows={activityData.filter((post) => post.DayType === 1)}
          columns={columns}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          onEditRowsModelChange={handleEditRowsModelChange}
        />
      ),
    },
    {
      index: 1,
      tabText: t("weekend"),
      context: (
        <GBESSDataGrid
          name="waterConsumptionDataGrid"
          getRowId={(row) => row.Id}
          rows={activityData.filter((post) => post.DayType === 2)}
          columns={columns}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          onEditRowsModelChange={handleEditRowsModelChange}
        />
      ),
    },
  ];

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };

  const ActivityDataValueChanged = (name, value, id) => {
    const tempActivity = [...activityData];
    const updatedValue = tempActivity.find((x) => x.Id == id);
    if (updatedValue) {
      updatedValue[name] = value;
    }
    setActivityData(tempActivity);
  };

  const handleSubmit = (e) => {
    model.ActivityContent = JSON.stringify(activityData);
    setModel({ ...model });

    let tempValidateModel = {
      ProjectId: false,
      ActivityContent: false,
    };
    let result = true;
    if (model.ProjectId == null || model.ProjectId <= 0) {
      tempValidateModel.ProjectId = true;
      result = false;
    }
    if (model.ActivityContent == null || model.ActivityContent == "") {
      tempValidateModel.ActivityContent = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      if (hasUsageProfile == true) {
        Proxy.POST("/coreapi/v1.0/EnergyConsumptionUsageProfiles/Update", model)
          .withErrorAlert()
          .success((response) => {
            AlertHelper.show("success", "Success", "Update Successfully!");
            console.log("ProjectType => " + projecttype);
            if (projecttype === "GEST") {
              props?.history?.push?.(
                `/admin/electrioty-load/${model.ProjectId}`
              );
            } else {
              props?.history?.push?.(`/admin/results/${model.ProjectId}`);
            }
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      } else {
        Proxy.POST("/coreapi/v1.0/EnergyConsumptionUsageProfiles/Insert", model)
          .withErrorAlert()
          .success((response) => {
            AlertHelper.show("success", "Success", "Create Successfully!");
            if (projecttype === "GEST") {
              props?.history?.push?.(
                `/admin/electrioty-load/${model.ProjectId}`
              );
            } else {
              props?.history?.push?.(`/admin/results/${model.ProjectId}`);
            }
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      }
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create-usage-profile")}
          subtitle={t("create-usage-profile-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={6}>
                  <GBESSTabBar
                    value={value}
                    valueChanged={(newValue) => {
                      setTabValue(newValue);
                    }}
                    dataSource={tabColumns}
                    onFinish={() => handleSubmit()}
                  ></GBESSTabBar>
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={handlePreviousPage}
                    code={"usage-profile-previous"}
                  >
                    {t("previous_page")}
                  </GBESSButton>
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter></GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default UsageProfileCreate;
