import React from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const RolesCreate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { t } = useTranslation();

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = { RoleCode: false, RoleName: false };
    let result = true;
    if (model.RoleCode == null || model.RoleCode == "") {
      tempValidateModel.RoleCode = true;
      result = false;
    }
    if (model.RoleName == null || model.RoleName == "") {
      tempValidateModel.RoleName = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/Role/Insert", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.("/admin/roles-search");
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create-roles")}
          subtitle={t("create-role-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="RoleName"
                    error={modelValidate.RoleName}
                    value={model.RoleName}
                    valueChanged={ValueChanged}
                    labelText={t("role-name")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="RoleCode"
                    error={modelValidate.RoleCode}
                    value={model.RoleCode}
                    valueChanged={ValueChanged}
                    labelText={t("role-code")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="roles-create-submit"
          >
            {t("submit")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default RolesCreate;
