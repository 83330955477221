import React from "react";
import "./EvaluationTable.css";

const PassiveHouseTable = ({ criteria }) => {
  return (
    <div className="evaluation-table">
      <h6></h6>
      <table>
        <thead>
          <tr>
            <th>PASSSIVE HOUSE ANALYZE</th>
            <th>Analyze Result Of The Model</th>
            <th>Unit</th>
            <th>PH Requirements</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Total annual energy consumption for heating and cooling purposes
            </td>
            <td>{criteria.TotalHeatingCoolingPurposes}</td>
            <td> kWh/(m^2-year) </td>
            <td> ≤ 15 </td>
          </tr>
          <tr>
            <td>
              Instant highest energy consumption for heating and cooling
              purposes
            </td>
            <td>{criteria.InstantHeatingCoolingPurposes}</td>
            <td> kWh/m^2 </td>
            <td> ≤ 10 </td>
          </tr>
          <tr>
            <td>Electricity produced with HYES</td>
            <td>{criteria.HYES}</td>
            <td> kWh/(m^2-year) </td>
            <td></td>
          </tr>
          <tr>
            <td>Air tightness rate</td>
            <td>{criteria.AirTightnessRate}</td>
            <td> ACH </td>
            <td> ≤ 0.6 </td>
          </tr>
          <tr>
            <td>Thermal comfort</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p> {criteria.PassiveHouseResult}</p>
    </div>
  );
};

export default PassiveHouseTable;
