import GBESCard from "../../../components/GBESS/GBESSCard";
import GBESCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESLabel from "../../../components/GBESS/GBESSLabel";
import GBESGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESButton from "../../../components/GBESS/GBESSButton";
import GBESDivider from "../../../components/GBESS/GBESSDivider";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESTabBar from "../../../components/GBESS/GBESSTabBar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const styles = {};
const useStyles = makeStyles(styles);
const RenewableResult = (props) => {
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const classes = useStyles();
  const demand = useSelector((state) => state.demand.demand);
  const climatedata = useSelector((state) => state.ClimatesData.ClimatesData);
  const renewInfo = useSelector((state) => state.gridtype.gridtype);
  const designtype = useSelector((state) => state.designtype.designtype);
  const projecttype = useSelector((state) => state.projecttype.projecttype);

  const [modelValidate, setModelValidate] = React.useState({});
  const [modelPVCalculator, setmodelPvCalculator] = React.useState({});
  const [modelWindCalculator, setmodelWindCalculator] = React.useState({});

  const [panelTypeList, setPanelTypeList] = React.useState([]);
  const [value, setValue] = React.useState();
  const [panelRows, setPanelRows] = React.useState([]);
  const [windRows, setWindRows] = React.useState([]);
  const [optimumRows, setOptimumRows] = React.useState([]);
  const [calcOptimumLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();

  const [modelOptimization, setmodelOptimization] = React.useState({});
  const [panelSelectedRows, setPanelSelectedRows] = React.useState([]);
  const [windSelectedRows, setWindSelectedRows] = React.useState([]);
  const [projectModel, setProjectModel] = React.useState({});

  React.useEffect(() => {
    projectModel.UserId = user.id;
    if (id > 0) {
      projectModel.ProjectId = id;
      setProjectModel({ ...projectModel });
      getRecord(id);
    }
  }, []);

  const getRecord = (id) => {
    setIsLoading(true);
    if (id > 0) {
      var apiResult = `/coreapi/v1.0/RenewableEnergyResult/GetById/Id?Id=${id}`;
      if (projecttype === "GEST") {
        apiResult = `/coreapi/v1.0/GestEnergyResult/GetById/Id?Id=${id}`;
      }
      Proxy.GET(apiResult)
        .withErrorAlert()
        .success((response) => {
          setmodelOptimization({
            ...JSON.parse(response.Item?.OptimizationData),
          });
          setmodelWindCalculator({
            ...JSON.parse(response.Item?.WindData),
          });
          setmodelPvCalculator({
            ...JSON.parse(response.Item?.PVData),
          });
          setOptimumRows(JSON.parse(response.Item?.OptimizationResultList));
          setPanelRows(JSON.parse(response.Item?.PanelResultList));
          setWindRows(JSON.parse(response.Item?.WindResultList));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {});
    }
    setIsLoading(false);
  };

  const columnsPanel = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "panelname",
      headerName: t("panel_set_name"),
      width: 150,
      editable: true,
    },
    {
      field: "wattvalue",
      headerName: t("kWh/year"),
      width: 150,
      editable: true,
    },
  ];

  const columnsWind = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "windname",
      headerName: t("wind_set_name"),
      width: 150,
      editable: true,
    },
    {
      field: "wattvalue",
      headerName: t("kWh/year"),
      width: 150,
      editable: true,
    },
  ];

  const downloadPanelPower = () => {
    // Seçili satırları kontrol et
    if (panelSelectedRows.length === 0) {
      AlertHelper.show("error", "Error", "Please select at least one row!");
      return;
    }
    // Seçili satırların güç bilgilerini bir metin olarak topla
    const powerInfoText = panelSelectedRows
      .map((rowIndex) => {
        const row = panelRows[rowIndex - 1];

        // powervalues dizisini alt alta yazdır
        const powerValuesText = row.powervalues
          .map((value, index) => `Panel Power (watt) ${index + 1}: ${value}`)
          .join("\n");

        return `Panel Set Name: ${row.panelname}, Total Power (watt): ${row.wattvalue}, Count: ${row.powervalues.length}\n${powerValuesText}`;
      })
      .join("\n\n");

    // Metni bir dosyaya yazdır
    const element = document.createElement("a");
    const file = new Blob([powerInfoText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "panel_power_info.txt";
    document.body.appendChild(element); // Firefox için gerekli
    element.click();
  };

  const downloadWindPower = () => {
    // Seçili satırları kontrol et
    if (windSelectedRows.length === 0) {
      AlertHelper.show("error", "Error", "Please select at least one row!");
      return;
    }
    // Seçili satırların güç bilgilerini bir metin olarak topla
    const windInfoText = windSelectedRows
      .map((rowIndex) => {
        const row = windRows[rowIndex - 1];

        // powervalues dizisini alt alta yazdır
        const windValuesText = row.powervalues
          .map((value, index) => `Wind Power (watt) ${index + 1}: ${value}`)
          .join("\n");

        return `Wind Set Name: ${row.windname}, Total Power (watt): ${row.wattvalue}, Count: ${row.powervalues.length}\n${windValuesText}`;
      })
      .join("\n\n");

    // Metni bir dosyaya yazdır
    const element = document.createElement("a");
    const file = new Blob([windInfoText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "wind_power_info.txt";
    document.body.appendChild(element); // Firefox için gerekli
    element.click();
  };

  const AddPanelData = (name, value) => {
    var rowscount = panelRows.length + 1;
    var newList = panelRows.concat({
      id: rowscount,
      panelname: name || "", // panelname özelliğini doğru şekilde tanımla
      wattvalue: value.Value,
      powervalues: value.PowerValues,
    });
    setPanelRows(newList);
  };

  const AddWindData = (name, value) => {
    var rowscount = windRows.length + 1;
    var newList = windRows.concat({
      id: rowscount,
      windname: name || "",
      wattvalue: value.Value,
      powervalues: value.PowerValues,
    });
    setWindRows(newList);
  };

  const handlePreviousPage = (e) => {
    props?.history?.push?.(`/admin/renewable-system/${projectModel.ProjectId}`);
  };

  return (
    <GBESCard isLoading={calcOptimumLoading}>
      <GBESCardHeader title={t("renewable_energy_system_result")} />
      <GBESCardBody>
        <GBESGridContainer xs={12}>
          <GBESGridItem xs={12} sm={12} md={6}>
            <GBESGridContainer xs={12}>
              <GBESLabel bold text={t("panel_result")} />
              <GBESDataGrid
                rows={panelRows}
                columns={columnsPanel}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(newSelection) =>
                  setPanelSelectedRows(newSelection)
                } // Seçili satırları tutmak için bir state güncelleyicisi
                disableSelectionOnClick
              />
              <GBESButton onClick={() => downloadPanelPower()}>
                {t("download")}
              </GBESButton>
              <GBESDivider />
            </GBESGridContainer>
          </GBESGridItem>
        </GBESGridContainer>
        <GBESGridContainer xs={12}>
          <GBESGridItem xs={12} sm={12} md={6}>
            <GBESLabel bold text={t("wind_result")} />
            <GBESDataGrid
              rows={windRows}
              columns={columnsWind}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              onSelectionModelChange={(newSelection) =>
                setWindSelectedRows(newSelection)
              }
              disableSelectionOnClick
            />
            <GBESButton onClick={() => downloadWindPower()}>
              {t("download")}
            </GBESButton>
            <GBESDivider />
          </GBESGridItem>
        </GBESGridContainer>
        <GBESButton onClick={handlePreviousPage}>Previous Page</GBESButton>
      </GBESCardBody>
      <GBESCardFooter></GBESCardFooter>
    </GBESCard>
  );
};

export default RenewableResult;
