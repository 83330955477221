import * as React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthHelper from "../../core/helpers/AuthHelper";

export default function PrivateRoute({ component: Component, ...rest }) {
  const user = useSelector((state) => state.user.user);

  const menus = useSelector((state) => state.menus.menus);

  const authIsValid =
    rest.path === "/signup"
      ? true
      : user === null
      ? false
      : user.token !== "" && AuthHelper.checkTokenIsValid()
      ? true
      : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        authIsValid ? (
          menus?.find?.((x) => "/admin/" + x.MenuCode == rest.path) ||
          rest.path === "/admin" ||
          rest.path === "/signup" ? (
            <Component {...props} />
          ) : (
            () => {
              return <>PAGE NOT FOUND ...</>;
            }
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
