import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import GBESCard from "../../../components/GBESS/GBESSCard";
import GBESCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESLabel from "../../../components/GBESS/GBESSLabel";
import GBESGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESButton from "../../../components/GBESS/GBESSButton";
import GBESDivider from "../../../components/GBESS/GBESSDivider";
import Proxy from "core/Proxy";
import AlertHelper from "../../../core/helpers/AlertHelper";
import {
  GridConstants,
  DesignConstants,
  ClimateConstants,
  ClimateData,
} from "core/constants/renewable.constants";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const styles = {};
const useStyles = makeStyles(styles);

const GeneralInformation = (props) => {
  const { t } = useTranslation();
  const climates = useSelector((state) => state.Climates.Climates);
  const climatedata = useSelector((state) => state.ClimatesData.ClimatesData);
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const renewInfo = useSelector((state) => state.gridtype.gridtype);
  const designtype = useSelector((state) => state.designtype.designtype);
  const [model, setModel] = useState({});
  const [RenewInfo, setRenewInfo] = useState(renewInfo);
  const [DesingType, setDesingType] = useState(designtype);
  const [WeatherData, setWeatherData] = useState("");
  const [WeatherDataButton, setWeatherDataButton] = useState("");
  const [modelValidate, setModelValidate] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (Object.keys(climates).length === 0 && climates.constructor === Object) {
      setWeatherData(t("location"));
      setWeatherDataButton("UPLOAD Weather Data");
    } else {
      setWeatherData(
        "Continent = " +
          climates.Continent +
          "\t\tCountry = " +
          climates.Country +
          "\t\tCity =  " +
          climates.City
      );
      setWeatherDataButton("DELETE Weather Data");
    }
  }, [climates]);

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      getRecord(id);
    } else {
      ValueChanged("GridType", "OnGrid");
      ValueChanged("DesingType", "Optimization");
    }
    setModel({ ...model });
  }, []);

  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/RenewableEnergyProjects/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        ValueChanged("GridType", response.Item.GridType);
        ValueChanged("DesingType", response.Item.DesingType);
        setModel({ ...response.Item });

        if (response.Item.EpwData !== null && response.Item.EpwData !== "") {
          PostClimate(response.Item.EpwData);
        } else {
          renewInfo["City"] = response.Item.CityId;
          setRenewInfo({ ...renewInfo });
          dispatch({
            type: GridConstants.GRID_CHANGE_VALUE,
            gridtype: { ...renewInfo },
          });
        }
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const ValueChanged = (name, value) => {
    modelValidate[name] = false;
    model[name] = value;
    if (name === "CityId") {
      renewInfo["City"] = value;
      setRenewInfo({ ...renewInfo });
      dispatch({
        type: GridConstants.GRID_CHANGE_VALUE,
        gridtype: { ...renewInfo },
      });
    } else if (name === "GridType") {
      renewInfo["GridType"] = value;
      setRenewInfo({ ...renewInfo });
      dispatch({
        type: GridConstants.GRID_CHANGE_VALUE,
        gridtype: { ...renewInfo },
      });
    } else if (name === "DesingType") {
      dispatch({
        type: DesignConstants.DESING_CHANGE_VALUE,
        designtype: value,
      });
      setDesingType(value);
      // If "Power Plant Design" is selected, set grid type to "On-Grid"
      if (value === "Exist") {
        ValueChanged("GridType", "OnGrid");
      }
    }
    setModel({ ...model });
  };

  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/plain") {
      try {
        const content = await readFileAsync(file);
        PostClimate(content);
      } catch (error) {
        AlertHelper.show("error", "Error", "Error reading file.");
      }
    } else {
      AlertHelper.show("warning", "Warning", "Please upload a .txt file.");
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const triggerFileInput = () => {
    if (WeatherDataButton === "DELETE Weather Data") {
      dispatch({
        type: ClimateConstants.CLIMATE_CHANGE_VALUE,
        Climates: {},
      });
      dispatch({
        type: ClimateData.CLIMATEDATA_CHANGE_VALUE,
        ClimatesData: "",
      });
    } else {
      fileInputRef.current.click();
    }
  };

  const PostClimate = (value) => {
    Proxy.POST("/coreapi/v1.0/Optimization/ClimateData", {
      Climate: value,
    })
      .withErrorAlert()
      .success((response) => {
        dispatch({
          type: ClimateData.CLIMATEDATA_CHANGE_VALUE,
          ClimatesData: value,
        });
        ValueChanged("EpwData", value);
        dispatch({
          type: ClimateConstants.CLIMATE_CHANGE_VALUE,
          Climates: response.Item,
        });
        AlertHelper.show(
          "success",
          t("success"),
          "File Uploaded Successfully!"
        );
      });
  };

  const handleGoToNextPage = () => {
    let result = true;
    let tempValidateModel = { ProjectName: false };
    if (model.ProjectName == null || model.ProjectName == "") {
      tempValidateModel.ProjectName = true;
      result = false;
    } else if (model.CityId == null || model.CityId == "") {
      if (
        Object.keys(climates).length === 0 &&
        climates.constructor === Object
      ) {
        result = false;
      }
    }
    setModelValidate({ ...tempValidateModel });
    if (RenewInfo.GridType <= 0) {
      AlertHelper.show(
        "warning",
        "Warning",
        "Please fill the Grid Type in required!"
      );
      return;
    }
    if (result) {
      setIsLoading(true);
      if (model.Id != null && model.Id > 0) {
        Proxy.POST("/coreapi/v1.0/RenewableEnergyProjects/Update", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show("success", t("success"), t("update-successfully"));
            if (DesingType === "Optimization") {
              props?.history?.push?.(
                `/admin/electrioty-load/${model.ProjectId}`
              );
            } else {
              props?.history?.push?.(
                `/admin/renewable-system/${model.ProjectId}`
              );
            }
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      } else {
        Proxy.POST("/coreapi/v1.0/RenewableEnergyProjects/Insert", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show(
              "success",
              t("success"),
              t("insert-successfully!")
            );
            if (DesingType === "Optimization") {
              props?.history?.push?.(
                `/admin/electrioty-load/${model.ProjectId}`
              );
            } else {
              props?.history?.push?.(
                `/admin/renewable-system/${model.ProjectId}`
              );
            }
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      }
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };

  return (
    <GBESCard isLoading={isLoading}>
      <GBESCardHeader
        title={t("Renewable_Information")}
        subtitle={t("define_renewable_information")}
      />
      <GBESCardBody>
        <GBESGridContainer xs={12}>
          <GBESGridItem xs={12} sm={12} md={6}>
            <GBESGridContainer xs={12}>
              <GBESLabel bold text={t("information")} />
              <GBESTextInput
                name="ProjectName"
                value={model.ProjectName}
                valueChanged={ValueChanged}
                labelText={t("project-name")}
                error={modelValidate.ProjectName}
              />
              <GBESTextInput
                name="ProjectDesc"
                value={model.ProjectDesc}
                valueChanged={ValueChanged}
                rows={3}
                labelText={t("project_description")}
              />
              <GBESDivider />
              <GBESLabel bold text={WeatherData} />
              {/* <GBESButton onClick={triggerFileInput}>
                {WeatherDataButton}
              </GBESButton> */}
              <GBESGridItem xs={12} sm={12} md={12}>
                <GBESSelectInput
                  url={"/coreapi/v1.0/Continent/Search"}
                  name="ContinentId"
                  renderer={(item) => {
                    return `${item.ContinentName}`;
                  }}
                  keySelector={"Id"}
                  value={model.ContinentId}
                  valueChanged={ValueChanged}
                  labelText={t("continent")}
                  isDisabled={WeatherDataButton === "DELETE Weather Data"}
                />
              </GBESGridItem>

              <GBESGridItem xs={12} sm={12} md={12}>
                <GBESSelectInput
                  url={"/coreapi/v1.0/Country/Search"}
                  name="CountryId"
                  renderer={(item) => {
                    return `${item.CountryName}`;
                  }}
                  keySelector={"Id"}
                  value={model.CountryId}
                  valueChanged={ValueChanged}
                  parameter={{ ContinentId: model.ContinentId }}
                  labelText={t("country")}
                  isDisabled={WeatherDataButton === "DELETE Weather Data"}
                />
              </GBESGridItem>

              <GBESGridItem xs={12} sm={12} md={12}>
                <GBESSelectInput
                  url={"/coreapi/v1.0/City/Search"}
                  name="CityId"
                  renderer={(item) => {
                    return `${item.CityName}`;
                  }}
                  keySelector={"Id"}
                  value={model.CityId}
                  valueChanged={ValueChanged}
                  parameter={{ CountryId: model.CountryId }}
                  labelText={t("city")}
                  isDisabled={WeatherDataButton === "DELETE Weather Data"}
                />
              </GBESGridItem>
              <GBESDivider />
              <GBESLabel bold text={t("select_system_type")} />
              <GBESSelectInput
                labelText={t("select_design")}
                name="DesingType"
                value={DesingType}
                keySelector="Id"
                textSelector="Label"
                valueChanged={ValueChanged}
                data={[
                  { Id: "Exist", Label: t("power_plant_design") },
                  { Id: "Optimization", Label: t("optimum_design_zeb") },
                ]}
              />
              <GBESSelectInput
                labelText={t("select_grid_type")}
                name="GridType"
                value={RenewInfo.GridType}
                valueChanged={ValueChanged}
                defaultValue={"OnGrid"}
                keySelector="Id"
                textSelector="Label"
                data={[
                  { Id: "OnGrid", Label: t("on-grid") },
                  { Id: "OffGrid", Label: t("off-grid") },
                ]}
                isDisabled={DesingType === "Exist"}
              />
            </GBESGridContainer>
          </GBESGridItem>
        </GBESGridContainer>
      </GBESCardBody>
      <GBESCardFooter>
        <GBESButton onClick={(e) => handleGoToNextPage()}>
          {t("next")}
        </GBESButton>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".txt"
        />
      </GBESCardFooter>
    </GBESCard>
  );
};
export default GeneralInformation;
