import { makeStyles } from "@material-ui/core";
import Proxy from "core/Proxy";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Select from "react-select";

import GBESSGridContainer from "./GBESSGridContainer";
import GBESSGridItem from "./GBESSGridItem";
import GBESSLabel from "./GBESSLabel";
import _ from "lodash";
import { useTranslation } from "react-i18next";
const equal = require("fast-deep-equal");

// import styles from "assets/jss/material-dashboard-react/components/cardFooterStyle.js";
const styles = {
  label: {
    alignSelf: "center",
  },
  select: {
    margin: "5px 0",
    // height: 10
  },
};
const useStyles = makeStyles(styles);

//const parameterSearchUrl = "coreapi/v1.0/Parameter/Search";

function GBESSSelectInput({
  parameterSearchUrl,
  name,
  value,
  valueChanged,
  defaultValue,
  data: staticData,
  keySelector,
  textSelector,
  isMulti,
  url,
  parameterCode,
  parameter,
  renderer,
  isClearable,
  labelMd,
  labelText,
  methodType,
  isDisabled,
  ...rest
}) {
  const classes = useStyles();
  const [mappedData, setMappedData] = React.useState([]);
  const [remoteData, setRemoteData] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const isStaticData = !url && !parameterCode;
  const data = isStaticData ? staticData : remoteData;
  const { t } = useTranslation();

  const valueChangedFunc = (name, value, object) => {
    valueChanged && valueChanged(name, value, object);
  };

  const onChangeFunction = (newValue, params) => {
    const selectedData = data.find(
      (item) => _.get(item, keySelector) == newValue?.value
    );
    switch (params?.action) {
      case "clear":
        valueChangedFunc(name, -2, null);
        break;
      case "select-option":
        valueChangedFunc(
          name,
          isMulti === true ? newValue?.map?.((x) => x?.value) : newValue?.value,
          selectedData
        );
        break;
      case "create-option": //Creatable
        break;
      case "deselect-option": //Multiple
        break;
      case "remove-value": //Multiple
        break;
      case "pop-value":
        break;
      case "set-value":
        break;
    }
    const selectedValue = mappedData.find((item) => item.value == value);
    setSelectedValue(selectedValue);
  };

  useEffect(() => {
    if (value !== selectedValue) {
      const selectedValue = mappedData.find((item) => item.value == value);
      setSelectedValue(selectedValue);
    }
  }, [value]);
  useEffect(() => {
    //Generate mapped data for react-select
    const dataList = [];
    data &&
      data.forEach((item) => {
        dataList.push({
          value: _.get(item, keySelector),
          label:
            typeof renderer === "function"
              ? renderer(item)
              : item[textSelector],
        });
      });

    let selectedValue = dataList.find((item) => item.value == value);
    if (!selectedValue && value != -2 && defaultValue) {
      selectedValue = dataList.find((item) => item.value == defaultValue);
    }
    if (!equal(dataList, mappedData)) {
      setSelectedValue(selectedValue);
      setMappedData(dataList);
    }
  }, [data]);

  useEffect(() => {
    // Remote data fetch
    let requestUrl = url;
    let request = { ...parameter };
    if (parameterCode && parameterSearchUrl) {
      requestUrl = parameterSearchUrl;
      request.ParameterCode = parameterCode;
    }
    if (!requestUrl) {
      return;
    }
    setIsLoading(true);
    if (methodType == "GET") {
      Proxy.GET(requestUrl)
        .withErrorAlert()
        .success((response) => {
          setRemoteData(response.Item || []);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      Proxy.POST(requestUrl, request)
        .withErrorAlert()
        .success((response) => {
          setRemoteData(response.Item || []);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    }
  }, [url, parameter, parameterCode]);

  return (
    <GBESSGridItem xs={12}>
      <GBESSGridContainer alignItems="center" justify="center">
        <GBESSGridItem xs={labelMd}>
          <GBESSLabel {...rest} noGrid text={labelText} />
        </GBESSGridItem>
        <GBESSGridItem xs={12 - labelMd}>
          <Select
            {...rest}
            placeholder={t("select_place_holder")}
            className={classes.select}
            value={selectedValue}
            isMulti={isMulti}
            isLoading={isLoading}
            options={mappedData}
            onChange={onChangeFunction}
            isClearable={isClearable}
            isDisabled={isDisabled}
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                borderColor: "#e5e5ea",
                "&:hover": {
                  borderColor: "#e5e5ea",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#65BA69" : "#FFF",
                "&:hover": {
                  backgroundColor: state.isSelected ? "#65BA69" : "#47A34B",
                  color: "#fff",
                },
              }),
            }}
          />
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSGridItem>
  );
}

GBESSSelectInput.defaultProps = {
  keySelector: "Id",
  isClearable: true,
  isDisabled: false,
  labelMd: 5,
  methodType: "POST",
};
GBESSSelectInput.propTypes = {
  data: PropTypes.array,
  defaultValue: PropTypes.any,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  keySelector: PropTypes.string,
  labelMd: PropTypes.number,
  labelText: PropTypes.string,
  name: PropTypes.string,
  parameter: PropTypes.object,
  parameterCode: PropTypes.any,
  renderer: PropTypes.func,
  textSelector: PropTypes.string.isRequired,
  url: PropTypes.string,
  value: PropTypes.any,
  valueChanged: PropTypes.func,
  methodType: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default GBESSSelectInput;
