import React, { useEffect } from "react";

import classNames from "classnames";
import { ErrorBoundary as EB } from "react-error-boundary";
import { mapStackTrace } from "sourcemapped-stacktrace";

import { Button, Grid, Box, Typography } from "@mui/material";

const errorUrlRegex = /(.*\()(.*)(\).*)/;
function ErrorFallback({ error, resetErrorBoundary }) {
  const [mappedStackTrace, setMappedStackTrace] = React.useState([]);

  useEffect(() => {
    if (!error.stack || process.env.NODE_ENV === "production") return;
    mapStackTrace(error.stack, function (mappedStack) {
      setMappedStackTrace(mappedStack);
    });
  }, [error.stack]);
  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: "center",
          my: 4,
        }}
      >
        <Grid container>
          <Grid item lg={12}>
            <div className="text-center mb-5">
              <h1 className="display-3 font-weight-medium">Oops...</h1>
              <h4 className="text-uppercase">Something Went Wrong</h4>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
        >
          <Grid item md={12} xl={9}>
            <Box>
              <>
                <Typography variant="h5">{error.message}</Typography>
                <br />
                <pre>
                  {mappedStackTrace.map((line, index) => {
                    const match = line.match(errorUrlRegex);
                    const isPrimaryFile =
                      !line?.includes("node_modules") &&
                      line?.includes("/src/");
                    if (match?.[2]) {
                      return (
                        <div
                          key={index}
                          style={{
                            ...(isPrimaryFile
                              ? {
                                  fontWeight: "bold",
                                }
                              : {
                                  opacity: 0.7,
                                }),
                          }}
                        >
                          {match[1]}
                          <a href={`vscode://file/${match[2]}`}>{match?.[2]}</a>
                          {match[3]}
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        style={{
                          ...(isPrimaryFile
                            ? {
                                fontWeight: "bold",
                              }
                            : {
                                opacity: 0.5,
                              }),
                        }}
                      >
                        {line}
                      </div>
                    );
                  })}
                </pre>
                <Button variant="outlined" onClick={resetErrorBoundary}>
                  Reload
                </Button>
              </>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

const ErrorBoundary = ({ children }) => (
  <EB FallbackComponent={ErrorFallback}>{children}</EB>
);

export default ErrorBoundary;
