import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { successColor } from "assets/jss/material-dashboard-react";
import baseStyles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import combineStyles from "./../../core/helpers/combineStyles";
import GBESSGridContainer from "./GBESSGridContainer";
import GBESSGridItem from "./GBESSGridItem";
import GBESSLabel from "./GBESSLabel";
import Checkbox from "@mui/material/Checkbox";

// @material-ui/core components
// @material-ui/icons
// core components
const styles = {
  root: {
    margin: "0px 0px 16px 0px",
  },
  label: {
    marginTop: 16,
  },
  underline: {
    color: successColor[0],
  },
};
const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSCheckBox(props) {
  const checkBoxRef = React.useRef(null);
  const classes = useStyles();
  const {
    labelText,
    labelProps,
    inputProps,
    labelMd,
    value,
    valueChanged,
    name,
    innerRef,
  } = props;

  const ValueChanged = (e) => {
    var value = e.target.checked;
    valueChanged && valueChanged(name, value, e);
  };
  return (
    <GBESSGridItem xs={12} className={classes.root}>
      <GBESSGridContainer>
        <GBESSGridItem xs={labelMd} className={classes.label}>
          <GBESSLabel noGrid text={labelText} {...labelProps} />
        </GBESSGridItem>
        <GBESSGridItem xs={12 - labelMd}>
          <Checkbox
            {...inputProps}
            ref={innerRef}
            checked={value}
            onChange={ValueChanged}
            color="success"
          />
        </GBESSGridItem>
      </GBESSGridContainer>
    </GBESSGridItem>
  );
}
GBESSCheckBox.defaultProps = {
  fullWidth: true,
  checked: false,
  labelMd: 5,
  labelProps: {},
  inputProps: {},
};
GBESSCheckBox.propTypes = {
  disabled: PropTypes.any,
  error: PropTypes.bool,
  formControlProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  labelMd: PropTypes.number,
  labelProps: PropTypes.object,
  labelText: PropTypes.node,
  maxLength: PropTypes.any,
  minLength: PropTypes.any,
  name: PropTypes.string,
  rtlActive: PropTypes.bool,
  step: PropTypes.any,
  success: PropTypes.bool,
  value: PropTypes.bool,
  valueChanged: PropTypes.func,
};
export default GBESSCheckBox;
