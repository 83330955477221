import { Action, Reducer } from "redux";
import { GridState, KnownAction } from "../actions/grid.actions";
import { GridConstants } from "core/constants/renewable.constants";

export const reducer: Reducer<GridState> = (
  state: GridState | undefined,
  incomingAction: Action
): GridState => {
  if (state === undefined) {
    return { gridtype: { GridType: "OnGrid" } };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case GridConstants.GRID_CHANGE_VALUE:
      return {
        gridtype: action.gridtype,
      };
    default:
      return state;
  }
};
