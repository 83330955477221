import * as jwtDecode from "jwt-decode";

export default class AuthHelper {
  static getAuthToken() {
    var user = localStorage.getItem("user");
    const userCookie = JSON.parse(user);

    return userCookie?.token;
  }

  static logout() {
    localStorage.removeItem("user");
  }

  static checkTokenIsValid() {
    const token = AuthHelper.getAuthToken();
    if (token) {
      const { exp } = jwtDecode.default(token);
      if (Date.now() >= exp * 1000) {
        AuthHelper.logout();
        return false;
      }
    }
    return true;
  }
}
