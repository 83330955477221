import { store } from "react-notifications-component";
export default class AlertHelper {
  static show = (type, title, message) => {
    store.addNotification({
      type: type,
      title: title,
      message: message,
      insert: "bottom",
      container: "bottom-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__slideOutLeft"],
      dismiss: {
        duration: 3000,
        onScreen: true,
        showIcon: true,
      },
      slidingExit: {
        duration: 3000,
        timingFunction: "ease",
        delay: 10000,
      },
    });
  };
}
