import PropTypes from "prop-types";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

// nodejs library that concatenates classes
// nodejs library to set properties for components
// material-ui components
const styles = {};

function GBESSAccordion(props) {
  return (
    <Accordion>
      <AccordionSummary open={true} expandIcon={<ExpandMoreIcon />}>
        <HeartBrokenIcon />
        <Typography>{props.title}</Typography>
        <Typography> - </Typography>
        <QueryBuilderIcon />
        <Typography>{props.subtitle}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
}

GBESSAccordion.defaultProps = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
GBESSAccordion.propTypes = {};

export default GBESSAccordion;
