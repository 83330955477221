import React, { useEffect, useRef } from "react";
import GBESSGridContainer from "../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../components/GBESS/GBESSButton";
import GBESSDivider from "../../components/GBESS/GBESSDivider";
import GBESSExportDataButton from "../../components/GBESS/GBESSExportDataButton";
import GBESSLabel from "../../components/GBESS/GBESSLabel";
import AlertHelper from "../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";

const styles = {};
const useStyles = makeStyles(styles);
const FCSResults = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({
    A: 0.00003,
    B: 0.0024,
    C: 0.0077,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const [fcsResults, setFcsResults] = React.useState([]);
  const user = useSelector((state) => state.user.user);
  const fileInputRef = useRef(null);
  const itFileInputRef = useRef(null);
  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };

  useEffect(() => {
    model.UserId = user.id;
    setModel({ ...model });
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      model.EpwFile = reader.result.split(",")[1];
      model.EpwFileName = file.name;
      setModel({ ...model });
      AlertHelper.show("success", "Success", "File Upload Successfully");
    };
    reader.onerror = (error) => {
      AlertHelper.show("warning", "Warning", "File Upload Successfully", error);
    };
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleItFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      model.ItFile = reader.result.split(",")[1];
      model.ItFileName = file.name;
      setModel({ ...model });
      AlertHelper.show("success", "Success", "File Upload Successfully");
    };
    reader.onerror = (error) => {
      AlertHelper.show("warning", "Warning", "File Upload Successfully", error);
    };
  };

  const handleItButtonClick = () => {
    itFileInputRef.current.click();
  };

  const calculate = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/Fcs/Calculate", model)
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
        AlertHelper.show("success", t("success"), t("calculate-successfully"));
        if (
          response.Item?.FCSResults != null &&
          response.Item?.FCSResults.length > 0
        ) {
          setFcsResults(JSON.parse(response.Item?.FCSResults));
        }
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader title={t("results")} subtitle={t("result-page")} />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridContainer xs={12} sm={12} md={12}>
                  <GBESSGridItem
                    style={{ marginLeft: "15px" }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GBESSGridContainer xs={12} sm={12} md={12}>
                      <GBESSGridItem xs={12} sm={12} md={12}>
                        <GBESSLabel
                          style={{ marginTop: "15px" }}
                          text="EC of FCS : (a * T^2 + b * T + c) * IT"
                        />
                      </GBESSGridItem>
                      <GBESSGridItem
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GBESSTextInput
                          name="A"
                          value={model.A}
                          valueChanged={ValueChanged}
                          labelText="a"
                          type="number"
                        />
                      </GBESSGridItem>
                      <GBESSGridItem
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GBESSTextInput
                          name="B"
                          value={model.B}
                          valueChanged={ValueChanged}
                          labelText="b"
                          type="number"
                        />
                      </GBESSGridItem>
                      <GBESSGridItem
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GBESSTextInput
                          name="C"
                          value={model.C}
                          valueChanged={ValueChanged}
                          labelText="c"
                          type="number"
                        />
                      </GBESSGridItem>
                    </GBESSGridContainer>
                  </GBESSGridItem>
                </GBESSGridContainer>

                <GBESSGridContainer xs={12} sm={12} md={12}>
                  <GBESSGridItem
                    style={{ marginLeft: "15px" }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GBESSGridContainer xs={12} sm={12} md={12}>
                      <GBESSGridItem xs={12} sm={12} md={12}>
                        <GBESSLabel
                          style={{ marginTop: "15px" }}
                          text={t("fcs_upload_epw_file")}
                        />
                      </GBESSGridItem>
                      <GBESSGridItem
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GBESSButton onClick={handleButtonClick}>
                          <input
                            type="file"
                            accept=".epw"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          {t("btn_fcs_upload_epw_file")}
                        </GBESSButton>
                      </GBESSGridItem>
                      <GBESSGridItem xs={12} sm={12} md={12}>
                        <GBESSLabel
                          style={{ marginTop: "15px" }}
                          text={model.EpwFileName}
                        />
                      </GBESSGridItem>
                    </GBESSGridContainer>
                  </GBESSGridItem>
                </GBESSGridContainer>
                <GBESSGridContainer xs={12} sm={12} md={12}>
                  <GBESSGridItem
                    style={{ marginLeft: "15px" }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GBESSGridContainer xs={12} sm={12} md={12}>
                      <GBESSGridItem xs={12} sm={12} md={12}>
                        <GBESSLabel
                          style={{ marginTop: "15px" }}
                          text={t("upload_it_file")}
                        />
                      </GBESSGridItem>
                      <GBESSGridItem
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "15px",
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <GBESSButton onClick={handleItButtonClick}>
                          <input
                            type="file"
                            accept=".txt"
                            ref={itFileInputRef}
                            style={{ display: "none" }}
                            onChange={handleItFileChange}
                          />
                          {t("btn_upload_it_file")}
                        </GBESSButton>
                      </GBESSGridItem>
                      <GBESSGridItem xs={12} sm={12} md={12}>
                        <GBESSLabel
                          style={{ marginTop: "15px" }}
                          text={model.ItFileName}
                        />
                      </GBESSGridItem>
                    </GBESSGridContainer>
                  </GBESSGridItem>
                </GBESSGridContainer>

                <GBESSDivider />

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    bold
                    text={t("notes")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    text={t("fcs-note-1")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    text={t("fcs-note-2")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    text={t("fcs-note-3")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSLabel
                    style={{ marginTop: "20px" }}
                    text={t("fcs-note-4")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSGridItem
            style={{ display: "flex", justifyContent: "flex-start" }}
            xs={12}
            sm={3}
            md={3}
          >
            <GBESSButton disabled={isLoading} onClick={calculate}>
              FCS Calculate
            </GBESSButton>
          </GBESSGridItem>

          <GBESSGridItem
            style={{ display: "flex", justifyContent: "flex-center" }}
            xs={12}
            sm={12}
            md={12}
          >
            <GBESSExportDataButton
              data={fcsResults}
              excelFileName="FCSResults"
              labelText="Download FCS Data"
            />
          </GBESSGridItem>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default FCSResults;
