import { actionsConstants } from "../constants/auth.constants.core";
import { Action, Reducer } from "redux";
import { ActionState, KnownAction } from "../actions/action.actions";

export const reducer: Reducer<ActionState> = (
  state: ActionState | undefined,
  incomingAction: Action
): ActionState => {
  if (state === undefined) {
    return { actions: [] };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case actionsConstants.ACTIONS_ADD_ALL:
      return {
        actions: action.actions,
      };

    case actionsConstants.ACTIONS_REMOVE_ALL:
      return {
        actions: [],
      };
    default:
      return state;
  }
};
