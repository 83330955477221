import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import "../../assets/css/styles.css";
import { useTranslation } from "react-i18next";
import Proxy from "core/Proxy";
import AlertHelper from "../../core/helpers/AlertHelper";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/inputBorderStyle.js";
import LinearProgress from "@mui/material/LinearProgress";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
const theme = createTheme();
const useStyles = makeStyles(styles);
const Step1Schema = yup.object().shape({
  phone: yup.string().min(8),
});

export default function SignUpPage(props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const [model, setModel] = React.useState({
    TcNo: "",
    FirstName: "",
    LastName: "",
    Company: "",
    PhoneNumber: "",
    ExtensionNumber: "",
    Job: "",
    EducationalStatus: "",
    Mail: "",
    Password: "",
  });
  const { control } = useForm({
    resolver: Step1Schema,
  });

  const handleChange = (event) => {
    model[event.target.name] = event.target.value;
    setModel({ ...model });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    JSON.stringify(model);
    Proxy.POST("/coreapi/v1.0/User/Insert", model)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("sign-up-successfully!"));
        props?.history?.push?.("/login");
      })
      .error((error) => {
        AlertHelper.show(
          "warning",
          "Warning",
          error?.ErrorDescription === undefined
            ? error
            : error?.ErrorDescription
        );
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handlePhoneNumber = (value) => {
    model["PhoneNumber"] = value;
    setModel({ ...model });
  };

  const handleExtensionNumber = (value) => {
    model["ExtensionNumber"] = value;
    setModel({ ...model });
  };

  const regexNumber = (value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      return true;
    }
    return false;
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleBackIcon = (e) => {
    e.preventDefault();
    props?.history?.push?.("/login");
  };
  return (
    <ThemeProvider theme={theme}>
      <div className="login-wrapper">
        <Container
          component="main"
          maxWidth="sm"
          sx={{ bgcolor: "#fff", borderRadius: "0.5rem", overflow: "hidden" }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <LinearProgress
                className="linear-progress"
                color="success"
                style={{
                  borderRadius: "5px",
                  height: "5px",
                  overflow: "visible",
                  width: "100%",
                }}
              />
            ) : null}
            <div className="signup-avatars-content">
              <div onClick={handleBackIcon}>
                <Avatar
                  sx={{
                    bgcolor: "#4caf50",
                    marginTop: "15px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIcon />
                </Avatar>
              </div>
              <div className="second-avatar">
                <Avatar
                  sx={{
                    m: 1,
                    bgcolor: "#4caf50",
                    marginTop: "15px",
                    marginLeft: "-35px",
                  }}
                >
                  <HowToRegRoundedIcon />
                </Avatar>
              </div>
            </div>
            <Typography component="h1" variant="h5">
              {t("Sign_Up")}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit}>
                <div className="singup-row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="tcNo"
                    label={t("identity-number")}
                    name="TcNo"
                    autoComplete="tcNo"
                    autoFocus
                    onChange={(e) => {
                      regexNumber(e.target.value) ? handleChange(e) : null;
                    }}
                    className={classes.borderStyle}
                    value={model.TcNo}
                    inputProps={{
                      maxLength: 11,
                      minLength: 11,
                    }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={t("First_Name")}
                    name="FirstName"
                    autoComplete="firstName"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    sx={{
                      ml: 10,
                    }}
                    value={model.FirstName}
                  />
                </div>
                <div className="singup-row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={t("Last_Name")}
                    name="LastName"
                    autoComplete="lastName"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    value={model.LastName}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="company"
                    label={t("Company")}
                    name="Company"
                    autoComplete="company"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    sx={{
                      ml: 10,
                    }}
                    value={model.Company}
                  />
                </div>
                <div className="singup-row">
                  <Controller
                    name="PhoneNumber"
                    control={control}
                    render={({ name, value }) => (
                      <MuiPhoneNumber
                        name={name}
                        value={model.PhoneNumber}
                        onChange={handlePhoneNumber}
                        id="phoneNumber"
                        defaultCountry={"tr"}
                        style={{ width: "100%" }}
                        label={t("Phone_Number")}
                        variant="outlined"
                        margin="normal"
                        required
                        className={classes.borderStyle}
                      />
                    )}
                  />
                  <Controller
                    name="ExtensionNumber"
                    control={control}
                    render={({ name, value }) => (
                      <MuiPhoneNumber
                        name={name}
                        value={model.ExtensionNumber}
                        onChange={handleExtensionNumber}
                        id="extensionNumber"
                        defaultCountry={"tr"}
                        style={{ width: "100%", marginLeft: "80px" }}
                        label={t("Extension_Number")}
                        variant="outlined"
                        margin="normal"
                        className={classes.borderStyle}
                      />
                    )}
                  />
                </div>
                <div className="singup-row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="job"
                    label={t("Job")}
                    name="Job"
                    autoComplete="job"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    value={model.Job}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="educationalStatus"
                    label={t("Educational_Status")}
                    name="EducationalStatus"
                    autoComplete="educationalStatus"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    value={model.EducationalStatus}
                    sx={{
                      ml: 10,
                    }}
                  />
                </div>
                <div className="singup-row">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("E-mail")}
                    name="Mail"
                    autoComplete="email"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    type="email"
                    value={model.Mail}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="Password"
                    label={t("Password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                    className={classes.borderStyle}
                    sx={{
                      ml: 10,
                    }}
                    value={model.Password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Button
                  disabled={isLoading}
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{
                    mt: 3,
                    mb: 2,
                    fontSize: "1rem",
                    height: "48px",
                    backgroundColor: "#4caf50",
                    ":hover": {
                      bgcolor: "#2C9530",
                    },
                  }}
                >
                  {t("Sign_Up")}
                </Button>
              </form>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
