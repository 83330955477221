import React, { useEffect, useRef, useState } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BabylonCanvasPreview from "views/babylon/BabylonCanvasPreview";

import woodTexture from "assets/img/babylon/wood_texture.jpg";
import wallTexture from "assets/img/babylon/wall_texture.jpg";
import glassTexture from "assets/img/babylon/glass_texture.jpg";

import _ from "lodash";
import * as BABYLON from "@babylonjs/core";

function parameterValueToDirection(parameterValue) {
  let directionName;
  let direction;

  if (parameterValue === "1") {
    directionName = "west";
    direction = new BABYLON.Vector3(-1, 0, 0);
  } else if (parameterValue === "2") {
    directionName = "south";
    direction = new BABYLON.Vector3(0, 0, -1);
  } else if (parameterValue === "3") {
    directionName = "east";
    direction = new BABYLON.Vector3(1, 0, 0);
  } else if (parameterValue === "4") {
    directionName = "north";
    direction = new BABYLON.Vector3(0, 0, 1);
  } else if (parameterValue === "5") {
    directionName = "up";
    direction = new BABYLON.Vector3(0, 1, 0);
  } else if (parameterValue === "6") {
    directionName = "down";
    direction = new BABYLON.Vector3(0, -1, 0);
  }

  return { directionName, direction };
}

function getConstructionType(constructionObject) {
  let constructionType = "";
  if (constructionObject.ConstructionType.ParameterValue === "1") {
    constructionType = "door";
  } else if (constructionObject.ConstructionType.ParameterValue === "2") {
    constructionType = "window";
  } else if (constructionObject.ConstructionType.ParameterValue === "3") {
    constructionType = "other";
  } else if (constructionObject.ConstructionType.ParameterValue === "4") {
    constructionType = "wall";
  }

  return constructionType;
}

const getThicknessByType = (construction) => {
  if (construction === "door") {
    return 0.02;
  } else if (construction === "window") {
    return 0.02;
  } else if (construction === "other") {
    return 0.1;
  } else if (construction === "wall") {
    return 0.1;
  }
  return 0.05;
};
function calculateNestedExposedRelativePosition(
  construction,
  constructionList
) {
  let offsetX = 0,
    offsetY = 0;
  // let constructionType = getConstructionType(construction);
  // const zoneId = construction.ZoneId;
  const exposedZoneId = construction.ExposedToZoneId;

  if (exposedZoneId > 0) {
    const exposedConstructionList = constructionList.filter(
      (item) => item.ZoneId === exposedZoneId
    );
    const exposedGroundConstruction = exposedConstructionList.find(
      (item) =>
        parameterValueToDirection(item.Direction.ParameterValue)
          .directionName === "down"
    );
    if (exposedGroundConstruction) {
      const exposedDirectionInfo = parameterValueToDirection(
        construction.ExposedDirection.ParameterValue
      );
      if (exposedDirectionInfo.directionName === "north") {
        offsetY =
          (exposedGroundConstruction.Height / 1000 +
            construction.Height / 1000) /
          2;
      } else if (exposedDirectionInfo.directionName === "south") {
        offsetY = -(
          (exposedGroundConstruction.Height / 1000 +
            construction.Height / 1000) /
          2
        );
      } else if (exposedDirectionInfo.directionName === "east") {
        offsetX =
          (exposedGroundConstruction.Width / 1000 + construction.Width / 1000) /
          2;
      } else if (exposedDirectionInfo.directionName === "west") {
        offsetX = -(
          (exposedGroundConstruction.Width / 1000 + construction.Width / 1000) /
          2
        );
      }

      const subOffset = calculateNestedExposedRelativePosition(
        exposedGroundConstruction,
        constructionList
      );

      offsetX += subOffset.offsetX;
      offsetY += subOffset.offsetY;
    }
  }
  return { offsetX, offsetY };
}

const ConstructionPreview = (props) => {
  const buildingRef = useRef(null);
  const { id } = useParams();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [constructionData, setConstructionData] = useState([]);

  const [model, setModel] = React.useState({});
  const [reloadCounter, setReloadCounter] = React.useState(0);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (!model?.UserId) return;
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/EnergyConsumptionConstructions/Search", model)
      .withErrorAlert()
      .success((response) => {
        setConstructionData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  }, [model, reloadCounter]);

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
    }
    setModel({ ...model });
  }, []);
  useEffect(() => {
    if (!buildingRef.current) return;
    const scene = buildingRef.current.scene;
    // const engine = buildingRef.current.engine;
    const meshList = [];

    let constructionGroupByZone = _.groupBy(constructionData, "ZoneId");

    let first = true;
    for (const key in constructionGroupByZone) {
      if (first) {
        first = false;
        // continue;
      }
      let zoneConstructions = constructionGroupByZone[key];
      const zone = zoneConstructions[0].Zone;

      const groundInfo = zoneConstructions.find(
        (item) =>
          parameterValueToDirection(item.Direction.ParameterValue)
            .directionName === "down"
      );
      if (!groundInfo) continue;

      const groundWidth = groundInfo.Width / 1000;
      const groundHeight = groundInfo.Height / 1000;
      const wallHeight = zone.ZoneWallHeight / 1000;
      // const ceilingThickness =
      //   (ceilingInfo &&
      //     ceilingInfo?.ConstructionMaterial?.Materials?.Thickness / 100) ||
      //   0;

      // const groundThickness =
      //   groundInfo.ConstructionMaterial.Materials.Thickness / 100;
      const ceilingThickness = 0.05;
      const groundThickness = 0.05;
      const verticalWallThickness = ceilingThickness + groundThickness;

      var zoneTransformNode = new BABYLON.TransformNode("zoneTransformNode");

      const zoneOffsetInfo = calculateNestedExposedRelativePosition(
        groundInfo,
        constructionData
      );

      zoneTransformNode.position = new BABYLON.Vector3(
        zoneOffsetInfo.offsetX + groundInfo.OffsetX / 1000,
        0,
        zoneOffsetInfo.offsetY + groundInfo.OffsetY / 1000
      );

      for (const item of zoneConstructions) {
        const directionInfo = parameterValueToDirection(
          item.Direction.ParameterValue
        );

        // const constructionThickness =
        //   item.ConstructionMaterial.Materials.Thickness / 100;
        const constructionThickness = 0.05;
        const constructionType = getConstructionType(item);

        let material = new BABYLON.StandardMaterial("material", scene);
        material.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        material.specularColor = new BABYLON.Color3(0, 0, 0);
        material.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        material.ambientColor = new BABYLON.Color3(0.23, 0.98, 0.53);
        material.diffuseTexture = new BABYLON.Texture(wallTexture, scene);
        material.alpha = 1;

        const doorMaterial = new BABYLON.StandardMaterial(
          "doorMaterial",
          scene
        );
        doorMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        doorMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        doorMaterial.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        doorMaterial.ambientColor = new BABYLON.Color3(0.23, 0.98, 0.53);
        doorMaterial.diffuseTexture = new BABYLON.Texture(woodTexture, scene);
        doorMaterial.alpha = 1;

        const glassMaterial = new BABYLON.StandardMaterial(
          "glassMaterial",
          scene
        );
        glassMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        glassMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        glassMaterial.emissiveColor = new BABYLON.Color3(0.5, 0.5, 0.5);
        glassMaterial.ambientColor = new BABYLON.Color3(0.23, 0.98, 0.53);
        glassMaterial.diffuseTexture = new BABYLON.Texture(glassTexture, scene);
        glassMaterial.alpha = 0.5;

        const boxMaterial =
          constructionType === "door"
            ? doorMaterial
            : constructionType === "window"
            ? glassMaterial
            : material;
        let box;
        if (directionInfo.directionName === "north") {
          if (constructionType === "door" || constructionType === "window") {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: item.Height / 1000,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              item.OffsetX / 1000,
              item.Height / 1000 / 2 - groundThickness + item.OffsetY / 1000,
              groundHeight / 2 + constructionThickness
            );
          } else {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: wallHeight - verticalWallThickness,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              0.0,
              wallHeight / 2 - ceilingThickness,
              groundHeight / 2 - constructionThickness
            );
          }
          box.material = boxMaterial;
          box.rotation = new BABYLON.Vector3(0, 0, 0);
          box.scaling = new BABYLON.Vector3(1, 1, 1);
        } else if (directionInfo.directionName === "south") {
          if (constructionType === "door" || constructionType === "window") {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: item.Height / 1000,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              item.OffsetX / 1000,
              item.Height / 1000 / 2 - groundThickness + item.OffsetY / 1000,
              -(groundHeight / 2 + constructionThickness)
            );
          } else {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: wallHeight - verticalWallThickness,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              0.0,
              wallHeight / 2 - ceilingThickness,
              -(groundHeight / 2 - constructionThickness)
            );
          }
          box.material = boxMaterial;
          box.rotation = new BABYLON.Vector3(0, 0, 0);
          box.scaling = new BABYLON.Vector3(1, 1, 1);
        } else if (directionInfo.directionName === "east") {
          if (constructionType === "door" || constructionType === "window") {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: item.Height / 1000,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            // box.position = new BABYLON.Vector3(
            //   -((item.Width/1000 - constructionThickness) / 2),
            //   (item.Height/1000 - groundThickness) / 2,
            //   0
            // );
            box.position = new BABYLON.Vector3(
              groundWidth / 2 + constructionThickness + item.OffsetX / 1000,
              item.Height / 1000 / 2 - groundThickness + item.OffsetY / 1000,
              0
            );
          } else {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: wallHeight - verticalWallThickness,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              groundWidth / 2 - constructionThickness,
              wallHeight / 2 - ceilingThickness,
              0
            );
          }
          box.material = boxMaterial;
          //turn right to 90 degree
          box.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
          box.scaling = new BABYLON.Vector3(1, 1, 1);
        } else if (directionInfo.directionName === "west") {
          if (constructionType === "door" || constructionType === "window") {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: item.Height / 1000,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            // box.position = new BABYLON.Vector3(
            //   -((item.Width/1000 - constructionThickness) / 2),
            //   (item.Height/1000 - groundThickness) / 2,
            //   0
            // );
            box.position = new BABYLON.Vector3(
              -(groundWidth / 2 + constructionThickness),
              item.Height / 1000 / 2 - groundThickness + item.OffsetY / 1000,
              item.OffsetX / 1000
            );
          } else {
            box = BABYLON.MeshBuilder.CreateBox(
              "box",
              {
                height: wallHeight - verticalWallThickness,
                width: item.Width / 1000,
                // depth: item.ConstructionMaterial.Materials.Thickness / 100,
                depth: getThicknessByType(constructionType),
              },
              scene
            );

            box.position = new BABYLON.Vector3(
              -(groundWidth / 2 - constructionThickness),
              wallHeight / 2 - ceilingThickness,
              0
            );
          }
          box.material = boxMaterial;
          //turn right to 90 degree
          box.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
          box.scaling = new BABYLON.Vector3(1, 1, 1);
        } else if (directionInfo.directionName === "up") {
          box = BABYLON.MeshBuilder.CreateBox(
            "box",
            {
              height: item.Height / 1000,
              width: item.Width / 1000,
              // depth: item.ConstructionMaterial.Materials.Thickness / 100,
              depth: getThicknessByType(constructionType),
            },
            scene
          );
          box.material = material;
          box.position = new BABYLON.Vector3(
            0,
            wallHeight - verticalWallThickness / 2,
            0
          );
          box.scaling = new BABYLON.Vector3(1, 1, 1);
          //rotate X axis -90 degree to make it horizontal
          box.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
        } else if (directionInfo.directionName === "down") {
          box = BABYLON.MeshBuilder.CreateBox(
            "box",
            {
              height: item.Height / 1000,
              width: item.Width / 1000,
              // depth: item.ConstructionMaterial.Materials.Thickness / 100,
              depth: getThicknessByType(constructionType),
            },
            scene
          );
          box.material = material;
          box.position = new BABYLON.Vector3(0, 0, 0);
          box.scaling = new BABYLON.Vector3(1, 1, 1);
          //rotate X axis -90 degree to make it horizontal
          box.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
        }
        box.parent = zoneTransformNode;
        meshList.push(box);
      }
    }
    return () => {
      meshList?.forEach((mesh) => {
        mesh?.dispose();
      });
    };
  }, [constructionData]);

  const handleGoToSearch = () => {
    props?.history?.push?.(
      `/admin/building-construction-search/${model.ProjectId}`
    );
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("construction-preview")}
          subtitle={"Construction Preview"}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem
                  style={{ display: "flex", justifyContent: "flex-start" }}
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <GBESSButton
                    disabled={isLoading}
                    onClick={() => setReloadCounter(reloadCounter + 1)}
                    code={"zone-construction-search-previous"}
                  >
                    Reload
                  </GBESSButton>
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>

            <GBESSGridItem xs={12} sm={12} md={12}>
              <BabylonCanvasPreview ref={buildingRef} />
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ConstructionPreview;
