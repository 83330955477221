import { Action, Reducer } from "redux";
import { DesignState, KnownAction } from "../actions/design.actions";
import {
  DesignConstants,
  GridConstants,
} from "core/constants/renewable.constants";

export const reducer: Reducer<DesignState> = (
  state: DesignState | undefined,
  incomingAction: Action
): DesignState => {
  if (state === undefined) {
    return { designtype: "Optimization" };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case DesignConstants.DESING_CHANGE_VALUE:
      return {
        designtype: action.designtype,
      };
    default:
      return state;
  }
};
