import React from "react";
import "./EvaluationTable.css";
import { useTranslation } from "react-i18next";

const EvaluationTable = ({ criteria }) => {
  const { t } = useTranslation();
  return (
    <div className="evaluation-table">
      <h6>
        {criteria.ZoneName} {t("zone_evaluation")}
      </h6>
      <table>
        <thead>
          <tr>
            <th>{t("criteria")}</th>
            <th>{t("heating_season")}</th>
            <th>{t("cooling_season")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("indoor_temperature")}</td>
            <td>{criteria.HeatingSeason.IndoorTemperature}</td>
            <td>{criteria.CoolingSeason.IndoorTemperature}</td>
          </tr>
          <tr>
            <td>{t("external_wall_thermal_conductivity")}</td>
            <td>{criteria.HeatingSeason.ExternalWallThermalConductivity}</td>
            <td>{criteria.CoolingSeason.ExternalWallThermalConductivity}</td>
          </tr>
          <tr>
            <td>{t("window_to_wall_ratio")}</td>
            <td>{criteria.HeatingSeason.WindowToWallRatio}</td>
            <td>{criteria.CoolingSeason.WindowToWallRatio}</td>
          </tr>
          <tr>
            <td>{t("natural_lighting")}</td>
            <td>{criteria.HeatingSeason.NaturalLighting}</td>
            <td>{criteria.CoolingSeason.NaturalLighting}</td>
          </tr>
          <tr>
            <td>{t("average")}</td>
            <td>{criteria.HeatingSeason.Average}</td>
            <td>{criteria.CoolingSeason.Average}</td>
          </tr>
        </tbody>
      </table>
      <p>
        {t("overall_score")}: {criteria.OverallScore}
      </p>
    </div>
  );
};

export default EvaluationTable;
