import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);

const CommunityCreate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const user = useSelector((state) => state.user.user);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      getRecord(id);
    }
    setModel({ ...model });
  }, []);

  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/CommunityProjects/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };

  const handleSubmit = (e) => {
    let tempValidateModel = {
      CommunityName: false,
      ReferenceBuildingProjectId: false,
    };

    let result = true;
    if (model.CommunityName == null || model.CommunityName == "") {
      tempValidateModel.CommunityName = true;
      result = false;
    }

    if (
      model.ReferenceBuildingProjectId == null ||
      model.ReferenceBuildingProjectId == ""
    ) {
      tempValidateModel.ReferenceBuildingProjectId = true;
      result = false;
    }

    setModelValidate({ ...tempValidateModel });
    if (result) {
      if (model.Id != null && model.Id > 0) {
        Proxy.POST("/coreapi/v1.0/CommunityProjects/Update", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show("success", t("success"), t("update-successfully"));
            props?.history?.push?.(
              `/admin/community-buildings-search/${model.Id}`
            );
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      } else {
        Proxy.POST("/coreapi/v1.0/CommunityProjects/Insert", model)
          .withErrorAlert()
          .success((response) => {
            model.ProjectId = response?.Item?.Id;
            setModel({ ...model });
            AlertHelper.show(
              "success",
              t("success"),
              t("insert-successfully!")
            );
            props?.history?.push?.(
              `/admin/community-buildings-search/${model.Id}`
            );
          })
          .error((error) => {
            AlertHelper.show("warning", "Warning", error?.ErrorDescription);
          })
          .continueWith(() => {
            setIsLoading(false);
          });
      }
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("Community_Create_Page_Title")}
          subtitle={t("Community_Create_Page_Sub_Title")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="CommunityName"
                    error={modelValidate.CommunityName}
                    value={model.CommunityName}
                    valueChanged={ValueChanged}
                    labelText={t("Community_Project_Name")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/EnergyConsumptionProjects/Search"}
                    name="ReferenceBuildingProjectId"
                    renderer={(item) => {
                      return `${item.ProjectName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ReferenceBuildingProjectId}
                    valueChanged={ValueChanged}
                    labelText={t("Community_Reference_Building")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="community-create-submit"
          >
            {t("next")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default CommunityCreate;
