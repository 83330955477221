import React, { useEffect, useRef, useState } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import GBESSLabel from "../../../components/GBESS/GBESSLabel";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import GBESSSelectInput from "../../../components/GBESS/GBESSSelectInput";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { East, North, South, West } from "@mui/icons-material";
import { VerticalAlignBottom, VerticalAlignTop } from "@material-ui/icons";
export function renderDirection(item) {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {item.ParameterValue === "1" ? (
        <West />
      ) : item.ParameterValue === "2" ? (
        <South />
      ) : item.ParameterValue === "3" ? (
        <East />
      ) : item.ParameterValue === "4" ? (
        <North />
      ) : item.ParameterValue === "5" ? (
        <VerticalAlignTop />
      ) : item.ParameterValue === "6" ? (
        <VerticalAlignBottom />
      ) : (
        ""
      )}
      {item.ParameterDesc}
    </Box>
  );
}

const ZoneConstructionMapCreate = (props) => {
  const buildingRef = useRef(null);
  const { id } = useParams();

  const { t } = useTranslation();
  const [model, setModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modelValidate, setModelValidate] = useState({});
  const [previewState, setPreviewState] = useState({
    width: 1,
    height: 0,
    thickness: 1,
    x: 0,
    y: 0,
    z: 0,
    direction: "north",
  });
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    model.UserId = user.id;
    if (id > 0) {
      model.ProjectId = id;
    }
    setModel({ ...model });
  }, []);

  const ValueChanged = (name, value, obj) => {
    model[name] = value;
    if (name === "ZoneId") {
      setPreviewState((prev) => {
        return {
          ...prev,
          height: obj.ZoneWallHeight,
        };
      });
    } else if (name === "DirectionId") {
      setPreviewState((prev) => {
        return {
          ...prev,
          direction:
            obj.ParameterValue === "1"
              ? "west"
              : obj.ParameterValue === "2"
              ? "south"
              : obj.ParameterValue === "3"
              ? "east"
              : obj.ParameterValue === "4"
              ? "north"
              : obj.ParameterValue === "5"
              ? "up"
              : obj.ParameterValue === "6"
              ? "down"
              : "",
        };
      });
    } else if (name === "ConstructionTypeId") {
      setPreviewState((prev) => {
        return {
          ...prev,
          type:
            obj.ParameterDesc === "Door"
              ? "door"
              : obj.ParameterDesc === "Window"
              ? "window"
              : "box",
          thickness:
            obj.ParameterDesc === "Door"
              ? 0.25
              : obj.ParameterDesc === "Window"
              ? 0.1
              : obj.ParameterDesc === "Other"
              ? 1
              : 1,
          // ...(obj.ParameterDesc !== "Window" && { height: 1, width: 1 }),
        };
      });
    } else if (name === "Width") {
      setPreviewState((prev) => {
        return {
          ...prev,
          width: value,
        };
      });
    } else if (name === "Height") {
      setPreviewState((prev) => {
        return {
          ...prev,
          height: value,
        };
      });
    }
    setModel({ ...model });
  };

  useEffect(() => {
    if (buildingRef.current) {
      buildingRef.current.setShapes([previewState]);
    }
  }, [previewState]);

  const handleSubmit = (e) => {
    let tempValidateModel = { MenuId: false, RoleId: false };
    let result = true;
    // if (model.ZoneId == null || model.ZoneId <= 0) {
    //   tempValidateModel.ZoneId = true;
    //   result = false;
    // }
    // if (model.DirectionId == null || model.DirectionId <= 0) {
    //   tempValidateModel.DirectionId = true;
    //   result = false;
    // }
    // if (model.Width == null || model.Width == "") {
    //   tempValidateModel.Width = true;
    //   result = false;
    // }
    // if (model.Height == null || model.Height == "") {
    //   tempValidateModel.Height = true;
    //   result = false;
    // }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      if (model.ExposedToId != 10066) {
        model.ExposedToZoneId = 0;
      }
      if (model.ConstructionTypeId != 10067) {
        model.riId = 0;
        model.gtId = 0;
      }

      Proxy.POST("/coreapi/v1.0/EnergyConsumptionConstructions/Insert", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("insert-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.(
      `/admin/building-construction-search/${model.ProjectId}`
    );
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("create-menu-role-mapping")}
          subtitle={"Create Menu Role Mapping Page"}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/EnergyConsumptionZones/Search"}
                    name="ZoneId"
                    renderer={(item) => {
                      return `${item.ZoneName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ZoneId}
                    valueChanged={ValueChanged}
                    labelText={t("zone")}
                  />
                </GBESSGridItem>

                {/* {model.ExposedToId == 10066 && ( */}
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("direction")}
                    name="DirectionId"
                    value={model.DirectionId}
                    error={modelValidate.DirectionId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="Direction"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                    renderer={renderDirection}
                  />
                </GBESSGridItem>
                {/* )} */}
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText="ConstructionType"
                    name="ConstructionTypeId"
                    value={model.ConstructionTypeId}
                    error={modelValidate.ConstructionTypeId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ConstructionType"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Constructions/Search"}
                    name="ConstructionId"
                    value={model.ConstructionId}
                    valueChanged={ValueChanged}
                    labelText={t("construction")}
                    keySelector="Id"
                    textSelector="ConstructionName"
                  />
                </GBESSGridItem>

                {1 == 2 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      url={"/coreapi/v1.0/ConstructionMaterials/Search"}
                      name="ConstructionMaterialId"
                      renderer={(item) => {
                        return `${item.Construction?.ConstructionName}`;
                      }}
                      keySelector={"Id"}
                      value={model.ConstructionMaterialId}
                      valueChanged={ValueChanged}
                      labelText={t("construction")}
                      Visibility
                    />
                  </GBESSGridItem>
                ) : null}

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSSelectInput
                    labelText={t("exposed-to")}
                    name="ExposedToId"
                    value={model.ExposedToId}
                    error={modelValidate.ExposedToId}
                    parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                    parameterCode="ExposedTo"
                    valueChanged={ValueChanged}
                    keySelector="Id"
                    textSelector="ParameterDesc"
                  />
                </GBESSGridItem>

                {model.ExposedToId == 10066 && (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("exposed-direction")}
                      name="ExposedDirectionId"
                      value={model.ExposedDirectionId}
                      // error={modelValidate.ExposedDirectionId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="Direction"
                      renderer={renderDirection}
                      valueChanged={ValueChanged}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                )}

                {model.ExposedToId == 10066 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      url={"/coreapi/v1.0/EnergyConsumptionZones/Search"}
                      name="ExposedToZoneId"
                      renderer={(item) => {
                        return `${item.ZoneName}`;
                      }}
                      keySelector={"Id"}
                      value={model.ExposedToZoneId}
                      valueChanged={ValueChanged}
                      labelText={t("zone")}
                    />
                  </GBESSGridItem>
                ) : null}

                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Width"
                    error={modelValidate.Width}
                    value={model.Width}
                    valueChanged={ValueChanged}
                    labelText={t("width")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="Height"
                    error={modelValidate.Height}
                    value={model.Height}
                    valueChanged={ValueChanged}
                    labelText={t("height")}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="OffsetX"
                    error={modelValidate.OffsetX}
                    value={model.OffsetX || 0}
                    valueChanged={ValueChanged}
                    labelText={"Distance from Origin to the Right (mm)"}
                    type="number"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={12} md={12}>
                  <GBESSTextInput
                    name="OffsetY"
                    error={modelValidate.OffsetY}
                    value={model.OffsetY || 0}
                    valueChanged={ValueChanged}
                    labelText={"Distance from Origin to the Ground (mm)"}
                    type="number"
                  />
                </GBESSGridItem>
                {model.ConstructionTypeId == 10067 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("the-ghosting-factor")}
                      name="riId"
                      value={model.riId}
                      error={modelValidate.riId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="riType"
                      valueChanged={ValueChanged}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                ) : null}

                {model.ConstructionTypeId == 10067 ? (
                  <GBESSGridItem xs={12} sm={12} md={12}>
                    <GBESSSelectInput
                      labelText={t("the-solar-transmission-factor")}
                      name="gtId"
                      value={model.gtId}
                      error={modelValidate.gtId}
                      parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                      parameterCode="gtType"
                      valueChanged={ValueChanged}
                      keySelector="Id"
                      textSelector="ParameterDesc"
                    />
                  </GBESSGridItem>
                ) : null}
                {/* <GBESSGridItem xs={12} sm={12} md={12}>
                  <BabylonCanvas ref={buildingRef} />
                </GBESSGridItem> */}
                <GBESSLabel
                  bold
                  style={{ marginTop: "15px" }}
                  text={t("the-constructions-you-have")}
                />
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="zone-construction-create-submit"
          >
            {t("submit")}
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ZoneConstructionMapCreate;
