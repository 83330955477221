import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Şifreyi sıfırlamak için gönderilen kodun süresini gösteren component
export const Timer = () => {
  const { i18n } = useTranslation();

  const [time, setTime] = useState(180);
  useEffect(() => {
    const interval = setInterval(() => {
      if (time !== 0) setTime((time) => time - 1);
    }, 1000);
    return () => clearInterval(interval);
  });
  if (i18n.language === "en") {
    if (time === 0) return <h5>Your code expired please get new code.</h5>;
    return <h5>Your code will expired in {time} seconds.</h5>;
  }
  if (i18n.language === "tr") {
    if (time === 0)
      return <h5>Kodunuzun süresi doldu, lütfen yeni kod alın.</h5>;
    return <h5>Kodunuzun süresi {time} saniye içinde dolacak.</h5>;
  }
  return null;
};
