import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@mui/material/LinearProgress";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-react/components/cardStyle.js";

const useStyles = makeStyles(styles);
function GBESSCard(props) {
  const classes = useStyles();
  const { className, children, plain, profile, chart, isLoading, ...rest } =
    props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {isLoading === true && (
        <LinearProgress
          color="success"
          style={{ borderRadius: "5px", height: "7px" }}
        />
      )}
      {children}
    </div>
  );
}

GBESSCard.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  isLoading: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node,
};

export default GBESSCard;
