import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import baseStyles from "assets/jss/material-dashboard-react/components/buttonStyle.js";
import classNames from "classnames";
import combineStyles from "core/helpers/combineStyles";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from "xlsx";

// nodejs library that concatenates classes
// nodejs library to set properties for components
// material-ui components
const styles = {
  "success-outline": {
    ...baseStyles.success,
    // borderColor: '#5eb562',
    // borderWidth: 3,
    // borderStyle: 'solid',
    // color: '#000000DE',
    // backgroundColor: '#F0F0F0'
  },
};

const useStyles = makeStyles(combineStyles(baseStyles, styles));

function GBESSExportDataButton(props) {
  const classes = useStyles();
  const {
    labelText,
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    isAuthDisabled,
    data,
    excelFileName,
    code,
    ...rest
  } = props;
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
    [classes["success-outline"]]: color === "success-outline",
  });
  const downloadExcel = async () => {
    const worksheet = XLSX.utils.json_to_sheet(props.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datas");
    XLSX.writeFile(workbook, props.excelFileName + ".xlsx");
  };
  const actions = useSelector((state) => state.actions.actions);
  const [isDisabled, setDisabled] = React.useState(true);
  React.useEffect(() => {
    const isActionValid = actions.find((x) => x.ActionCode === props.code);
    if (props.code == null) {
      setDisabled(false);
    } else {
      setDisabled(!isActionValid);
    }
  }, [actions]);
  return (
    <Button
      {...rest}
      classes={muiClasses}
      className={btnClasses}
      disabled={
        disabled === true
          ? disabled
          : isAuthDisabled === false
          ? isDisabled
          : disabled
      }
      onClick={(e) => {
        e.preventDefault();
        downloadExcel();
      }}
    >
      <RiFileExcel2Line /> <span> {props.labelText} </span>
    </Button>
  );
}

GBESSExportDataButton.defaultProps = {
  color: "success-outline",
  isAuthDisabled: false,
};
GBESSExportDataButton.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "success-outline",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent",
  ]),
  disabled: PropTypes.bool,
  justIcon: PropTypes.bool,
  link: PropTypes.bool,
  muiClasses: PropTypes.object, // use this to pass the classes props from Material-UI
  round: PropTypes.bool,
  simple: PropTypes.bool,
  isAuthDisabled: PropTypes.bool,
  code: PropTypes.string,
  size: PropTypes.oneOf(["sm", "lg"]),
  data: [],
  excelFileName: PropTypes.string,
  labelText: PropTypes.string,
};

export default GBESSExportDataButton;
