import React, { useEffect } from "react";
import {
  GBESSGridContainer,
  GBESSGridItem,
  GBESSCard,
  GBESSTextInput,
  GBESSCardBody,
  GBESSSelectInput,
  GBESSDialog,
} from "../../components/GBESS";
import GBESSDataGrid from "../../components/GBESS/GBESSDataGrid";
import GBESSAccordion from "../../components/GBESS/GBESSAccordion";
import { makeStyles } from "@material-ui/core/styles";
import AlertHelper from "../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@mui/icons-material";
import GBESSCardHeader from "./../../components/GBESS/GBESSCardHeader";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const UserSearch = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [model, setModel] = React.useState({});
  const [data, setData] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(0);
  const [deleteQuestionOpen, setDeleteQuestionOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const columns = [
    {
      field: "Actions",
      headerName: t("actions"),
      width: 150,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="delete"
              onClick={handleDeleteQuestion(params)}
              code="user-search-delete"
            >
              <Delete />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={handleUpdate(params)}
              code="user-search-update"
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "TcNo",
      headerName: t("identity-number"),
      width: 180,
      editable: false,
    },
    {
      field: "FirstName",
      headerName: t("first-name"),
      width: 220,
      editable: false,
    },
    {
      field: "LastName",
      headerName: t("last-name"),
      width: 220,
      editable: false,
    },
    {
      field: "Mail",
      headerName: t("mail"),
      width: 220,
      editable: false,
    },
    {
      field: "PhoneNumber",
      headerName: "PhoneNumber",
      width: 220,
      editable: false,
    },
    {
      field: "Role",
      headerName: t("role"),
      width: 220,
      editable: false,
      valueGetter: (params) => {
        return `${params?.row?.Role?.RoleName ?? ""}`;
      },
    },
  ];
  useEffect(() => {
    //handleUserGetAll();
  }, []);
  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const handleDeleteQuestion = (record) => (e) => {
    setDeleteId(record.id);
    setDeleteQuestionOpen(true);
  };
  const handleDelete = (e) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/User/Delete/Id?Id=${deleteId}`)
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("delete-successfully"));
        handleSearch();
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
        setDeleteId(0);
        setDeleteQuestionOpen(false);
      });
  };

  const handleUpdate = (record) => (e) => {
    props?.history?.push?.(`user-detail/${record.id}`);
  };

  const handleSearch = (e) => {
    setIsLoading(true);
    Proxy.POST("/coreapi/v1.0/User/Search", model)
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleUserGetAll = (e) => {
    setIsLoading(true);
    Proxy.GET("/coreapi/v1.0/User/GetAll")
      .withErrorAlert()
      .success((response) => {
        setData(response?.Item ?? []);
        AlertHelper.show("success", t("success"), "Search Successfully!");
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteQuestion = () => {
    setDeleteQuestionOpen(false);
  };

  return (
    <>
      <GBESSDialog
        open={deleteQuestionOpen}
        title={t("delete-record")}
        content={t("are-you-sure-you")}
        handleAccept={handleDelete}
        handleReject={handleCloseDeleteQuestion}
      />
      <GBESSCard>
        <GBESSCardHeader
          title={t("search-user")}
          subtitle={t("search-user-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="TcNo"
                    value={model.TcNo}
                    valueChanged={ValueChanged}
                    labelText={t("identity-number")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSTextInput
                    name="Mail"
                    value={model.Mail}
                    valueChanged={ValueChanged}
                    labelText="E-Mail"
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Role/Search"}
                    name="RoleId"
                    keySelector={"Id"}
                    textSelector={"RoleName"}
                    value={model.RoleId}
                    valueChanged={ValueChanged}
                    labelText={t("role")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
      </GBESSCard>

      <GBESSDataGrid
        getRowId={(row) => row.Id}
        loading={isLoading}
        gridActionList={[
          {
            name: t("search"),
            onClick: handleSearch,
            code: "user-search-search",
          },
        ]}
        rows={data}
        columns={columns}
        checkboxSelection={false}
        disableSelectionOnClick={true}
      />
    </>
  );
};
export default UserSearch;
