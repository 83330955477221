import React, { useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function GBESSPopover(props) {
  const {
    onClose,
    anchorElementRef,
    helperText,
    anchorOriginVertical,
    anchorOriginHorizontal,
    transformOriginVertical,
    transformOriginHorizontal,
    open,
    anchorEl,
  } = props;

  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl || anchorElementRef.current}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal,
      }}
      onClose={onClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1 }}>{helperText}</Typography>
    </Popover>
  );
}
GBESSPopover.defaultProps = {};
GBESSPopover.propTypes = {
  onClose: PropTypes.func,
  anchorElementRef: PropTypes.any,
  helperText: PropTypes.string,
  anchorOriginVertical: PropTypes.oneOf(["top", "center", "bottom"]),
  anchorOriginHorizontal: PropTypes.oneOf(["left", "center", "right"]),
  transformOriginVertical: PropTypes.oneOf(["top", "center", "bottom"]),
  transformOriginHorizontal: PropTypes.oneOf(["left", "center", "right"]),
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
};

export default GBESSPopover;
