import {
  DemandElectrioty,
  ElectriotyConstants,
} from "../constants/renewable.constants";
import { Action, Reducer } from "redux";
import { DemandState, KnownAction } from "../actions/demandelectricity.actions";

export const reducer: Reducer<DemandState> = (
  state: DemandState | undefined,
  incomingAction: Action
): DemandState => {
  if (state === undefined) {
    return { demand: {} };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case DemandElectrioty.DEMAND_CHANGE_VALUE:
      return {
        demand: action.demand,
      };
    default:
      return state;
  }
};
