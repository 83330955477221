import React, { useEffect } from "react";
import GBESSGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESSGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSCard from "../../../components/GBESS/GBESSCard";
import GBESSTextInput from "../../../components/GBESS/GBESSTextInput";
import { makeStyles } from "@material-ui/core/styles";
import GBESSCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESSCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESSCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESSButton from "../../../components/GBESS/GBESSButton";
import AlertHelper from "../../../core/helpers/AlertHelper";
import Proxy from "core/Proxy";
import { useParams } from "react-router-dom";
import GBESSSelectInput from "./../../../components/GBESS/GBESSSelectInput";
import { useTranslation } from "react-i18next";

const styles = {};
const useStyles = makeStyles(styles);
const ConstructionMaterialsUpdate = (props) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [modelValidate, setModelValidate] = React.useState({});
  const { id } = useParams();
  const { t } = useTranslation();
  useEffect(() => {
    if (id > 0) {
      getRecord(id);
    }
  }, []);
  const ValueChanged = (name, value) => {
    model[name] = value;
    setModel({ ...model });
  };
  const getRecord = (id) => {
    setIsLoading(true);
    Proxy.GET(`/coreapi/v1.0/ConstructionMaterials/GetById/Id?Id=${id}`)
      .withErrorAlert()
      .success((response) => {
        setModel({ ...response.Item });
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      })
      .continueWith(() => {
        setIsLoading(false);
      });
  };
  const handleSubmit = (e) => {
    let tempValidateModel = {
      ConstructionId: false,
      MaterialId: false,
      Id: false,
    };
    let result = true;
    if (model.Id == null || model.Id == "" || model.Id <= 0) {
      tempValidateModel.Id = true;
      result = false;
    }
    if (model.ConstructionId == null || model.ConstructionId <= 0) {
      tempValidateModel.ConstructionId = true;
      result = false;
    }
    if (model.MaterialId == null || model.MaterialId <= 0) {
      tempValidateModel.MaterialId = true;
      result = false;
    }
    setModelValidate({ ...tempValidateModel });
    if (result) {
      setIsLoading(true);
      Proxy.POST("/coreapi/v1.0/ConstructionMaterials/Update", model)
        .withErrorAlert()
        .success((response) => {
          AlertHelper.show("success", t("success"), t("update-successfully"));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {
          setIsLoading(false);
        });
    } else {
      AlertHelper.show("warning", "Warning", t("please-fill-the-fields"));
    }
  };
  const handleGoToSearch = () => {
    props?.history?.push?.("/admin/construction-materials-search");
  };
  return (
    <>
      <GBESSCard isLoading={isLoading}>
        <GBESSCardHeader
          title={t("update-construction-material")}
          subtitle={t("update-construction-material-page")}
        />
        <GBESSCardBody>
          <GBESSGridContainer>
            <GBESSGridItem xs={12} sm={12} md={12}>
              <GBESSGridContainer>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Constructions/Search"}
                    name="ConstructionId"
                    renderer={(item) => {
                      return `${item.ConstructionName}`;
                    }}
                    keySelector={"Id"}
                    value={model.ConstructionId}
                    valueChanged={ValueChanged}
                    labelText={t("construction-name")}
                  />
                </GBESSGridItem>
                <GBESSGridItem xs={12} sm={6} md={6}>
                  <GBESSSelectInput
                    url={"/coreapi/v1.0/Materials/Search"}
                    name="MaterialId"
                    renderer={(item) => {
                      return `${item.MaterialName}`;
                    }}
                    keySelector={"Id"}
                    value={model.MaterialId}
                    valueChanged={ValueChanged}
                    labelText={t("material-name")}
                  />
                </GBESSGridItem>
              </GBESSGridContainer>
            </GBESSGridItem>
          </GBESSGridContainer>
        </GBESSCardBody>
        <GBESSCardFooter>
          <GBESSButton
            disabled={isLoading}
            onClick={handleGoToSearch}
            isAuthDisabled={true}
          >
            {t("Go_To_Search_Page")}
          </GBESSButton>
          <GBESSButton
            disabled={isLoading}
            onClick={handleSubmit}
            code="construction-materials-edit-update"
          >
            Update
          </GBESSButton>
        </GBESSCardFooter>
      </GBESSCard>
    </>
  );
};
export default ConstructionMaterialsUpdate;
