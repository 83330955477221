import { menusConstants } from "../constants/auth.constants.core";
import { Action, Reducer } from "redux";
import { MenuState, KnownAction } from "../actions/menus.actions";

export const reducer: Reducer<MenuState> = (
  state: MenuState | undefined,
  incomingAction: Action
): MenuState => {
  if (state === undefined) {
    return { menus: [] };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case menusConstants.MENUS_ADD_ALL:
      return {
        menus: action.menus,
      };

    case menusConstants.MENUS_REMOVE_ALL:
      return {
        menus: [],
      };
    default:
      return state;
  }
};
