import {
  ClimateConstants,
  ClimateData,
} from "../constants/renewable.constants";
import { Action, Reducer } from "redux";
import { ClimateDataState, KnownAction } from "../actions/climatedata.actions";

export const reducer: Reducer<ClimateDataState> = (
  state: ClimateDataState | undefined,
  incomingAction: Action
): ClimateDataState => {
  if (state === undefined) {
    return { ClimatesData: {} };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ClimateData.CLIMATEDATA_CHANGE_VALUE:
      return {
        ClimatesData: action.ClimatesData,
      };
    default:
      return state;
  }
};
