import { ClimateConstants } from "../constants/renewable.constants";
import { Action, Reducer } from "redux";
import { ClimateState, KnownAction } from "../actions/climate.actions";

export const reducer: Reducer<ClimateState> = (
  state: ClimateState | undefined,
  incomingAction: Action
): ClimateState => {
  if (state === undefined) {
    return { Climates: {} };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ClimateConstants.CLIMATE_CHANGE_VALUE:
      return {
        Climates: action.Climates,
      };
    default:
      return state;
  }
};
