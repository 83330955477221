import GBESCard from "../../../components/GBESS/GBESSCard";
import GBESCardBody from "../../../components/GBESS/GBESSCardBody";
import GBESCardFooter from "../../../components/GBESS/GBESSCardFooter";
import GBESCardHeader from "../../../components/GBESS/GBESSCardHeader";
import GBESGridContainer from "../../../components/GBESS/GBESSGridContainer";
import GBESLabel from "../../../components/GBESS/GBESSLabel";
import GBESGridItem from "../../../components/GBESS/GBESSGridItem";
import GBESSelectInput from "../../../components/GBESS/GBESSSelectInput";
import GBESTextInput from "../../../components/GBESS/GBESSTextInput";
import GBESButton from "../../../components/GBESS/GBESSButton";
import GBESDivider from "../../../components/GBESS/GBESSDivider";
import Proxy from "core/Proxy";
import React, { useEffect } from "react";
import AlertHelper from "../../../core/helpers/AlertHelper";
import GBESDataGrid from "../../../components/GBESS/GBESSDataGrid";
import GBESTabBar from "../../../components/GBESS/GBESSTabBar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const styles = {};
const useStyles = makeStyles(styles);
const RenewableSystem = (props) => {
  const user = useSelector((state) => state.user.user);
  const { id } = useParams();
  const classes = useStyles();
  const demand = useSelector((state) => state.demand.demand);
  const climatedata = useSelector((state) => state.ClimatesData.ClimatesData);
  const renewInfo = useSelector((state) => state.gridtype.gridtype);
  const designtype = useSelector((state) => state.designtype.designtype);
  const projecttype = useSelector((state) => state.projecttype.projecttype);

  const [modelValidate, setModelValidate] = React.useState({});
  const [modelPVCalculator, setmodelPvCalculator] = React.useState({});
  const [modelWindCalculator, setmodelWindCalculator] = React.useState({});

  const [panelTypeList, setPanelTypeList] = React.useState([]);
  const [value, setValue] = React.useState();
  const [panelRows, setPanelRows] = React.useState([]);
  const [windRows, setWindRows] = React.useState([]);
  const [optimumRows, setOptimumRows] = React.useState([]);
  const [calcOptimumLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();

  const [modelOptimization, setmodelOptimization] = React.useState({});
  const [panelSelectedRows, setPanelSelectedRows] = React.useState([]);
  const [windSelectedRows, setWindSelectedRows] = React.useState([]);
  const [projectModel, setProjectModel] = React.useState({});

  React.useEffect(() => {
    optimizationhandleChange("AnnualDiscountRate", 6);
    optimizationhandleChange("PvCost", 960);
    optimizationhandleChange("PvMaintenanceCost", 25);
    optimizationhandleChange("PvPrice", 0.000073);
    optimizationhandleChange("WtCost", 1300);
    optimizationhandleChange("WtMaintenanceCost", 25);
    optimizationhandleChange("WtPrice", 0.000133);
    optimizationhandleChange("SystemLifetime", 25);
    optimizationhandleChange("BatteryCost", 436);
    optimizationhandleChange("DGCost", 125);

    optimizationhandleChange("AnnualCapacity", 20);
    optimizationhandleChange("MinPVCapacity", 0);
    optimizationhandleChange("MaxPVCapacity", 300);
    optimizationhandleChange("MinWTCapacity", 0);
    optimizationhandleChange("MaxWTCapacity", 100);
    optimizationhandleChange("MaxTotalCost", 500000);

    pvhandleChange("PVPower", 400);
    pvhandleChange("PVAngle", 35);
    pvhandleChange("PVArea", 1.9361);
    pvhandleChange("PVEfficiency", 20);
    pvhandleChange("PVCount", 1155);
    pvhandleChange("PVOrientetion", "South");

    windhandleChange("WindArea", 50);
    windhandleChange("HubHeightWind", 10);
    windhandleChange("WindPowerDensity", 3);
    windhandleChange("RatedPower", 2500);
    windhandleChange("CutInWindSpeed", 3);
    windhandleChange("CutOffWindSpeed", 25);
    windhandleChange("WindCount", 1);

    projectModel.UserId = user.id;
    if (id > 0) {
      projectModel.ProjectId = id;
      setProjectModel({ ...projectModel });
      getRecord(id);
    }
  }, []);

  const getRecord = (id) => {
    setIsLoading(true);
    if (id > 0) {
      var apiResult = `/coreapi/v1.0/RenewableEnergyResult/GetById/Id?Id=${id}`;
      if (projecttype === "GEST") {
        apiResult = `/coreapi/v1.0/GestEnergyResult/GetById/Id?Id=${id}`;
      }
      Proxy.GET(apiResult)
        .withErrorAlert()
        .success((response) => {
          setmodelOptimization({
            ...JSON.parse(response.Item?.OptimizationData),
          });
          setmodelWindCalculator({
            ...JSON.parse(response.Item?.WindData),
          });
          setmodelPvCalculator({
            ...JSON.parse(response.Item?.PVData),
          });
          setOptimumRows(JSON.parse(response.Item?.OptimizationResultList));
          setPanelRows(JSON.parse(response.Item?.PanelResultList));
          setWindRows(JSON.parse(response.Item?.WindResultList));
        })
        .error((error) => {
          AlertHelper.show("warning", "Warning", error?.ErrorDescription);
        })
        .continueWith(() => {});
    }
    setIsLoading(false);
  };

  const finish = () => {
    var apiResult = "/coreapi/v1.0/RenewableEnergyResult/Insert";
    if (projecttype === "GEST") {
      apiResult = "/coreapi/v1.0/GestEnergyResult/Insert";
    }
    Proxy.POST(apiResult, {
      OptimizationData: JSON.stringify(modelOptimization),
      PVData: JSON.stringify(modelPVCalculator),
      WindData: JSON.stringify(modelWindCalculator),
      PanelResultList: JSON.stringify(panelRows),
      WindResultList: JSON.stringify(windRows),
      OptimizationResultList: JSON.stringify(optimumRows),
      ProjectId: projectModel.ProjectId,
      UserId: projectModel.UserId,
    })
      .withErrorAlert()
      .success((response) => {
        AlertHelper.show("success", t("success"), t("update-successfully"));
        if (projecttype === "GEST")
          props?.history?.push?.(`/admin/results/${projectModel.ProjectId}`);
        else if (designtype !== "Optimization")
          props?.history?.push?.(
            `/admin/renewable-system-result/${projectModel.ProjectId}`
          );
      })
      .error((error) => {
        AlertHelper.show("warning", "Warning", error?.ErrorDescription);
      });
  };

  const optimizationhandleChange = (name, value, data) => {
    modelValidate[name] = false;
    modelOptimization[name] = value;
    setmodelOptimization({ ...modelOptimization });
  };

  const pvhandleChange = (name, value, data) => {
    modelValidate[name] = false;
    modelPVCalculator[name] = value;
    setmodelPvCalculator({ ...modelPVCalculator });
  };

  const windhandleChange = (name, value, data) => {
    modelValidate[name] = false;
    modelWindCalculator[name] = value;
    setmodelWindCalculator({ ...modelWindCalculator });
  };

  const TabValueChanged = (value) => {
    setValue(value);
  };

  const columnsPanel = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "panelname",
      headerName: t("panel_set_name"),
      width: 150,
      editable: true,
    },
    {
      field: "wattvalue",
      headerName: t("kWh/year"),
      width: 150,
      editable: true,
    },
    {
      field: "co2",
      headerName: t("co2_emission"),
      width: 270,
      editable: true,
    },
  ];

  const columnsWind = [
    { field: "id", headerName: `ID`, width: 90 },
    {
      field: `windname`,
      headerName: t(`wind_set_name`),
      width: 150,
      editable: true,
    },
    {
      field: "wattvalue",
      headerName: t("kWh/year"),
      width: 150,
      editable: true,
    },
    {
      field: "co2",
      headerName: t("co2_emission"),
      width: 270,
      editable: true,
    },
  ];

  const columnsBattery = [
    { field: `id`, headerName: `ID`, width: 90 },
    {
      field: `bname`,
      headerName: `Name of Battery Set`,
      width: 160,
      editable: true,
    },
    {
      field: `bc`,
      headerName: `Battery Count`,
      width: 150,
      editable: true,
    },
    {
      field: `ct`,
      headerName: `Connection Type`,
      width: 150,
      editable: true,
    },
    {
      field: `dod`,
      headerName: `Depth of Charge (DoD)`,
      width: 180,
      editable: true,
    },
  ];

  const columnsPVController = [
    { field: `id`, headerName: `ID`, width: 90 },
    {
      field: `pname`,
      headerName: `Name of PV Controller Set`,
      width: 190,
      editable: true,
    },
    {
      field: `pcc`,
      headerName: `PV Controller Count`,
      width: 150,
      editable: true,
    },
    {
      field: `ct`,
      headerName: `Connection Type`,
      width: 150,
      editable: true,
    },
  ];

  const columnsInverter = [
    { field: `id`, headerName: `ID`, width: 90 },
    {
      field: `iname`,
      headerName: `Name of Inverter Set`,
      width: 190,
      editable: true,
    },
    {
      field: `ie`,
      headerName: `Inverter Efficiency`,
      width: 150,
      editable: true,
    },
  ];

  const columnsOptimum = [
    { field: `id`, headerName: `ID`, width: 50 },
    {
      field: `lcoe`,
      headerName: `${t("minumum_lcoe")}`,
      width: 125,
      editable: true,
    },
    {
      field: `rf`,
      headerName: `${t("maximum_rf")}`,
      width: 120,
      editable: true,
    },
    {
      field: `pv`,
      headerName: `${t("pv_capacity")} (kW)`,
      width: 125,
      editable: true,
    },
    {
      field: `wt`,
      headerName: `${t("wt_capacity")} (kW)`,
      width: 125,
      editable: true,
    },
    {
      field: `td`,
      headerName: `${t(`total_demand`)}(kW)`,
      width: 150,
      editable: true,
    },
    {
      field: `fe`,
      headerName: `${t(`final_electricity_gen`)} (kW)`,
      width: 150,
      editable: true,
    },
    {
      field: `tcost`,
      headerName: `${t(`total_cost`)} ($)`,
      width: 150,
      editable: true,
    },
  ];

  const CalculatePv = () => {
    let result = true;
    let climateResult = controlClimateData();
    if (
      modelPVCalculator.PVName === null ||
      modelPVCalculator.PVName === `` ||
      modelPVCalculator.PVName === undefined
    ) {
      modelValidate[`PVName`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVOrientetion === null ||
      modelPVCalculator.PVOrientetion === `` ||
      modelPVCalculator.PVOrientetion === undefined
    ) {
      modelValidate[`PVOrientetion`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVPower === null ||
      modelPVCalculator.PVPower === `` ||
      modelPVCalculator.PVPower === undefined
    ) {
      modelValidate[`PVPower`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVAngle === null ||
      modelPVCalculator.PVAngle === `` ||
      modelPVCalculator.PVAngle === undefined
    ) {
      modelValidate[`PVAngle`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVArea === null ||
      modelPVCalculator.PVArea === `` ||
      modelPVCalculator.PVArea === undefined
    ) {
      modelValidate[`PVArea`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVEfficiency === null ||
      modelPVCalculator.PVEfficiency === `` ||
      modelPVCalculator.PVEfficiency === undefined
    ) {
      modelValidate[`PVEfficiency`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelPVCalculator.PVCount === null ||
      modelPVCalculator.PVCount === `` ||
      modelPVCalculator.PVCount === undefined
    ) {
      modelValidate[`PVCount`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (result && climateResult) {
      Proxy.POST(`/coreapi/v1.0/Panel/CalculatePV`, modelPVCalculator)
        .withErrorAlert()
        .success((response) => {
          if (response.Item === null || response.Item === undefined) {
            AlertHelper.show(`danger`, `Error`, `PV CALCULATE ERROR!`);
          } else {
            AlertHelper.show(
              `success`,
              t(`success`),
              `PV CALCULATE Successfully!`
            );
            AddPanelData(modelPVCalculator[`PVName`], response.Item);
          }
        });
    } else {
      AlertHelper.show(`warning`, `Warning`, t(`please-fill-the-fields`));
    }
  };

  const CalculateWind = () => {
    let result = true;
    let climateResult = controlClimateData();
    if (
      modelWindCalculator.WindName === null ||
      modelWindCalculator.WindName === `` ||
      modelWindCalculator.WindName === undefined
    ) {
      modelValidate[`WindName`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.WindCount === null ||
      modelWindCalculator.WindCount === `` ||
      modelWindCalculator.WindCount === undefined
    ) {
      modelValidate[`WindCount`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.WindPowerDensity === null ||
      modelWindCalculator.WindPowerDensity === `` ||
      modelWindCalculator.WindPowerDensity === undefined
    ) {
      modelValidate[`WindPowerDensity`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.HubHeightWind === null ||
      modelWindCalculator.HubHeightWind === `` ||
      modelWindCalculator.HubHeightWind === undefined
    ) {
      modelValidate[`HubHeightWind`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.WindArea === null ||
      modelWindCalculator.WindArea === `` ||
      modelWindCalculator.WindArea === undefined
    ) {
      modelValidate[`WindArea`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.CutOffWindSpeed === null ||
      modelWindCalculator.CutOffWindSpeed === `` ||
      modelWindCalculator.CutOffWindSpeed === undefined
    ) {
      modelValidate[`CutOffWindSpeed`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.CutInWindSpeed === null ||
      modelWindCalculator.CutInWindSpeed === `` ||
      modelWindCalculator.CutInWindSpeed === undefined
    ) {
      modelValidate[`CutInWindSpeed`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelWindCalculator.RatedPower === null ||
      modelWindCalculator.RatedPower === `` ||
      modelWindCalculator.RatedPower === undefined
    ) {
      modelValidate[`RatedPower`] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (result && climateResult) {
      Proxy.POST(`/coreapi/v1.0/Wind/CalculateWind`, modelWindCalculator)
        .withErrorAlert()
        .success((response) => {
          if (response.Item === null || response.Item === undefined) {
            AlertHelper.show(`danger`, `Error`, `WT CALCULATE ERROR!`);
          } else {
            AlertHelper.show(
              `success`,
              t(`success`),
              `WT CALCULATE Successfully!`
            );
            AddWindData(modelWindCalculator[`WindName`], response.Item);
          }
        });
    } else {
      AlertHelper.show(`warning`, `Warning`, t(`please-fill-the-fields`));
    }
  };

  const controlClimateData = () => {
    let control = false;
    if (
      renewInfo.City !== null ||
      renewInfo.City !== `` ||
      renewInfo.City !== undefined
    ) {
      modelWindCalculator[`City`] = renewInfo.City;
      modelPVCalculator[`City`] = renewInfo.City;
      modelOptimization[`City`] = renewInfo.City;
      control = true;
    }
    if (
      Object.keys(climatedata).length !== 0 &&
      climatedata.constructor !== Object
    ) {
      modelWindCalculator[`ClimateData`] = climatedata;
      modelPVCalculator[`ClimateData`] = climatedata;
      modelOptimization[`ClimateData`] = climatedata;
      control = true;
    }
    if (!control) {
      AlertHelper.show(
        `warning`,
        `Warning`,
        `Please fill the Weather Data in required!`
      );
    }
    return control;
  };

  const CalculateOptimumSystem = () => {
    let result = controlCalculateOptimization();
    let climateResult = controlClimateData();
    if (renewInfo.GridType <= 0) {
      AlertHelper.show(
        `warning`,
        `Warning`,
        `Please fill the Grid Type in required!`
      );
    }
    if (
      demand === null ||
      demand === `` ||
      demand === undefined ||
      (Object.keys(demand).length === 0 && demand.constructor === Object)
    ) {
      AlertHelper.show(
        `warning`,
        `Warning`,
        `Please fill the Electricity Data in required!`
      );
    }
    if (result && climateResult) {
      setIsLoading(true);
      modelOptimization[`GridType`] = renewInfo.GridType;
      modelOptimization[`Demand`] = demand;
      JSON.stringify(modelOptimization);
      Proxy.POST(`/coreapi/v1.0/Optimization/Calculate`, modelOptimization)
        .withErrorAlert()
        .success((response) => {
          if (response.Item === null || response.Item === undefined) {
            AlertHelper.show(
              `danger`,
              `Error`,
              `Optimization CALCULATE ERROR!`
            );
          } else {
            AlertHelper.show(
              `success`,
              t(`success`),
              `Optimization CALCULATE Successfully!`
            );
            AddOptimumData(
              response.Item.MinLCOE,
              response.Item.MaxRF,
              response.Item.PVCapacity,
              response.Item.WTCapacity,
              response.Item.TotalDemand,
              response.Item.FinalElectricityGen,
              response.Item.TotalCost
            );
          }
          setIsLoading(false);
        });
    } else {
      AlertHelper.show(`warning`, `Warning`, t(`please-fill-the-fields`));
    }
  };

  const downloadPanelPower = () => {
    // Seçili satırları kontrol et
    if (panelSelectedRows.length === 0) {
      AlertHelper.show(`error`, `Error`, `Please select at least one row!`);
      return;
    }
    // Seçili satırların güç bilgilerini bir metin olarak topla
    const powerInfoText = panelSelectedRows
      .map((rowIndex) => {
        const row = panelRows[rowIndex - 1];

        // powervalues dizisini alt alta yazdır
        const powerValuesText = row.powervalues
          .map((value, index) => `Panel Power (watt) ${index + 1}: ${value}`)
          .join(`\n`);

        return `Panel Set Name: ${row.panelname}, Total Power (watt): ${row.wattvalue}, CO2 Emission Factor (kg/kWh): ${row.co2},Count: ${row.powervalues.length}\n${powerValuesText}`;
      })
      .join(`\n\n`);

    // Metni bir dosyaya yazdır
    const element = document.createElement(`a`);
    const file = new Blob([powerInfoText], { type: `text/plain` });
    element.href = URL.createObjectURL(file);
    element.download = `panel_power_info.txt`;
    document.body.appendChild(element); // Firefox için gerekli
    element.click();
  };

  const downloadWindPower = () => {
    // Seçili satırları kontrol et
    if (windSelectedRows.length === 0) {
      AlertHelper.show(`error`, `Error`, `Please select at least one row!`);
      return;
    }
    // Seçili satırların güç bilgilerini bir metin olarak topla
    const windInfoText = windSelectedRows
      .map((rowIndex) => {
        const row = windRows[rowIndex - 1];

        // powervalues dizisini alt alta yazdır
        const windValuesText = row.powervalues
          .map((value, index) => `Wind Power (watt) ${index + 1}: ${value}`)
          .join(`\n`);

        return `Wind Set Name: ${row.windname}, Total Power (watt): ${row.wattvalue}, CO2 Emission Factor (kg/kWh): ${row.co2} Count: ${row.powervalues.length}\n${windValuesText}`;
      })
      .join(`\n\n`);

    // Metni bir dosyaya yazdır
    const element = document.createElement(`a`);
    const file = new Blob([windInfoText], { type: `text/plain` });
    element.href = URL.createObjectURL(file);
    element.download = `wind_power_info.txt`;
    document.body.appendChild(element); // Firefox için gerekli
    element.click();
  };

  const AddPanelData = (name, value) => {
    var rowscount = panelRows.length + 1;
    var co2Value = value.Value * 0.325;
    var roundedCo2 = parseFloat(co2Value.toFixed(3));
    var newList = panelRows.concat({
      id: rowscount,
      panelname: name || ``, // panelname özelliğini doğru şekilde tanımla
      wattvalue: value.Value,
      powervalues: value.PowerValues,
      co2: roundedCo2,
    });
    setPanelRows(newList);
  };

  const AddWindData = (name, value) => {
    var rowscount = windRows.length + 1;
    var co2Value = value.Value * 0.325;
    var roundedCo2 = parseFloat(co2Value.toFixed(3));
    var newList = windRows.concat({
      id: rowscount,
      windname: name || ``,
      wattvalue: value.Value,
      powervalues: value.PowerValues,
      co2: roundedCo2,
    });
    setWindRows(newList);
  };

  const economicDataInputs =
    renewInfo.GridType === `OffGrid` ? (
      <>
        <GBESTextInput
          name="BatteryCost"
          value={modelOptimization.BatteryCost}
          valueChanged={optimizationhandleChange}
          labelText={t("battery_cost")}
          error={modelValidate.BatteryCost}
        />
        <GBESTextInput
          name="DGCost"
          value={modelOptimization.DGCost}
          valueChanged={optimizationhandleChange}
          labelText={t("dg_cost")}
          error={modelValidate.DGCost}
        />
      </>
    ) : null;

  const AddOptimumData = (
    minlcoe,
    maxrf,
    pvvalue,
    wtvalue,
    tdemand,
    finale,
    totalcost
  ) => {
    var rowscount = optimumRows.length + 1;
    var newList = optimumRows.concat({
      id: rowscount,
      lcoe: minlcoe,
      rf: maxrf,
      pv: pvvalue,
      wt: wtvalue,
      td: tdemand,
      fe: finale,
      tcost: totalcost,
    });
    setOptimumRows(newList);
  };

  const renderOnGridTabs = () => {
    return [
      {
        context: (
          <GBESGridContainer xs={12}>
            <GBESGridItem xs={12} sm={12} md={6}>
              <GBESGridContainer xs={12}>
                <GBESDivider />
                <GBESTextInput
                  name="PVName"
                  value={modelPVCalculator.PVName}
                  valueChanged={pvhandleChange}
                  labelText={t("name_of_panel_set")}
                  error={modelValidate.PVName}
                />
                <GBESSelectInput
                  labelText={t("select_panel_brand")}
                  name="PVModel"
                  // parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                  // parameterCode="Panel"
                  value={modelPVCalculator.PVModel}
                  valueChanged={pvhandleChange}
                  keySelector="Id"
                  textSelector="ParameterDesc"
                />
                <GBESSelectInput
                  labelText={t("select_panel_model")}
                  name="PVType"
                  //parameterSearchUrl="coreapi/v1.0/Panel/GetPanelType"
                  //parameterCode="ADANI"
                  value={modelPVCalculator.PVType}
                  valueChanged={pvhandleChange}
                  keySelector="Id"
                  textSelector="PowerValue"
                  data={panelTypeList}
                />
                <GBESSelectInput
                  labelText={t("pv_orientation")}
                  name="PVOrientetion"
                  value={modelPVCalculator.PVOrientetion}
                  keySelector="Label"
                  textSelector="Label"
                  valueChanged={pvhandleChange}
                  data={[
                    { Id: 1, Label: "South" },
                    { Id: 2, Label: "West" },
                    { Id: 3, Label: "North" },
                    { Id: 4, Label: "Easth" },
                    { Id: 5, Label: "South-West" },
                    { Id: 6, Label: "North-West" },
                    { Id: 7, Label: "South-Easth" },
                    { Id: 8, Label: "North-Easth" },
                  ]}
                  error={modelValidate.PVOrientetion}
                />
                <GBESTextInput
                  name="PVPower"
                  value={modelPVCalculator.PVPower}
                  valueChanged={pvhandleChange}
                  labelText={t("panel_power")}
                  error={modelValidate.PVPower}
                />
                <GBESTextInput
                  name="PVAngle"
                  value={modelPVCalculator.PVAngle}
                  valueChanged={pvhandleChange}
                  labelText={t("panel_angle")}
                  error={modelValidate.PVAngle}
                />
                <GBESTextInput
                  name="PVArea"
                  value={modelPVCalculator.PVArea}
                  valueChanged={pvhandleChange}
                  labelText={t("panel_area")}
                  error={modelValidate.PVArea}
                />
                <GBESTextInput
                  name="PVEfficiency"
                  value={modelPVCalculator.PVEfficiency}
                  valueChanged={pvhandleChange}
                  labelText={t("panel_efficiency")}
                  error={modelValidate.PVEfficiency}
                />{" "}
                <GBESTextInput
                  name="PVCount"
                  value={modelPVCalculator.PVCount}
                  valueChanged={pvhandleChange}
                  labelText={t("panel_count")}
                  error={modelValidate.PVCount}
                />
                <GBESButton onClick={CalculatePv}>
                  {t("add_panel_set")}
                </GBESButton>
                <GBESDataGrid
                  rows={panelRows}
                  columns={columnsPanel}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  onSelectionModelChange={(newSelection) =>
                    setPanelSelectedRows(newSelection)
                  } // Seçili satırları tutmak için bir state güncelleyicisi
                  disableSelectionOnClick
                />
                <GBESButton onClick={() => downloadPanelPower()}>
                  {t("download")}
                </GBESButton>
                <GBESDivider />
              </GBESGridContainer>
            </GBESGridItem>
          </GBESGridContainer>
        ),
        tabText: "PV",
        index: 0,
      },
      {
        context: (
          <GBESGridContainer xs={12}>
            <GBESGridItem xs={12} sm={12} md={6}>
              <GBESDivider />
              <GBESLabel bold text={t("wind_general_information")} />
              <GBESTextInput
                name="WindName"
                value={modelWindCalculator.WindName}
                valueChanged={windhandleChange}
                labelText={t("name_wind_set")}
                error={modelValidate.WindName}
              />
              <GBESSelectInput
                labelText={t("select_wind_model")}
                name="WindType"
                // parameterSearchUrl="coreapi/v1.0/Parameter/Search"
                // parameterCode="Wind"
                value={modelWindCalculator.WindType}
                valueChanged={windhandleChange}
                keySelector="Id"
                textSelector="ParameterDesc"
              />
              <GBESTextInput
                name="WindArea"
                value={modelWindCalculator.WindArea}
                valueChanged={windhandleChange}
                labelText={t("sweeping_area")}
                error={modelValidate.WindArea}
              />
              <GBESTextInput
                name="WindPowerDensity"
                value={modelWindCalculator.WindPowerDensity}
                valueChanged={windhandleChange}
                labelText={t("wind_power_density")}
                error={modelValidate.WindPowerDensity}
              />
              <GBESTextInput
                name="RatedPower"
                value={modelWindCalculator.RatedPower}
                valueChanged={windhandleChange}
                labelText={t("rated_power")}
                error={modelValidate.RatedPower}
              />
              <GBESTextInput
                name="CutInWindSpeed"
                value={modelWindCalculator.CutInWindSpeed}
                valueChanged={windhandleChange}
                labelText={t("cut_in_wind_speed")}
                error={modelValidate.CutInWindSpeed}
              />
              <GBESTextInput
                name="CutOffWindSpeed"
                value={modelWindCalculator.CutOffWindSpeed}
                valueChanged={windhandleChange}
                labelText={t("cut_off_wind_speed")}
                error={modelValidate.CutOffWindSpeed}
              />
              <GBESTextInput
                name="HubHeightWind"
                value={modelWindCalculator.HubHeightWind}
                valueChanged={windhandleChange}
                labelText={t("hub_height_of_wind")}
                error={modelValidate.HubHeightWind}
              />
              <GBESTextInput
                name="WindCount"
                value={modelWindCalculator.WindCount}
                valueChanged={windhandleChange}
                labelText={t("wind_count")}
                error={modelValidate.WindCount}
              />
              <GBESButton onClick={CalculateWind}>
                {t("add_wind_turbine_set")}
              </GBESButton>
              <GBESDataGrid
                rows={windRows}
                columns={columnsWind}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(newSelection) =>
                  setWindSelectedRows(newSelection)
                }
                disableSelectionOnClick
              />
              <GBESButton onClick={() => downloadWindPower()}>
                {t("download")}
              </GBESButton>
              <GBESDivider />
            </GBESGridItem>
          </GBESGridContainer>
        ),
        tabText: t("wind_turbine"),
        index: 2,
      },
    ];
  };

  const controlCalculateOptimization = () => {
    let result = true;
    if (
      modelOptimization.AnnualDiscountRate === null ||
      modelOptimization.AnnualDiscountRate === "" ||
      modelOptimization.AnnualDiscountRate === undefined
    ) {
      modelValidate["AnnualDiscountRate"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.SystemLifetime === null ||
      modelOptimization.SystemLifetime === "" ||
      modelOptimization.SystemLifetime === undefined
    ) {
      modelValidate["SystemLifetime"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.WtPrice === null ||
      modelOptimization.WtPrice === "" ||
      modelOptimization.WtPrice === undefined
    ) {
      modelValidate["WtPrice"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.WtMaintenanceCost === null ||
      modelOptimization.WtMaintenanceCost === "" ||
      modelOptimization.WtMaintenanceCost === undefined
    ) {
      modelValidate["WtMaintenanceCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.WtCost === null ||
      modelOptimization.WtCost === "" ||
      modelOptimization.WtCost === undefined
    ) {
      modelValidate["WtCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.PvPrice === null ||
      modelOptimization.PvPrice === "" ||
      modelOptimization.PvPrice === undefined
    ) {
      modelValidate["PvPrice"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.PvMaintenanceCost === null ||
      modelOptimization.PvMaintenanceCost === "" ||
      modelOptimization.PvMaintenanceCost === undefined
    ) {
      modelValidate["PvMaintenanceCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.PvCost === null ||
      modelOptimization.PvCost === "" ||
      modelOptimization.PvCost === undefined
    ) {
      modelValidate["PvCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }

    if (
      modelOptimization.MaxTotalCost === null ||
      modelOptimization.MaxTotalCost === "" ||
      modelOptimization.MaxTotalCost === undefined
    ) {
      modelValidate["MaxTotalCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.MaxWTCapacity === null ||
      modelOptimization.MaxWTCapacity === "" ||
      modelOptimization.MaxWTCapacity === undefined
    ) {
      modelValidate["MaxWTCapacity"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.MinWTCapacity === null ||
      modelOptimization.MinWTCapacity === "" ||
      modelOptimization.MinWTCapacity === undefined
    ) {
      modelValidate["MinWTCapacity"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.MaxPVCapacity === null ||
      modelOptimization.MaxPVCapacity === "" ||
      modelOptimization.MaxPVCapacity === undefined
    ) {
      modelValidate["MaxPVCapacity"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.MinPVCapacity === null ||
      modelOptimization.MinPVCapacity === "" ||
      modelOptimization.MinPVCapacity === undefined
    ) {
      modelValidate["MinPVCapacity"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.AnnualCapacity === null ||
      modelOptimization.AnnualCapacity === "" ||
      modelOptimization.AnnualCapacity === undefined
    ) {
      modelValidate["AnnualCapacity"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.BatteryCost === null ||
      modelOptimization.BatteryCost === "" ||
      modelOptimization.BatteryCost === undefined
    ) {
      modelValidate["BatteryCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    if (
      modelOptimization.DGCost === null ||
      modelOptimization.DGCost === "" ||
      modelOptimization.DGCost === undefined
    ) {
      modelValidate["DGCost"] = true;
      setModelValidate({ ...modelValidate });
      result = false;
    }
    return result;
  };

  const renderOptimizationTabs = () => {
    return [
      {
        context: (
          <GBESGridContainer xs={12}>
            <GBESGridItem xs={12} sm={12} md={6}>
              <GBESDivider />
              <GBESLabel bold text={t("economic_data")} />
              <GBESTextInput
                name="AnnualDiscountRate"
                value={modelOptimization["AnnualDiscountRate"]}
                valueChanged={optimizationhandleChange}
                labelText={t("annual_discount_rate")}
                error={modelValidate.AnnualDiscountRate}
              />
              <GBESTextInput
                name="PvCost"
                value={modelOptimization.PvCost}
                valueChanged={optimizationhandleChange}
                labelText={t("pv_cost")}
                error={modelValidate.PvCost}
              />
              <GBESTextInput
                name="PvMaintenanceCost"
                value={modelOptimization.PvMaintenanceCost}
                valueChanged={optimizationhandleChange}
                labelText={t("pv_system_maintenance_cost")}
                error={modelValidate.PvMaintenanceCost}
              />
              <GBESTextInput
                name="PvPrice"
                value={modelOptimization.PvPrice}
                valueChanged={optimizationhandleChange}
                labelText={t("pv_electricity_sales_price_to_the_grid")}
                error={modelValidate.PvPrice}
              />
              <GBESTextInput
                name="WtCost"
                value={modelOptimization.WtCost}
                valueChanged={optimizationhandleChange}
                labelText={t("wt_cost")}
                error={modelValidate.WtCost}
              />
              <GBESTextInput
                name="WtMaintenanceCost"
                value={modelOptimization.WtMaintenanceCost}
                valueChanged={optimizationhandleChange}
                labelText={t("wt_system_maintenance_cost")}
                error={modelValidate.WtMaintenanceCost}
              />
              <GBESTextInput
                name="WtPrice"
                value={modelOptimization.WtPrice}
                valueChanged={optimizationhandleChange}
                labelText={t("wt_electricity_sales_price_to_the_grid")}
                error={modelValidate.WtPrice}
              />
              {economicDataInputs}
              <GBESTextInput
                name="SystemLifetime"
                value={modelOptimization.SystemLifetime}
                valueChanged={optimizationhandleChange}
                labelText={t("system_lifetime_years")}
                error={modelValidate.SystemLifetime}
              />
              <GBESDivider />
            </GBESGridItem>
          </GBESGridContainer>
        ),
        tabText: t("economic_data"),
        index: 0,
      },
      {
        context: (
          <GBESGridContainer xs={12}>
            <GBESGridItem xs={12} sm={12} md={6}>
              <GBESDivider />
              <GBESLabel bold text={t("constraints")} />
              <GBESTextInput
                name="AnnualCapacity"
                value={modelOptimization.AnnualCapacity}
                valueChanged={optimizationhandleChange}
                labelText={t("maximum_annual_capacity_shortage")}
                error={modelValidate.AnnualCapacity}
              />
              <GBESTextInput
                name="MinPVCapacity"
                value={modelOptimization.MinPVCapacity}
                valueChanged={optimizationhandleChange}
                labelText={t("minimum_pv_capacity")}
                error={modelValidate.MinPVCapacity}
              />
              <GBESTextInput
                name="MaxPVCapacity"
                value={modelOptimization.MaxPVCapacity}
                valueChanged={optimizationhandleChange}
                labelText={t("maximum_pv_capacity")}
                error={modelValidate.MaxPVCapacity}
              />
              <GBESTextInput
                name="MinWTCapacity"
                value={modelOptimization.MinWTCapacity}
                valueChanged={optimizationhandleChange}
                labelText={`${t("minimum_wt_capacity")} (kW)`}
                error={modelValidate.MinWTCapacity}
              />
              <GBESTextInput
                name="MaxWTCapacity"
                value={modelOptimization.MaxWTCapacity}
                valueChanged={optimizationhandleChange}
                labelText={`${t("maximum_wt_capacity")} (kW)`}
                error={modelValidate.MaxWTCapacity}
              />
              <GBESTextInput
                name="MaxTotalCost"
                value={modelOptimization.MaxTotalCost}
                valueChanged={optimizationhandleChange}
                labelText={t("maximum_system_installation_cost")}
                error={modelValidate.MaxTotalCost}
              />
              <GBESButton onClick={CalculateOptimumSystem}>
                {t("calculate")}
              </GBESButton>
              <GBESDataGrid
                loading={calcOptimumLoading}
                rows={optimumRows}
                columns={columnsOptimum}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </GBESGridItem>
          </GBESGridContainer>
        ),
        tabText: t("optimization"),
        index: 1,
      },
    ];
  };

  const renderTabs = () => {
    if (designtype === "Optimization") {
      return renderOptimizationTabs();
    } else {
      return renderOnGridTabs();
    }
  };

  const handlePreviousPage = (e) => {
    if (designtype === "Optimization") {
      props?.history?.push?.(
        `/admin/electrioty-load/${projectModel.ProjectId}`
      );
    } else {
      props?.history?.push?.(
        `/admin/renewable-information/${projectModel.ProjectId}`
      );
    }
  };

  return (
    <GBESCard isLoading={calcOptimumLoading}>
      <GBESCardHeader title={t("renewable_energy_system")} />
      <GBESCardBody>
        <GBESTabBar
          value={value}
          valueChanged={(newValue) => {
            TabValueChanged(newValue);
          }}
          onFinish={() => {
            finish();
          }}
          dataSource={renderTabs()}
        ></GBESTabBar>
        <GBESButton onClick={handlePreviousPage}>
          {t("previous_page")}
        </GBESButton>
      </GBESCardBody>
      <GBESCardFooter></GBESCardFooter>
    </GBESCard>
  );
};

export default RenewableSystem;
