import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { Box } from "@material-ui/core";
import { number, PropTypes } from "prop-types";
import { GBESSGridContainer } from "components/GBESS";

import { Button } from "@material-ui/core";
import GBESSButton from "./GBESSButton";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  footer: {
    position: "static",
    bottom: 0,
    right: 0,
    left: 0,
  },
  header: {
    backgroundColor: "#4ca750",
    borderRadius: 5,
    position: "static",
    top: 0,
    left: 0,
    right: 0,
    color: "#FFFFFF",
  },
});

function GBESSTabBar({
  value,
  valueChanged,
  dataSource,
  isFooterShow,
  nextButton,
  nextButtonText,
  finishButton,
  finishButtonText,
  previousButton,
  previousButtonText,
  onFinish,
  isTabsClickDisabled,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const onChange = (event, newValue) => {
    if (isTabsClickDisabled) return;
    valueChanged && valueChanged(newValue);
  };
  const onClick = (isNext) => {
    let newItem = {};
    const currentItemIndex = dataSource.indexOf(
      dataSource.find((x) => x.index == value)
    );
    const tempDataSource = dataSource.filter((item) => item.disabled != true);
    if (isNext) {
      newItem = tempDataSource[currentItemIndex + 1];
    } else {
      newItem = tempDataSource[currentItemIndex - 1];
    }
    valueChanged && valueChanged(newItem.index);
  };
  const getContent = () => {
    return dataSource.map((item) => {
      return (
        <TabPanel value={value} key={item.index} index={item.index}>
          {item.context}
        </TabPanel>
      );
    });
  };
  const getTabs = () => {
    return dataSource.map((item) => {
      return (
        <Tab
          key={item.index}
          value={item.index}
          label={item.tabText}
          disabled={item.disabled}
          wrapped={true}
        />
      );
    });
  };
  const getFooter = () => {
    if (!dataSource || dataSource.length < 1) return <></>;
    const lastItemValue = dataSource[dataSource.length - 1]?.index ?? 0;
    return (
      <Box display="flex" justifyContent="space-between">
        {previousButton ? (
          previousButton
        ) : (
          <GBESSButton
            disabled={!value || value == (dataSource[0].index ?? 0)}
            onClick={() => onClick(false)}
          >
            {previousButtonText || t("previous")}
          </GBESSButton>
        )}
        {value == lastItemValue ? (
          finishButton ? (
            finishButton
          ) : (
            <GBESSButton onClick={() => onFinish?.()}>
              {finishButtonText || t("next")}
            </GBESSButton>
          )
        ) : nextButton ? (
          nextButton
        ) : (
          <GBESSButton onClick={() => onClick(true)}>
            {nextButtonText || t("next")}
          </GBESSButton>
        )}
      </Box>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="wrapped label tabs example"
          centered
        >
          {getTabs()}
        </Tabs>
      </div>

      {getContent()}
      {isFooterShow && <div className={classes.footer}> {getFooter()}</div>}
    </div>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      role="tabpanel"
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </div>
  );
}

GBESSTabBar.defaultProps = {
  isFooterShow: true,
  dataSource: [],
  value: 0,
};

GBESSTabBar.propTypes = {
  value: PropTypes.number,
  valueChanged: PropTypes.func,
  onFinish: PropTypes.func,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      context: PropTypes.node,
      tabText: PropTypes.node,
      disabled: PropTypes.bool,
      index: PropTypes.number,
    })
  ),
  isFooterShow: PropTypes.bool,
  nextButton: PropTypes.node,
  previousButton: PropTypes.node,
  nextButtonText: PropTypes.string,
  previousButtonText: PropTypes.string,
  isTabsClickDisabled: PropTypes.bool,
  finishButton: PropTypes.node,
  finishButtonText: PropTypes.string,
};

export default GBESSTabBar;
